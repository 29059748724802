import * as React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import API from "kgt-api";
import { inject, observer } from "mobx-react";
import Session from "../../stores/Session";
import { GetResponseBody } from "kgt-api/dist/Request";
import { withRouter, RouteComponentProps } from "react-router";
import i18n from "i18next";
import SpinLoader from "../SpinLoader";
import TypographyTitle from "../Typography/TypographyTitle";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import RequestRow from "../Table/RequestRow";
import CellBold from "../Table/CellBold";
import Theme from "../../utils/Theme";
import Container from "../../components/Container";
import { Typography } from "@material-ui/core";
import Alert from "../../components/Alert/Alert";

interface Props extends RouteComponentProps {}
interface InjectedProps extends Props {
  session: Session;
}

interface State {
  requestsResponse?: GetResponseBody;
  ready: boolean;
  alertVisible: boolean;
  alertMessage: string;
}

@inject("session")
@observer
class RequestList extends React.Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  state: State = {
    ready: false,
    alertVisible: false,
    alertMessage: "",
  };

  async componentDidMount() {
    const { session } = this.injected;

    if (session.data === null) {
      return;
    }

    try {
      const response = await API.Instance().request.get({
        requesters: [session.data.profileId],
        ignoreHourBuffer: true,
      });

      const requests = await response.json();
      requests.sort((r1, r2) => {
        if (r1.startTime < r2.startTime) {
          return 1;
        }
        if (r1.startTime > r2.startTime) {
          return -1;
        }
        return 0;
      });
      this.setState({ requestsResponse: requests, ready: true });
    } catch {
      this.setState({
        ready: true,
        alertVisible: true,
        alertMessage: i18n.t("error_noNetwork"),
      });
    }
  }

  handleOnRowClick = (requestId: string) => {
    this.props.history.push(`${this.props.match.path}/${requestId}`);
  };

  handleOnButtonClick = () => {
    this.props.history.push(`${this.props.match.path}/create`);
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  render() {
    const { ready, requestsResponse } = this.state;

    if (ready) {
      return (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <TypographyTitle text={i18n.t("requestList_header")} />
            <ButtonPrimary
              title={i18n.t("createRequest.title")}
              disabled={ready && !requestsResponse}
              onClick={this.handleOnButtonClick}
              marginLeft="auto"
            />
          </div>
          {this.state.requestsResponse &&
          this.state.requestsResponse.length == 0 ? (
            <Container>
              <Typography component="p">
                {i18n.t("requestList_noCreatedTable")}
              </Typography>
            </Container>
          ) : (
            <Table style={{ marginTop: Theme.mediumSpacing }}>
              <TableHead>
                <TableRow>
                  <CellBold text={i18n.t("requestList_date")} />
                  <CellBold text={i18n.t("requestList_title")} />
                  <CellBold text={i18n.t("requestList_time")} />
                  <CellBold text={i18n.t("requestList_duration")} />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.requestsResponse &&
                  this.state.requestsResponse.map((request) => (
                    <RequestRow
                      title={request.title}
                      startTime={request.startTime}
                      duration={request.duration}
                      requestId={request.id}
                      key={request.id}
                      onClick={this.handleOnRowClick}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
          <Alert
            message={this.state.alertMessage}
            visible={this.state.alertVisible}
            closeOverlay={this.closeOverlay}
          ></Alert>
        </React.Fragment>
      );
    } else {
      return <SpinLoader />;
    }
  }
}

export default withRouter(RequestList);
