import * as React from "react";
import styles from "./RegisterInterest.css";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { PostRequestBody } from "kgt-api/dist/Responder";
import { GetResponseBody as Skills } from "kgt-api/dist/Skill";
import API from "kgt-api";
import {
  parsePersonalNumber,
  validatePersonalNumber
} from "kgt-api/dist/utils/personalNumber";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { Motion, spring } from "react-motion";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import i18n from "i18next";

interface Interest {
  name: string;
  personalNumber: string;
  email: string;
  password: string;
  phone: string;
  presentation: string;
  competence: string[];
  journal: string[];
  experience: number;
  bankAccountNumber: string;
  clearingNumber: string;
  bankName: string;
  billingStreet: string;
  billingCity: string;
  billingZip: string;
  hsaId: string;
  sithsExpiration: string;
  eServiceCard: boolean;
  driversLicence: boolean;
  termsOfService: boolean;
  privacyPolicy: boolean;
}

interface Props {
  longVersion?: boolean;
}

interface FormState {
  // Field values
  interest: Interest;

  // Checks whether the form has been successfully submitted or not
  submitSuccess?: boolean;

  personalNumberError?: string;

  emailError?: string;

  submitError?: string;

  availableSkills?: Skills;

  loading: boolean;
}

class RegisterInterest extends React.Component<Props, FormState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      interest: {
        name: "",
        personalNumber: "",
        email: "",
        password: "",
        phone: "",
        presentation: "",
        competence: [],
        journal: [],
        experience: 0,
        bankAccountNumber: "",
        clearingNumber: "",
        bankName: "",
        billingStreet: "",
        billingCity: "",
        billingZip: "",
        hsaId: "",
        sithsExpiration: "2019-01-01",
        eServiceCard: false,
        driversLicence: false,
        termsOfService: false,
        privacyPolicy: false
      }
    };
  }

  async componentDidMount() {
    const response = await API.Instance().skill.get();
    if (response.ok) {
      const skills = await response.json();
      this.setState(() => ({
        availableSkills: skills
      }));
    }
  }

  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    const personalNumberError: string | undefined = this.validatePn(
      this.state.interest.personalNumber
    );

    // We don't need to to anything, the onBlur event on personal number will show the error
    if (personalNumberError) {
      return;
    }

    this.setState({
      loading: true
    });

    let submitSuccess = false;
    let submitError: string | undefined = undefined;
    try {
      submitSuccess = await this.submitForm();
    } catch {
      submitSuccess = false;
      submitError = i18n.t("register.form.submit_error");
    }
    this.setState({
      submitSuccess,
      submitError,
      loading: false
    });
  };

  private async submitForm() {
    const interest = this.state.interest;

    const body: PostRequestBody = {
      name: interest.name,
      personalNumber: parsePersonalNumber(interest.personalNumber),
      email: interest.email,
      password: interest.password,
      phone: interest.phone,
      presentation: interest.presentation,
      skills: interest.competence.concat(interest.journal).map(id => ({
        id
      })),
      experience: interest.experience,
      bankAccountNumber: interest.bankAccountNumber,
      clearingNumber: interest.clearingNumber,
      bankName: interest.bankName,
      billingStreet: interest.billingStreet,
      billingCity: interest.billingCity,
      billingZip: interest.billingZip,
      hsaId: interest.hsaId,
      sithsExpiration: new Date(interest.sithsExpiration),
      eServiceCard: interest.eServiceCard,
      driversLicence: interest.driversLicence
    };
    const res = await API.Instance().responder.post(body);

    if (!res.ok) {
      const err = (await res.json()) as any;
      if (err.name && err.name === "UNIQUE_VIOLATION") {
        const pnExists = /personalNumber/.test(err.message);
        const emailExists = /accountName|email/.test(err.message);
        this.setState(() => ({
          personalNumberError: pnExists
            ? i18n.t("register.form.error.personalnumber_exists")
            : undefined,
          emailError: emailExists
            ? i18n.t("register.form.error.personalnumber_exists")
            : undefined
        }));
      }
    }

    return res.ok;
  }

  updateInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const field: string = e.target.name;
    const val: string = e.target.value;

    this.setState(({ interest }) => ({
      interest: Object.assign({}, interest, { [field]: val })
    }));
  };

  handlePersonalNumberBlur = (event: { target: HTMLInputElement }) => {
    let personalNumber: string = event.target.value;

    let formattedPN = parsePersonalNumber(personalNumber);

    // Add dash
    if (formattedPN.length > 8) {
      formattedPN = formattedPN.substr(0, 8) + "-" + formattedPN.substr(8, 4);
    }

    const personalNumberError: string | undefined = this.validatePn(
      formattedPN
    );

    this.setState(({ interest }) => ({
      interest: Object.assign({}, interest, { personalNumber: formattedPN }),
      personalNumberError
    }));
  };

  handleChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const field = e.target.name;
    this.setState(({ interest }) => ({
      interest: Object.assign({}, interest, { [field]: checked })
    }));
  };

  private validatePn(formattedPN: string) {
    const error = validatePersonalNumber(parsePersonalNumber(formattedPN));
    let personalNumberError: string | undefined = undefined;
    if (!error.valid) {
      personalNumberError = i18n.t(
        "register.form.error.personalnumber_invalid"
      );
    }
    return personalNumberError;
  }

  public render() {
    const { longVersion } = this.props;
    const {
      submitSuccess,
      availableSkills,
      personalNumberError,
      emailError,
      submitError,
      loading
    } = this.state;
    const {
      name,
      personalNumber,
      email,
      phone,
      password,
      presentation,
      competence,
      journal,
      experience,
      bankAccountNumber,
      clearingNumber,
      bankName,
      billingStreet,
      billingCity,
      billingZip,
      hsaId,
      sithsExpiration,
      eServiceCard,
      driversLicence,
      termsOfService,
      privacyPolicy
    } = this.state.interest;

    return (
      <form
        onSubmit={e => this.handleSubmit(e)}
        method="POST"
        className={styles.form}
      >
        {submitSuccess && (
          <Motion defaultStyle={{ value: 0 }} style={{ value: spring(100) }}>
            {({ value }) => (
              <div
                className={styles.successContainer}
                style={{ clipPath: `circle(${value}%)` }}
              >
                <Typography variant="h3" component="p" gutterBottom>
                  {i18n.t("registerInterest_thanksForSubmit")}
                </Typography>
                <Typography>
                  {i18n.t("registerInterest_readyForAppLogin")}
                </Typography>
              </div>
            )}
          </Motion>
        )}
        {/* Basic info */}
        {longVersion && (
          <p className={`${styles.formGroupLabel} ${styles.basicInfo}`}>
            GRUNDLÄGGANDE INFORMATION
          </p>
        )}
        <TextField
          type="text"
          name="name"
          label={i18n.t("register.form.textfield.name")}
          onChange={this.updateInput}
          placeholder="För- och efternamn"
          variant="outlined"
          margin="normal"
          value={name}
          required
        />
        <TextField
          type="text"
          name="personalNumber"
          label={i18n.t("register.form.textfield.personalnumber")}
          error={personalNumberError !== undefined}
          helperText={personalNumberError}
          onChange={this.updateInput}
          onBlur={this.handlePersonalNumberBlur}
          placeholder="T.ex. 20010203-0405"
          variant="outlined"
          margin="normal"
          value={personalNumber}
          required
        />
        <TextField
          type="email"
          name="email"
          label={i18n.t("register.form.textfield.email")}
          error={emailError !== undefined}
          helperText={emailError}
          onChange={this.updateInput}
          placeholder="T.ex. 'min@epost.se'"
          variant="outlined"
          margin="normal"
          value={email}
          required
        />
        <TextField
          type="password"
          name="password"
          label={i18n.t("register.form.textfield.password")}
          onChange={this.updateInput}
          variant="outlined"
          margin="normal"
          value={password}
          required
          hidden
        />
        <FormControlLabel
          name="termsOfService"
          onChange={this.handleChecked as React.ChangeEventHandler<{}>}
          control={<Checkbox inputProps={{ required: true }} color="primary" />}
          label={
            <React.Fragment>
              {i18n.t("register.form.checkbox.termsOfService")}
              <Link className={styles.link} to={"/terms"} target="_blank">
                {i18n.t("register.form.termsOfService")}
              </Link>
            </React.Fragment>
          }
          checked={termsOfService}
        />
        <FormControlLabel
          name="privacyPolicy"
          onChange={this.handleChecked as React.ChangeEventHandler<{}>}
          control={<Checkbox inputProps={{ required: true }} color="primary" />}
          label={
            <React.Fragment>
              {i18n.t("register.form.checkbox.privacyPolicy")}
              <Link className={styles.link} to={"/privacy"} target="_blank">
                {i18n.t("register.form.privacyPolicy")}
              </Link>
            </React.Fragment>
          }
          checked={privacyPolicy}
        />

        {/* Start of long version */}
        {longVersion && (
          <React.Fragment>
            <TextField
              type="tel"
              name="phone"
              label="Telefonnummer"
              onChange={this.updateInput}
              placeholder="T.ex. '+46 12345678'"
              variant="outlined"
              margin="normal"
              value={phone}
              required
            />
            <TextField
              type="text"
              name="presentation"
              multiline
              label="Beskrivning"
              onChange={this.updateInput}
              placeholder="Berätta lite om dig själv"
              variant="outlined"
              margin="normal"
              value={presentation}
            />

            <p className={styles.formGroupLabel}>ERFARENHETER</p>

            {/* Experience */}
            <FormControl margin="normal">
              <InputLabel htmlFor="competence">Specialistkompetenser</InputLabel>
              <Select
                inputProps={{
                  name: "competence",
                  id: "competence"
                }}
                onChange={this.updateInput}
                multiple
                value={competence}
                required
              >
                {availableSkills &&
                  availableSkills
                    .filter(skill => skill.type === "competence")
                    .map(skill => (
                      <MenuItem key={skill.id} value={skill.id}>
                        {i18n.t(skill.tag)}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl margin="normal">
              <InputLabel htmlFor="experience">Erfarenhet</InputLabel>
              <Select
                inputProps={{
                  name: "experience",
                  id: "experience"
                }}
                onChange={this.updateInput}
                value={experience}
                required
              >
                <MenuItem value={0}>0 - 1 år</MenuItem>
                <MenuItem value={1}>1 - 2 år</MenuItem>
                <MenuItem value={2}>2 - 5 år</MenuItem>
                <MenuItem value={5}>5 - 10 år</MenuItem>
                <MenuItem value={10}>&gt; 10 år</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="normal">
              <InputLabel htmlFor="journal">Journalsystem</InputLabel>
              <Select
                inputProps={{
                  name: "journal",
                  id: "journal"
                }}
                onChange={this.updateInput}
                multiple
                value={journal}
                required
              >
                {availableSkills &&
                  availableSkills
                    .filter(skill => skill.type === "tool")
                    .map(skill => (
                      <MenuItem key={skill.id} value={skill.id}>
                        {i18n.t(skill.tag)}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            {/* Billing information */}
            <p className={styles.formGroupLabel}>BANKINFORMATION</p>
            <TextField
              type="text"
              name="bankAccountNumber"
              label="Bankkontonummer"
              onChange={this.updateInput}
              margin="normal"
              variant="outlined"
              value={bankAccountNumber}
              required
            />
            <TextField
              type="text"
              name="bankName"
              label="Banknamn"
              onChange={this.updateInput}
              margin="normal"
              variant="outlined"
              value={bankName}
              required
            />
            <TextField
              type="text"
              name="clearingNumber"
              label="Clearingnummer"
              onChange={this.updateInput}
              margin="normal"
              variant="outlined"
              value={clearingNumber}
              required
            />
            {/* Billing information */}
            <p className={styles.formGroupLabel}>FAKTURERINGSADRESS</p>

            <TextField
              type="text"
              name="billingStreet"
              label="Gata"
              onChange={this.updateInput}
              placeholder="T.ex. 'Stockholmsvägen 1'"
              margin="normal"
              variant="outlined"
              value={billingStreet}
              required
            />
            <TextField
              type="text"
              name="billingCity"
              label="Stad"
              onChange={this.updateInput}
              placeholder="T.ex. 'Stockholm'"
              margin="normal"
              variant="outlined"
              value={billingCity}
              required
            />
            <TextField
              type="text"
              name="billingZip"
              label="Postkod"
              onChange={this.updateInput}
              placeholder="T.ex. '01234'"
              margin="normal"
              variant="outlined"
              value={billingZip}
              required
            />

            {/* Additional Info */}
            <p className={styles.formGroupLabel}>EXTRAINFORMATION</p>
            <TextField
              type="text"
              name="hsaId"
              label="HSA-id"
              onChange={this.updateInput}
              placeholder="T.ex. 'SE165567766992-XXXX'"
              margin="normal"
              variant="outlined"
              value={hsaId}
              required
            />
            <TextField
              type="date"
              name="hsaIdExpiration"
              label="Utgångsdatum för HSA-id"
              onChange={this.updateInput}
              margin="normal"
              variant="outlined"
              value={sithsExpiration}
              required
            />
            <FormGroup>
              <FormControlLabel
                name="driversLicence"
                onChange={this.handleChecked as React.ChangeEventHandler<{}>}
                control={<Checkbox color="primary" />}
                label="Jag har ett körkort"
                checked={driversLicence}
              />
              <FormControlLabel
                name="eServiceCard"
                onChange={this.handleChecked as React.ChangeEventHandler<{}>}
                control={<Checkbox color="primary" />}
                label="Jag har ett e-tjänstekort/SITHS"
                checked={eServiceCard}
              />
            </FormGroup>
          </React.Fragment>
        )}
        {/* End of long version */}

        <Divider className={styles.divider} />
        <Button
          type="submit"
          color="primary"
          variant={loading ? "text" : "contained"}
          disabled={loading}
        >
          {loading ? <CircularProgress /> : i18n.t("register.button.register")}
        </Button>

        {submitError && (
          <Paper classes={{ root: styles.errorBox }}>{submitError}</Paper>
        )}
      </form>
    );
  }
}

export default RegisterInterest;
