import { Button, TextField, Typography } from "@material-ui/core";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import i18n from "i18next";
import API from "kgt-api";
import * as React from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonWhite from "../Buttons/ButtonWhite";
import Logo from "../Logo";
import SpinLoader from "../SpinLoader";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitle from "../Typography/TypographyTitle";
import TypographyTitleBigger from "../Typography/TypographyTitleBigger";
import styles from "./ForgotPassword.css";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

interface State {
  email: string;
  password: string;
  loading: boolean;
  failedSubmit: boolean;
  referrer: boolean;
  showPassword: boolean;
  success: boolean;
  error?: string;
  showEmailField: boolean;
}

class ForgotPasswordComponent extends React.Component<State> {
  state = {
    email: "",
    loading: false,
    failedSubmit: false,
    showPassword: false,
    success: false,
    error: "",
    showEmailField: false,
  };

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    this.setState({ loading: true }, async () => {
      const res = await API.Instance().resettoken.post({
        accountName: this.state.email.toLowerCase(),
      });

      if (res.ok) {
        this.setState({
          loading: false,
          success: true,
        });
      } else if (res.status === 404) {
        this.setState({
          loading: false,
          success: false,
          failedSubmit: true,
          error: i18n.t("forgot.error.missing"),
        });
      } else {
        this.setState({
          loading: false,
          success: false,
          failedSubmit: true,
          error: i18n.t("reset.error.unknown"),
        });
      }
    });

    event.preventDefault();
  };

  render() {
    return (
      <div className={styles.container}>
        {this.state.loading ? (
          <SpinLoader />
        ) : (
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <TypographyTitleBigger text={i18n.t("forgot.heading")} />
            </ThemeProvider>
            <div className={styles.paragraph}>
              <TypographyRegular text={i18n.t("forgot.information")} />
            </div>
            <div className={styles.paragraph}>
              <TypographyRegular text={i18n.t("forgot.information_addendum")} />
            </div>

            {!this.state.showEmailField && (
              <div style={{ marginTop: 20 }}>
                <ButtonPrimary
                  title={i18n.t("forgot.iAmResponder")}
                  onClick={() => {
                    this.setState({ showEmailField: true });
                  }}
                />
              </div>
            )}

            {this.state.showEmailField && (
              <form className={styles.form} onSubmit={this.onSubmit}>
                {this.state.success && (
                  <div className={styles.successContainer}>
                    <Link to="/">
                      <Logo />
                    </Link>
                    <div
                      style={{
                        marginBottom: 48,
                        marginTop: 40,
                        maxWidth: "40%",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <TypographyTitle text={i18n.t("forgot.success")} />
                      </ThemeProvider>
                    </div>
                    <Link to="/">
                      <ButtonPrimary
                        title={i18n.t("reset.password.goBack")}
                        onClick={() => {
                          this.setState({ showEmailField: false });
                        }}
                      />
                    </Link>
                  </div>
                )}
                <TextField
                  style={{ backgroundColor: "white" }}
                  className={styles.formTextField}
                  onChange={this.handleEmailChange}
                  label={i18n.t("forgot.email")}
                  id="requester-username"
                  margin="normal"
                  variant="outlined"
                  value={this.state.email}
                />
                {this.state.failedSubmit && (
                  <Typography style={{ color: "#f44336" }}>
                    {this.state.error}
                  </Typography>
                )}
                <div className={styles.buttonContainer}>
                  <Link to="/">
                    <ButtonWhite
                      title={i18n.t("forgot.cancel")}
                      onClick={() => {}}
                    />
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ border: "none" }}
                    type="submit"
                  >
                    {i18n.t("forgot.reset")}
                  </Button>
                </div>
              </form>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ForgotPasswordComponent;
