import * as React from "react";
import RegisterInterest from "../../components/RegisterInterest";
import ScreenContent from "../../components/ScreenContent";
import Container from "../../components/Container";
import TextContent from "../../components/RegisterInterest/TextContent";

function ResponderInterest() {
  return (
    <ScreenContent>
      <Container>
        <TextContent />
        <RegisterInterest />
      </Container>
    </ScreenContent>
  );
}

export default ResponderInterest;
