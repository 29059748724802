import * as React from "react";

import styles from "./SpinLoader.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const SpinLoader = () => (
  <div className={styles.container}>
    <CircularProgress />
  </div>
);

export default SpinLoader;
