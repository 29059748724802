import Typography from "@material-ui/core/Typography";
import React from "react";
import Theme from "../../utils/Theme";

interface TypographySubtitleProps {
    text: string;
    className?: string;
}

export default class TypographySubtitle extends React.Component<TypographySubtitleProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                variant="subtitle1"
                style={{ 
                    fontFamily: "Barlow", 
                    color: Theme.primaryTextColour 
            }}
            >
                {this.props.text}
            </Typography>
        );
    }
}