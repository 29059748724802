import Account from "./Account";
import Admin from "./Admin";
import ChangePassword from "./ChangePassword";
import DeleteRequester from "./DeleteRequester";
import DeleteResponder from "./DeleteResponder";
import Login from "./Login";
import MatchRequests from "./MatchRequests";
import MatchResponders from "./MatchResponders";
import PingEndpoint from "./Ping/ping";
import Region from "./Region/Region";
import Request from "./Request";
import Requester from "./Requester";
import ResetToken from "./ResetToken";
import Responder from "./Responder";
import ResponderRequest from "./ResponderRequest";
import Skill from "./Skill";
import TimeReport from "./TimeReport";
import Translation from "./Translation";
import TypedResponse from "./TypedResponse";

type QueryParams = { [key: string]: any };
type ResponseCallback = (response: Response) => void;
export { ResponseCallback, QueryParams };

export default class API {
  // Internal data
  /**
   * The `fetch`-like object used
   */
  private fetcher: typeof fetch;
  /**
   * Base URL to make requests to
   */
  baseUrl: string;
  /**
   * Optional JWT token that will be sent with every request
   */
  private jwt: string | null = null;

  // Events/callbacks/signals
  private onUnauthourized: Array<ResponseCallback> = [];

  // End points
  admin: Admin;
  account: Account;
  login: Login;
  request: Request;
  requester: Requester;
  skill: Skill;
  responder: Responder;
  translation: Translation;
  responderRequest: ResponderRequest;
  matchrequests: MatchRequests;
  matchresponders: MatchResponders;
  timereport: TimeReport;
  region: Region;
  changepassword: ChangePassword;
  deleteresponder: DeleteResponder;
  deleterequester: DeleteRequester;
  resettoken: ResetToken;
  ping: PingEndpoint;

  constructor(baseUrl: string, fetcher: typeof fetch) {
    this.baseUrl = baseUrl;
    this.fetcher = fetcher;

    this.admin = new Admin(this);
    this.account = new Account(this);
    this.login = new Login(this);
    this.request = new Request(this);
    this.requester = new Requester(this);
    this.skill = new Skill(this);
    this.responder = new Responder(this);
    this.translation = new Translation(this);
    this.responderRequest = new ResponderRequest(this);
    this.matchrequests = new MatchRequests(this);
    this.matchresponders = new MatchResponders(this);
    this.timereport = new TimeReport(this);
    this.region = new Region(this);
    this.changepassword = new ChangePassword(this);
    this.deleteresponder = new DeleteResponder(this);
    this.deleterequester = new DeleteRequester(this);
    this.resettoken = new ResetToken(this);
    this.ping = new PingEndpoint(this);
  }

  private static instance: API | null = null;
  /**
   * Initializes the singleton instance of the API with the given arguments
   * @param baseUrl The base URL of the API, e.g. https://my.website.com:80
   * @param fetcher A fetch-like function. Should be compliant with the WHATWG fetch api
   */
  static Initialize(baseUrl: string, fetcher: typeof fetch) {
    if (API.instance === null) {
      API.instance = new API(baseUrl, fetcher);
    } else {
      API.instance.baseUrl = baseUrl;
      API.instance.fetcher = fetcher;
    }
  }

  /**
   * Retrieves the current instance of the API. If `API::Initialize`
   * isn't called before calling this function, it will throw an error
   */
  static Instance() {
    if (API.Instance === null) {
      throw new Error("Must call `API::Initialize` before getting an instance");
    } else {
      return API.instance as API;
    }
  }

  async send<T>(
    endPoint: string,
    method = "GET",
    body: { [index: string]: any } = {},
    urlParams: { [index: string]: any } = {},
    otherData: RequestInit = {}
  ) {
    let url = `${this.baseUrl}${endPoint}`;

    for (const key in urlParams) {
      url = url.replace(`:${key}`, urlParams[key] as string);
    }

    const data: RequestInit = Object.assign(
      {},
      {
        method,
        body: method !== "GET" ? JSON.stringify(body) : undefined,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.jwt ? `Bearer ${this.jwt}` : undefined,
        },
      },
      otherData
    );

    try {
      const response = (await this.fetcher(url, data)) as TypedResponse<T>;

      // If unauthroized, notify callbacks
      if (response.status === 401) {
        this.notifyOnUnauthorized(response);
      }
      return response;
    } catch (e) {
      return e;
    }
  }

  delete<T>(
    endPoint: string,
    body = {},
    urlParams = {},
    queryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.send<T>(
      endPoint + this.generateQueryString(queryParams),
      "DELETE",
      body,
      urlParams,
      otherData
    );
  }

  post<T>(endPoint: string, body = {}, otherData: RequestInit = {}) {
    return this.send<T>(endPoint, "POST", body, {}, otherData);
  }

  private generateQueryString(queryParams: QueryParams) {
    const keys = Object.keys(queryParams);
    if (keys.length === 0) {
      return "";
    }

    // We must encode both key and value as a URI component
    // e.g., if they themself contain & or = which must be parsed by server later.
    const subStrings = keys.map((key) => {
      const value = queryParams[key];
      let stringValue: string;
      stringValue = JSON.stringify(value);
      return `${encodeURIComponent(key)}=${encodeURIComponent(stringValue)}`;
    });
    const queryString = `?${subStrings.join("&")}`;

    return queryString;
  }

  get<T>(
    endPoint: string,
    urlParams = {},
    queryParams: QueryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.send<T>(
      endPoint + this.generateQueryString(queryParams),
      "GET",
      {},
      urlParams,
      otherData
    );
  }

  put<T>(
    endPoint: string,
    body = {},
    urlParams = {},
    queryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.send<T>(
      endPoint + this.generateQueryString(queryParams),
      "PUT",
      body,
      urlParams,
      otherData
    );
  }

  setJWT(token: string | null) {
    this.jwt = token;
  }

  getJWT() {
    return this.jwt;
  }

  notifyOnUnauthorized(response: Response) {
    for (const cb of this.onUnauthourized) {
      cb(response);
    }
  }
  addOnUnauthorized(callback: ResponseCallback) {
    this.onUnauthourized.push(callback);
  }
  removeOnUnauthorized(callback: ResponseCallback) {
    const index = this.onUnauthourized.findIndex((cb) => cb === callback);
    if (index !== -1) {
      // Remove callback by mutating array in-place
      this.onUnauthourized.splice(index, 1);
    }
  }
}
