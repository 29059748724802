import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import ScreenContent from "../../components/ScreenContent";
import styles from "./Admin.css";
import AdminRequesterRoutes from "./Requester/AdminRequesterRoutes";
import AdminResponderRoutes from "./Responder/AdminResponderRoutes";

type Props = RouteComponentProps;

const AdminScreen: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { match, history, location } = props;

  let currentTab: string = "responder";

  if (window.location.href.indexOf("requester") > -1) {
    currentTab = "requester";
  }

  return (
    <ScreenContent>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        className={styles.appBar}
      >
        <Typography variant="subtitle1">
          <Link
            to="/admin/responder"
            className={
              "responder" == currentTab ? styles.linkActive : styles.link
            }
            onClick={() => (currentTab = "responder")}
          >
            {t("adminDashboard.tabs.responder")}
          </Link>
          <Link
            to="/admin/requester"
            className={
              "requester" == currentTab ? styles.linkActive : styles.link
            }
            onClick={() => (currentTab = "requester")}
          >
            {t("adminDashboard.tabs.requester")}
          </Link>
        </Typography>
      </AppBar>

      <Container className={styles.container}>
        <Switch>
          <Route
            path={`${match.path}/responder`}
            component={AdminResponderRoutes}
          />
          <Route
            path={`${match.path}/requester`}
            component={AdminRequesterRoutes}
          />
          <Redirect to={`${match.path}/responder`} />
        </Switch>
      </Container>
    </ScreenContent>
  );
};

export default AdminScreen;
