import * as React from "react";
import styles from "./TermsContent.css"
import TypographyRegular from "../Typography/TypographyRegularChildren"

class TermsContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <TypographyRegular>Allmänna villkor – Vårdkällan (KGT Care AB 559116-1459)</TypographyRegular>
            <ol>
              <li><TypographyRegular>Allmänt och definitioner</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Dessa användarvillkor för användare gäller för användning av den tjänst som tillhandahålls av KGT Care AB, organisationsnummer 559116-1459, under namnet Vårdkällan.</TypographyRegular> </li>
                <li><TypographyRegular>Vårdkällan tillhandahåller en app vid namn Vårdkällan samt en hemsida, www.vårdkällan.se.</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>I Appen kan användaren söka efter och ansöka om att utföra vårdrelaterade uppdrag som konsult hos vårdgivare som utannonserat uppdrag.</TypographyRegular> </li>
                  <li><TypographyRegular>Via hemsidan kommer personal från vårdenheter kunna utannonsera uppdrag samt boka och administrera Vårdkällans konsulter.</TypographyRegular> </li>
                </ol>
                <li><TypographyRegular>När användaren registrerar sitt användarkonto accepterar användaren också de Allmänna villkoren, som tillsammans med användarens registrering av användarkontot och gällande anställningsavtal (som definieras nedan) utgör avtalet mellan användaren och Vårdkällan.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Användarkonto</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Användaren måste skapa ett användarkonto i Appen innan Tjänsten kan användas. Användaren ansvarar för att informationen som lämnas är riktig, uppdaterad och fullständig. Användarkontot är personligt och användaren måste hålla lösenordet väl skyddat och inte ge annan person tillgång till tjänsten genom användarkontot.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Tjänster, Uppdrag och behörighet</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Tjänsten ger användaren möjlighet att komma i kontakt med vårdgivare som utannonserat uppdrag. I tjänsten kan användaren söka och anmäla sig till tillgängliga uppdrag. Om användaren är aktuell (bokas) för att utföra ett uppdrag kommer användaren att meddelas detta i tjänsten. Bokningen sker och godkänns direkt av vårdenheten som utannonserat uppdraget.</TypographyRegular> </li>
                <li><TypographyRegular>För att vara behörig att använda Tjänsten måste Användaren tillhöra någon av följande yrkesgrupper:</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Legitimerad sjuksköterska</TypographyRegular> </li>
                  <li><TypographyRegular>Legitimerad läkare</TypographyRegular> </li>
                </ol>
                <li><TypographyRegular>Innan användaren kan ansöka om utannonserade uppdrag kommer Vårdkällan att kontrollera legitimation som hälso- och sjukvårdspersonal i Socialstyrelsens register över legitimerad hälso- och vårdpersonal (HoSp) samt att det inte förekommer några ärenden mot användaren i Inspektionen om vård och omsorgs (IVO) register. Efter den initiala kontrollen utförs kontrollen helårsvis. Vårdkällan kan efter kontroller neka användare att använda Tjänsten baserat på utfallet av dessa kontroller.</TypographyRegular> </li>
                <li><TypographyRegular>Användaren garanterar, genom att acceptera de Allmänna villkoren, att användaren:</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>tillhör någon av de ovan angivna yrkesgrupperna och är legitimerad som hälso- och sjukvårdspersonal</TypographyRegular> </li>
                  <li><TypographyRegular>har full behörighet att utföra de uppdrag som användaren ansöker om att få utföra</TypographyRegular> </li>
                  <li><TypographyRegular>har den utbildning, erfarenhet och de kvalifikationer som krävs för att utföra de uppdrag som användaren ansöker om att få utföra</TypographyRegular> </li>
                  <li><TypographyRegular>har nödvändiga tillstånd från relevanta myndigheter eller andra tredje parter</TypographyRegular> </li>
                  <li><TypographyRegular>inte är oförmögen, obehörig eller av andra skäl olämplig för att utföra de uppdrag som användaren ansöker om att få utföra</TypographyRegular> </li>
                </ol>
                <li><TypographyRegular>När användaren utför ett uppdrag som konsult för Vårdkällan måste utförandet vara av kvalitet som kan förväntas av skicklig och erfaren hälso- och sjukvårdspersonal, samt i enlighet med gällande etiska och professionella riktlinjer för hälso- och sjukvårdspersonal. Utförandet av ett uppdrag måste alltid ske i enlighet med tillämpliga lagar och förordningar.</TypographyRegular> </li>
                <li><TypographyRegular>En anmälan för att utföra ett uppdrag är bindande. Specifika detaljer, såsom tid, plats och ersättning, framgår av uppdragets beskrivning.</TypographyRegular> </li>
                <li><TypographyRegular>Underskrift av anställningsavtal signeras med e-legitimationen BankID genom tredjeparts partner.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Anställning för utförande av Uppdrag</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Om vårdgivaren väljer användaren för att utföra ett utannonserat uppdrag kommer användaren att anställas av Vårdkällan. Anställningen är en allmän visstidsanställning begränsad till den tid som uppdraget avser och som anges i Vårdkällans anställningsavtal. Användarens anställning regleras av Vårdkällans anställningsavtal om allmän visstidsanställning.</TypographyRegular> </li>
                <li><TypographyRegular>Innan användaren påbörjar utförandet av ett uppdrag, måste användaren ha läst, förstått och godkänt anställningsavtalet.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Rätten att använda appen</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Förutsatt att användaren uppfyller de allmänna villkoren så ger Vårdkällan användaren en icke-exklusiv och icke-överlåtbar rätt att använda Tjänsten via sitt användarkonto samt att använda appen på sin Android- eller iOS-enhet.</TypographyRegular></li>
                <li><TypographyRegular>Användaren får inte använda, kopiera eller överföra appen, eller delar därav, på annat sätt än vad som tillåts enligt de allmänna villkoren. Användaren får inte heller förändra, utveckla eller skapa tillägg till Tjänsten och appen.</TypographyRegular></li>
                <li><TypographyRegular>Användaren får inte upplåta underlicens, uthyra, utlåna eller på annat sätt tillåta tredje part att, direkt eller indirekt, förfoga eller på annat sätt använda Tjänsten och Appen.</TypographyRegular></li>
                <li><TypographyRegular>Användaren får inte dekompilera eller på annat sätt försöka återskapa Tjänstens och appens källkod, eller skapa säkerhetskopior, utöver vad som anges i tvingande lag.</TypographyRegular></li>
                <li><TypographyRegular>Användaren får endast använda Tjänsten i enlighet med de allmänna villkoren och Vårdkällans instruktioner för användning av Tjänsten, samt i enlighet med gällande lagstiftning. Inget innehåll som är olagligt, integritetskränkande eller oetiskt eller som skäligen kan befaras användas för sådana olagliga, integritetskränkande eller oetiska syften får hanteras inom ramen för Tjänsten. Användaren får inte använda Tjänsten eller appen på något sätt som kan inkräkta på någon upphovsrätt eller annan immateriell rättighet tillhörande Vårdkällan eller tredje part.</TypographyRegular></li>
              </ol>

              <li><TypographyRegular>Ansvarsbegränsning och skadeslöshet</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Tjänsten och appen tillhandahålls i befintligt skick utan garantier av något slag och användningen av Tjänsten är Användarens ansvar. Vårdkällan lämnar inte några garantier, direkta, underförstådda eller på annat sätt, rörande tillgänglighet, kvalitet, duglighet för något särskilt syfte, lämplighet eller riktighet av Tjänsten och appen.</TypographyRegular></li>
                <li><TypographyRegular>Tjänsten kan komma att innehålla information eller innehåll från tredje part, oftast vårdenheter, exempelvis beskrivningar av uppdrag. Den tredje parten är ansvarig för innehållet, dess ursprung och presentation. Vårdkällan kan inte under några omständigheter hållas ansvarig för att information från tredje part är saklig, korrekt eller relevant.</TypographyRegular></li>
              </ol>

              <li><TypographyRegular>Personuppgifter</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Vårdkällan är personuppgiftsansvarig för behandlingen av användarens personuppgifter som samlas in i samband med skapande av konto och vid användning av Tjänsten.  Vårdkällan behandlar personuppgifterna i enlighet med personuppgiftslagen (1998:204) eller, efter den 24 maj 2018, Dataskyddsförordningen (EU 2016/679) och Vårdkällans dataskyddspolicy.</TypographyRegular></li>
                <li><TypographyRegular>Mer information om Vårdkällans behandling av personuppgifter finns i Vårdkällans dataskyddspolicy.</TypographyRegular></li>
              </ol>

              <li><TypographyRegular>Immateriella rättigheter</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>All upphovsrätt, varumärken och andra immateriella rättigheter (registrerade och oregistrerade) i Tjänsten och appen tillhör Vårdkällan och/eller tredje man. Ingenting i de allmänna villkoren ger användaren en rättighet eller licens att använda någon upphovsrätt, varumärke, eller annan immateriell rättighet som ägs eller kontrolleras av Vårdkällan.</TypographyRegular></li>
                <li><TypographyRegular>Användaren erhåller endast den begränsade rätten att använda appen och Tjänsten som särskilt framgår av dessa allmänna villkor.</TypographyRegular></li>
              </ol>

              <li><TypographyRegular>Giltighetstid</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>De allmänna villkoren gäller tills vidare. Vårdkällan har rätt att när som helst säga upp användarens rätt att använda Tjänsten.</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Om användaren utför ett uppdrag gäller avtalet trots eventuell uppsägning av användarens rätt att använda Tjänsten till dess uppdraget är utfört och avslutat. Anställningsavtalet kan endast sägas upp på de sätt som framgår däri.</TypographyRegular></li>
                </ol>
              </ol>

              <li><TypographyRegular>Övriga villkor</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Om Användaren bryter mot avtalet har Vårdkällan rätt att omedelbart stänga av användaren från Tjänsten tills vidare eller avsluta användarens användarkonto, utan något ansvar gentemot användaren för detta.</TypographyRegular></li>
                <li><TypographyRegular>Vårdkällan förbehåller sig rätten att ändra de allmänna villkoren. Användaren kommer i så fall att informeras via e-post trettio (30) dagar innan de nya villkoren börjar gälla. Genom att fortsätta använda Tjänsten efter det att information har skickats till angiven e-postadress om att de allmänna villkoren har ändrats, godkänner användaren de ändrade Allmänna villkoren.</TypographyRegular></li>
                <li><TypographyRegular>Vårdkällan kan efter eget gottfinnande och när som helst ändra i eller upphöra med Tjänsten och appen. Användaren accepterar att Vårdkällan inte ansvarar gentemot Användaren eller någon tredje part för sådan ändring eller upphörande.</TypographyRegular></li>
                <li><TypographyRegular>Användaren har inte rätt att överlåta några rättigheter eller skyldigheter under de allmänna villkoren utan skriftligt godkännande från Vårdkällan.</TypographyRegular></li>
                <li><TypographyRegular>Vårdkällan har rätt att överlåta avtalet till annat bolag i samma koncern som Vårdkällan eller till tredje man i anslutning till en överlåtelse av alla eller väsentligen alla Vårdkällans tillgångar.</TypographyRegular></li>
              </ol>

              <li><TypographyRegular>Tillämplig lag</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>De allmänna villkoren ska tolkas och tillämpas i enlighet med svensk lag.</TypographyRegular></li>
              </ol>
            </ol>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsContent;
