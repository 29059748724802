import * as React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import API from "kgt-api";
import { GetResponseBody, Responder } from "kgt-api/dist/ResponderRequest";
import RequestInformation from "./RequestInformation";
import style from "./RequestList.css";
import { withRouter, RouteComponentProps } from "react-router";
import i18n from "i18next";
import SpinLoader from "../SpinLoader";
import { convertExperienceToText } from "kgt-api/dist/utils/experienceConverter";
import { RequestStatus } from "kgt-api/dist/Request";
import TypographyTitle from "../Typography/TypographyTitle";
import Cell from "../Table/Cell";
import Theme from "../../utils/Theme";

interface Props extends RouteComponentProps<{ requestId: string }> { }

interface State {
  respondersResponse?: GetResponseBody;
  ready: boolean;
}

class RequestInterestList extends React.Component<Props, State> {
  state: State = {
    ready: false
  };

  async componentDidMount() {
    // responderRequest.get() fetches an array containing Responder-Status pairs.
    const response = await API.Instance().responderRequest.get({
      id: this.props.match.params.requestId
    });
    const interestedResponders = await response.json();
    this.setState({ respondersResponse: interestedResponders, ready: true });
  }

  handleOnClick = (responderId: string) => {
    this.props.history.push(
      `${this.props.location.pathname}/responder/${responderId}`
    );
  };

  render() {
    const { ready } = this.state;

    if (ready) {
      return (
        <React.Fragment>
          <RequestInformation />
          <TypographyTitle text={i18n.t("requestInterestList.headline")} />
          <Table>
            <TableHead>
              <TableRow>
                <Cell text={i18n.t("requestInterestList_name")} />
                <Cell text={i18n.t("requestInterestList_experience")} />
                <Cell text={i18n.t("requestList_skills")} />
                <Cell text={i18n.t("requestList_status")} />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.respondersResponse &&
                this.state.respondersResponse.map(responderAndStatus => (
                  <RequestInterestRow
                    responder={responderAndStatus.responder}
                    responderId={responderAndStatus.responder.id}
                    approvalStatus={responderAndStatus.status}
                    key={responderAndStatus.responder.id}
                    onClick={this.handleOnClick}
                  />
                ))}
            </TableBody>
          </Table>
        </React.Fragment>
      );
    } else {
      return <SpinLoader />;
    }
  }
}

export default withRouter(RequestInterestList);

interface RowProps {
  responder: Responder;
  responderId: string;
  approvalStatus: RequestStatus;
  onClick: (id: string) => void;
}

class RequestInterestRow extends React.Component<RowProps> {
  handleOnClick = () => {
    this.props.onClick(this.props.responder.id);
  };

  statusIcon = (approvalStatus: RequestStatus) => {
    if (approvalStatus === RequestStatus.APPROVED) {
      return (
        <span style={{ color: Theme.secondaryColour }}>
          {i18n.t("approveResponder.action.status.approved")}
        </span>
      )
    } else if (approvalStatus === RequestStatus.INACTIVE) {
      return (
        <span style={{ color: Theme.secondaryTextColour }}>
          {i18n.t("approveResponder.action.status.inactive")}
        </span>
      )
    } else if (approvalStatus === RequestStatus.CONTACTED) {
      return (
        <span style={{ color: Theme.secondaryColour }}>
          {i18n.t("approveResponder.action.status.signed")}
        </span>
      )
    } else {
      return (
        <span style={{ color: Theme.tertiaryColour }}>
          {i18n.t("approveResponder.action.status.waiting")}
        </span>
      )
    }
  };

  render() {
    const { responder } = this.props;
    return (
      <TableRow onClick={this.handleOnClick} hover className={style.row}>
        <Cell text={responder.name} />
        <Cell text={experienceToString(responder.experience)} />
        <Cell text={skillsToString(responder.skills)} />
        <Cell text={this.statusIcon(this.props.approvalStatus)} />
        <TableCell>
          <ArrowRight />
        </TableCell>
      </TableRow>
    );
  }
}

//Shorthand for converting experience from number to proper year-span format
//  Uses the API solution but also adds the "Years" text at the end
function experienceToString(experience: number) {
  if (experience <= 0) {
    return convertExperienceToText(experience) + " " + i18n.t("profile.year");
  } else {
    return convertExperienceToText(experience) + " " + i18n.t("profile.years");
  }
}

function skillsToString(skills: Skill[]) {
  if (skills.length == 0) {
    return "-"
  }
  let skillsArray = new Array(skills.length);
  let i = 0;

  skills.map(value => (
    skillsArray[i] = i18n.t(value.tag),
    i++
  ))

  return skillsArray.join(", ");
}