import Paper from "@material-ui/core/Paper";
import i18n from "i18next";
import API from "kgt-api";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Alert from "../../../components/Alert/Alert";
import RequesterList from "../../../components/RequesterList";
import SpinLoader from "../../../components/SpinLoader";

type Props = RouteComponentProps;

type Requesters = Array<{ id: string; name: string; address: string }>;

const AdminRequesterScreen: React.SFC<Props> = (props) => {
  const [loadingReqs, setLoadingReqs] = React.useState<boolean>(true);
  const [requesters, setRequesters] = React.useState<Requesters>([]);
  const [alertVisable, setAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  async function fetchRequesters() {
    const res = await API.Instance().requester.get();
    if (res.ok) {
      const data = await res.json();
      setRequesters(data.requesters);
    } else {
      setAlert(true);
      setAlertMessage(i18n.t("error_noNetwork"));
    }
    setLoadingReqs(false);
  }

  React.useEffect(() => {
    fetchRequesters();
  }, []);

  const gotoRequester = React.useCallback(
    (requesterId: string) => {
      props.history.push(`${props.location.pathname}/${requesterId}`);
    },
    [props.location.pathname]
  );

  const closeOverlay = () => {
    setAlert(false);
    setAlertMessage("");
  };

  return (
    <React.Fragment>
      {loadingReqs && <SpinLoader />}
      {!loadingReqs && (
        <Paper
          style={{ border: "1px solid #BDBDBD", padding: "1.5em" }}
          elevation={0}
          square={false}
        >
          <RequesterList
            requesters={requesters}
            onRequesterClick={gotoRequester}
            history={props.history}
            location={props.location}
            match={props.match}
          />
        </Paper>
      )}
      <Alert
        message={alertMessage}
        visible={alertVisable}
        closeOverlay={closeOverlay}
      ></Alert>
    </React.Fragment>
  );
};

export default AdminRequesterScreen;
