import React from "react";
import { Button } from "@material-ui/core";
import Theme from "../../utils/Theme";

interface ButtonWhiteProps {
  title: string;
  onClick: () => void;
  marginLeft?: any;
  marginRight?: any;
  whiteBackground?: boolean;
}

export default class ButtonWhite extends React.Component<ButtonWhiteProps> {
  render() {
    return (
      <Button
        variant="outlined"
        style={{
          color: Theme.primaryColour,
          border: "none",
          borderRadius: Theme.buttonBorderRadius,
          textTransform: "none",
          fontFamily: "Barlow",
          marginLeft: this.props.marginLeft,
          marginRight: this.props.marginRight,
          backgroundColor: this.props.whiteBackground ? "white" : "default"
        }}
        onClick={this.props.onClick}
      >
        {this.props.title}
      </Button>
    );
  }
}
