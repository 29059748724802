import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Theme from "../../utils/Theme";

interface RequesterTableRowProps {
    title: string;
    text: string;
}

export default class RequesterTableRow extends React.Component<RequesterTableRowProps> {
    render() {
        return (
            <TableRow>
                <TableCell component="th" style={{ padding: "1em" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                        {this.props.title}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {this.props.text}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }
}