import * as React from "react";
import ScreenContent from "../../components/ScreenContent";
import TermsContent from "../../components/TermsContent";

function TermsOfService() {
  return (
    <ScreenContent>
      <TermsContent />
    </ScreenContent>
  );
}

export default TermsOfService;
