import React from "react";
import TableCell from "@material-ui/core/TableCell";

interface CellBoldProps {
    text: string;
    maxWidth?: string;
}

export default class CellBold extends React.Component<CellBoldProps> {
    render() {
        return (
            <TableCell style={{ fontWeight: "bold", maxWidth: this.props.maxWidth }}>
                {this.props.text}
            </TableCell>
        )
    }
}
