import * as React from "react";
import styles from "./Container.css";
import classNames from "classnames";

const Container: React.FunctionComponent<{ className?: string }> = ({
  children,
  className
}) => <div className={classNames(styles.container, className)}>{children}</div>;

export default Container;
