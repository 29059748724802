import { Typography } from "@material-ui/core";
import CheckBox from "@material-ui/core/CheckBox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import i18n from "i18next";
import API from "kgt-api";
import { GetResponseBody } from "kgt-api/dist/Requester/_id/timeReport/timeReport";
import {
  PutOperation,
  PutRequestBody,
  TimeReport,
  TimeReportStatus,
} from "kgt-api/dist/TimeReport";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Alert from "../../components/Alert/Alert";
import Container from "../../components/Container";
import Session from "../../stores/Session";
import Theme from "../../utils/Theme";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import SpinLoader from "../SpinLoader";
import Cell from "../Table/Cell";
import CellBold from "../Table/CellBold";
import TypographyTitle from "../Typography/TypographyTitle";
import styles from "./TimeReport.css";

interface Props extends RouteComponentProps {}
interface InjectedProps extends Props {
  session: Session;
}

interface State {
  timeReportsResponse?: GetResponseBody;
  timeReportsApproved?: Array<{
    timeReport: TimeReport;
    checked: boolean;
  }>;
  timeReportsUnapproved?: Array<{
    timeReport: TimeReport;
    checked: boolean;
  }>;
  checked: boolean;
  ready: boolean;
  helpTextNoChecked: boolean;
  helpTextNoUnapproved: boolean;
  alertVisible: boolean;
  alertMessage: string;
  disableButton: boolean;
}

@inject("session")
@observer
class TimeReportList extends React.Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  state: State = {
    ready: false,
    helpTextNoChecked: false,
    helpTextNoUnapproved: false,
    checked: false,
    alertVisible: false,
    alertMessage: "",
    disableButton: false,
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { session } = this.injected;

    if (session.data === null) {
      return;
    }
    try {
      const response = await API.Instance().requester._id.timereport.get({
        id: session.data.profileId,
      });

      const timeReports = await response.json();

      timeReports.sort((t1, t2) => {
        if (t1.request.startTime < t2.request.startTime) {
          return 1;
        }
        if (t1.request.startTime > t2.request.startTime) {
          return -1;
        }
        return 0;
      });

      const trCheck = await timeReports.map((timeReport) => {
        const checked = this.state.checked;
        return { timeReport, checked };
      });

      const timeReportsApp = trCheck.filter((tr) => {
        return tr.timeReport.status === TimeReportStatus.approved;
      });

      const timeReportsUnapp = trCheck.filter((tr) => {
        return tr.timeReport.status === TimeReportStatus.unapproved;
      });

      this.setState({
        timeReportsApproved: timeReportsApp,
        timeReportsUnapproved: timeReportsUnapp,
        ready: true,
      });
    } catch {
      this.setState({
        ready: true,
        alertVisible: true,
        alertMessage: i18n.t("error_noNetwork"),
        disableButton: true,
      });
    }
  };

  // Redirects to detailed timereport view
  handleOnClick = (requestId: string) => {
    this.props.history.push(`${this.props.match.path}/${requestId}`);
  };

  checkManyTimeReports = () => {
    if (this.state.timeReportsUnapproved) {
      const newReports = this.state.timeReportsUnapproved.map((tr) => {
        if (
          tr.timeReport.status === TimeReportStatus.unapproved &&
          !tr.checked
        ) {
          return { ...tr, checked: true };
        } else {
          return tr;
        }
      });
      this.setState({ timeReportsUnapproved: newReports });
    }
  };

  // If there are no checked time reports when trying to approve, an error message will show up.
  approveTimeReports = async () => {
    if (
      this.state.timeReportsUnapproved &&
      this.state.timeReportsUnapproved.length > 0
    ) {
      const reportsForApproval = this.state.timeReportsUnapproved.filter(
        (trCheck) => {
          return trCheck.checked === true;
        }
      );

      if (reportsForApproval.length !== 0) {
        const promises = reportsForApproval.map((tr) => {
          const body: PutRequestBody = {
            requestId: tr.timeReport.request.id,
            responderId: tr.timeReport.responder.id,
            operation: PutOperation.approving,
          };
          return API.Instance().timereport.put(body);
        });
        const results = await Promise.all(promises);
        this.setState({ helpTextNoChecked: false });
        this.fetchData();
      } else {
        this.setState({ helpTextNoChecked: true });
      }
      this.setState({ helpTextNoUnapproved: false });
    } else {
      this.setState({ helpTextNoUnapproved: true });
    }
  };

  // This is needed for handling single checks.
  handleOnCheck = (checked: boolean, id: string) => {
    if (this.state.timeReportsUnapproved) {
      const newReports = this.state.timeReportsUnapproved.map((tr) => {
        if (tr.timeReport.request.id === id) {
          return { ...tr, checked: !checked };
        } else {
          return tr;
        }
      });
      this.setState({ timeReportsUnapproved: newReports });
    }
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  render() {
    const { ready, disableButton } = this.state;
    const timeReportsUnapproved = this.state.timeReportsUnapproved || [];
    const timeReportsApproved = this.state.timeReportsApproved || [];

    if (ready) {
      return (
        <Container className={styles.container}>
          <Paper
            style={{ border: "1px solid #BDBDBD", padding: "1.5em" }}
            elevation={0}
            square={false}
          >
            <div style={{ marginBottom: Theme.largeSpacing }}>
              <div style={{ display: "flex" }}>
                <TypographyTitle
                  text={i18n.t("timeReportList_unapproved_timeReports")}
                />
                <ButtonPrimary
                  onClick={this.checkManyTimeReports}
                  disabled={disableButton}
                  title={i18n.t("timeReportList_choose_all")}
                  marginLeft="auto"
                />
                <ButtonPrimary
                  onClick={this.approveTimeReports}
                  disabled={disableButton}
                  title={i18n.t("timeReportList_approve_all")}
                  marginLeft={Theme.smallSpacing}
                />
              </div>
              {timeReportsUnapproved.length == 0 ? (
                <Container>
                  {this.state.helpTextNoUnapproved ? (
                    <Typography component="p" color="error">
                      {i18n.t("timeReportList_noUnnapproved")}
                    </Typography>
                  ) : (
                    <Typography component="p">
                      {i18n.t("timeReportList_noUnnapprovedTable")}
                    </Typography>
                  )}
                </Container>
              ) : (
                <Table style={{ marginTop: Theme.mediumSpacing }}>
                  <TableHead>
                    <TableRow>
                      <CellBold
                        maxWidth="70px"
                        text={i18n.t("timeReportList_request_check")}
                      />
                      <CellBold
                        maxWidth="90px"
                        text={i18n.t("timeReportList_request_date")}
                      />
                      <CellBold
                        maxWidth="90px"
                        text={i18n.t("timeReportList_request_title")}
                      />
                      <CellBold
                        maxWidth="90px"
                        text={i18n.t("timeReportList_responder_name")}
                      />
                      <CellBold
                        maxWidth="90px"
                        text={i18n.t("timeReportList_request_hours")}
                      />
                      <CellBold
                        maxWidth="30px"
                        text={i18n.t("timeReportList_request_reported")}
                      />
                      <TableCell style={{ maxWidth: "3px" }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.timeReportsUnapproved &&
                      this.state.timeReportsUnapproved.map((timeReport) => (
                        <RequestRow
                          title={timeReport.timeReport.request.title}
                          startTime={timeReport.timeReport.request.startTime}
                          duration={timeReport.timeReport.request.duration}
                          hoursWorked={timeReport.timeReport.hoursWorked}
                          requestId={timeReport.timeReport.request.id}
                          status={timeReport.timeReport.status}
                          checked={timeReport.checked}
                          key={timeReport.timeReport.request.id}
                          name={timeReport.timeReport.responder.name}
                          onClick={this.handleOnClick}
                          onCheck={this.handleOnCheck}
                        />
                      ))}
                  </TableBody>
                </Table>
              )}
              {this.state.helpTextNoChecked && (
                <Typography
                  component="p"
                  color="error"
                  className={styles.approve}
                >
                  {i18n.t("timeReportList_noChecked")}
                </Typography>
              )}
            </div>

            <TypographyTitle
              text={i18n.t("timeReportList_approved_timeReports")}
            />
            {timeReportsApproved.length == 0 ? (
              <Container>
                <Typography component="p">
                  {i18n.t("timeReportList_noApprovedTable")}
                </Typography>
              </Container>
            ) : (
              <Table style={{ marginTop: Theme.mediumSpacing }}>
                <TableHead>
                  <TableRow>
                    <CellBold
                      maxWidth="100px"
                      text={i18n.t("timeReportList_request_date")}
                    />
                    <CellBold
                      maxWidth="114px"
                      text={i18n.t("timeReportList_request_title")}
                    />
                    <CellBold
                      maxWidth="114px"
                      text={i18n.t("timeReportList_responder_name")}
                    />
                    <CellBold
                      maxWidth="114px"
                      text={i18n.t("timeReportList_request_hours")}
                    />
                    <CellBold
                      maxWidth="114px"
                      text={i18n.t("timeReportList_request_reported")}
                    />
                    <TableCell style={{ maxWidth: "30px" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.timeReportsApproved &&
                    this.state.timeReportsApproved.map((timeReport) => (
                      <RequestRow
                        title={timeReport.timeReport.request.title}
                        startTime={timeReport.timeReport.request.startTime}
                        duration={timeReport.timeReport.request.duration}
                        hoursWorked={timeReport.timeReport.hoursWorked}
                        requestId={timeReport.timeReport.request.id}
                        status={timeReport.timeReport.status}
                        checked={timeReport.checked}
                        key={timeReport.timeReport.request.id}
                        name={timeReport.timeReport.responder.name}
                        onClick={this.handleOnClick}
                        onCheck={this.handleOnCheck}
                      />
                    ))}
                </TableBody>
              </Table>
            )}
            <Alert
              message={this.state.alertMessage}
              visible={this.state.alertVisible}
              closeOverlay={this.closeOverlay}
            ></Alert>
          </Paper>
        </Container>
      );
    } else {
      return <SpinLoader />;
    }
  }
}

export default withRouter(TimeReportList);

interface RowProps {
  title: string;
  startTime: Date;
  duration: number;
  hoursWorked: number;
  requestId: string;
  status: TimeReportStatus;
  checked: boolean;
  name: string;
  onClick: (id: string) => void;
  onCheck: (checked: boolean, id: string) => void;
}

class RequestRow extends React.Component<RowProps> {
  handleOnClick = () => {
    this.props.onClick(this.props.requestId);
  };

  handleOnCheck: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.props.onCheck(this.props.checked, this.props.requestId);
  };

  // calculate duration of shift in h and mins
  calculateDuration = (duration: number) => {
    const hours = Math.floor(duration);
    const minutes = Math.round((duration % 1) * 59);
    return (
      hours +
      i18n.t("requestList_h") +
      " " +
      minutes +
      i18n.t("requestList_min")
    );
  };

  durationVsHoursWorked = (duration: number, hoursWorked: number) => {
    let color = Theme.secondaryColour;
    if (duration < hoursWorked) {
      color = Theme.tertiaryColour;
    } else if (duration > hoursWorked) {
      color = Theme.secondaryTextColour;
    }
    return (
      <span style={{ color: color }}>
        {this.calculateDuration(hoursWorked)}
      </span>
    );
  };

  render() {
    return (
      <TableRow hover onClick={this.handleOnClick}>
        {this.props.status === TimeReportStatus.unapproved && (
          <TableCell style={{ maxWidth: "70px" }}>
            <CheckBox
              checked={this.props.checked}
              onClick={(e) => e.stopPropagation()}
              onChange={this.handleOnCheck}
            />
          </TableCell>
        )}
        <Cell
          maxWidth="90px"
          text={new Date(this.props.startTime).toLocaleDateString()}
        />
        <Cell maxWidth="90px" text={this.props.title} />
        <Cell maxWidth="90px" text={this.props.name} />
        <Cell
          maxWidth="90px"
          text={this.calculateDuration(this.props.duration)}
        />
        <Cell
          maxWidth="90px"
          text={this.durationVsHoursWorked(
            this.props.duration,
            this.props.hoursWorked
          )}
        />
        <TableCell style={{ maxWidth: "30px" }}>
          <ArrowRight />
        </TableCell>
      </TableRow>
    );
  }
}
