import { Endpoint } from "../../Endpoint";
import API from "../../API";
import ResponderInterestEndpoint from "./interest/get";

const name = "/:id";

interface GetResponseBody {
  id: string;
  name: string;
  personalNumber: string;
  email: string;
  phone: string;
  presentation: string;
  skills: Array<{ id: string; tag: string }>;
  experience: number;
  billingStreet: string;
  billingCity: string;
  billingZip: string;
  hsaId: string;
  sithsExpiration: Date;
  eServiceCard: boolean;
  driversLicence: boolean;
  verified: boolean;
  profilePicture: string;
  bankAccountNumber: string;
  clearingNumber: string;
  bankName: string;
  regions: Region[];
}

interface GetParams {
  id: string;
}

interface PutParams {
  id: string;
}

interface PutRequestBody {
  name?: string;
  email?: string;
  personalNumber?: string;
  presentation?: string;
  phone?: string;
  experience?: number;
  hsaId?: string;
  sithsExpiration?: Date;
  eServiceCard?: boolean;
  driversLicence?: boolean;
  billingStreet?: string;
  billingCity?: string;
  billingZip?: string;
  skills?: Array<{ id: string }>;
  bankAccountNumber?: string;
  clearingNumber?: string;
  bankName?: string;
  regions?: Region[];
  pushToken?: string;
}

interface Region {
  id: string;
}

type PutResponseBody = "OK";

export {
  name,
  GetResponseBody,
  PutRequestBody,
  PutParams,
  PutResponseBody,
  GetParams
};

export default class OneResponderEndpoint extends Endpoint {
  interest: ResponderInterestEndpoint;

  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);

    this.interest = new ResponderInterestEndpoint(api, this);
  }

  get(urlParams: GetParams) {
    return this.sendGet<GetResponseBody>(urlParams);
  }

  put(body: PutRequestBody, urlParams: PutParams) {
    return this.sendPut<PutResponseBody>(body, urlParams);
  }
}
