import { Endpoint } from "../Endpoint";
import API from "../API";
import OneRequestEndpoint from "./_id";

const name = "/request";

export interface Request {
  startTime: string;
  duration: number;
  yearExperience: number;
  title: string;
  description: string;
  compensation: number;
  skills: Array<{ id: string }>;
}
type PostRequestBody = Array<Request>;

interface Requester {
  id: string;
}

type PostResponseBody = Array<{
  id: string;
  startTime: string;
  duration: number;
  yearExperience: number;
  title: string;
  description: string;
  skills: Array<{ id: string }>;
}>;

type GetResponseBody = Array<{
  id: string;
  startTime: Date;
  duration: number;
  yearExperience: number;
  createdAt: Date;
  title: string;
  description: string;
  compensation: number;
  payMargin: number;
  requester: Requester;
  skills: Array<{ id: string }>;
}>;

interface GetQueryParams {
  regions?: string[];
  requesters?: string[];
  ignoreHourBuffer?: boolean;
}

export enum RequestStatus {
  APPROVED = "approved",
  CONTACTED = "contacted",
  WAITING = "waiting",
  INACTIVE = "inactive"
}

export {
  name,
  PostRequestBody,
  PostResponseBody,
  GetResponseBody,
  GetQueryParams
};

export default class RequestEndpoint extends Endpoint {
  _id: OneRequestEndpoint;

  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);

    this._id = new OneRequestEndpoint(api, this);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }

  get(params: GetQueryParams) {
    return this.sendGet<GetResponseBody>({}, params);
  }
}
