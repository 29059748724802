import * as React from "react";
import ScreenContent from "../components/ScreenContent";
import Container from "../components/Container";
import LoginComponent from "../components/Login";

const Login = () => (
    <ScreenContent>
      <Container>
        <LoginComponent />
      </Container>
    </ScreenContent>
);

export default Login;
