import * as React from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withRouter, RouteComponentProps } from "react-router";
import API from "kgt-api";
import { GetResponseBody as ResponderBody } from "kgt-api/dist/Responder/_id";
import { Request } from "kgt-api/dist/Request/_id";
import { RequestStatus } from "kgt-api/dist/Request";
import { PutRequestBody } from "kgt-api/dist/ResponderRequest";
import i18n from "i18next";
import SpinLoader from "../SpinLoader";
import { convertExperienceToText } from "kgt-api/dist/utils/experienceConverter";
import Cell from "../Table/Cell";
import CellBold from "../Table/CellBold";
import Theme from "../../utils/Theme";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonWarning from "../Buttons/ButtonWarning";
import TypographySubtitle from "../Typography/TypographySubtitle";
import styles from "./InterestedResponder.css";
import defaultProfilePic from "../../images/default_profile.png";
import TypographyTitle from "../Typography/TypographyTitle";
import { subHours, differenceInMinutes, parseISO, subMinutes } from "date-fns";

const BUFFER_TIME_CANCEL_REQUEST: number = 30;

interface Props
  extends RouteComponentProps<{ responderId: string; requestId: string }> {}
interface State {
  responder?: ResponderBody;
  status: RequestStatus;
  ready: boolean;
  disableApprove: boolean;
  disableCancel: boolean;
  request?: Request;
}

class InterestedResponder extends React.Component<Props, State> {
  state: State = {
    status: RequestStatus.WAITING,
    ready: false,
    disableApprove: false,
    disableCancel: true,
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const requestResp = await API.Instance().request._id.get({
      id: this.props.match.params.requestId,
    });
    const req = await requestResp.json();
    const responderPromise = API.Instance().responder._id.get({
      id: this.props.match.params.responderId,
    });
    const interestPromise = API.Instance().request._id.get(
      {
        id: this.props.match.params.requestId,
      },
      {
        responderId: this.props.match.params.responderId,
      }
    );

    const responderResponse = await responderPromise;
    const interestResponse = await interestPromise;

    const responder = await responderResponse.json();
    const interest = await interestResponse.json();

    if (interest.responderInterest !== undefined) {
      this.setState({ status: interest.responderInterest.status });
    }

    this.setState({
      responder,
      ready: true,
      request: req.request,
    });
  };

  // Handles the functionality of the approval button being pressed.
  handleApproved = async () => {
    try {
      const body: PutRequestBody = {
        requestId: this.props.match.params.requestId,
        responderId: this.props.match.params.responderId,
        status: RequestStatus.APPROVED,
      };
      const res = await API.Instance().responderRequest.put(body);
      this.fetchData();
    } catch (err) {
      console.error(err);
      // Add visual feedback of error.
    }
  };
  handleSigned = async () => {
    try {
      const body: PutRequestBody = {
        requestId: this.props.match.params.requestId,
        responderId: this.props.match.params.responderId,
        status: RequestStatus.CONTACTED,
      };
      const res = await API.Instance().responderRequest.put(body);
      this.fetchData();
    } catch (err) {
      console.error(err);
      // Add visual feedback of error.
    }
  };
  // Handles the functionality of the cancelation button being pressed.
  handleCancelation = async () => {
    try {
      const body: PutRequestBody = {
        requestId: this.props.match.params.requestId,
        responderId: this.props.match.params.responderId,
        status: RequestStatus.WAITING,
      };
      await API.Instance().responderRequest.put(body);
      this.fetchData();
    } catch (err) {
      console.error(err);
      // Add visual feedback of error.
    }
  };

  //Shorthand for converting experience from number to proper year-span format
  //  Uses the API solution but also adds the "Years" text at the end
  convertExperienceToFullText = (num: number) => {
    if (num <= 0) {
      return convertExperienceToText(num) + " " + i18n.t("profile.year");
    } else {
      return convertExperienceToText(num) + " " + i18n.t("profile.years");
    }
  };

  getStatus = (approvalStatus: RequestStatus) => {
    if (approvalStatus === RequestStatus.APPROVED) {
      return (
        <span style={{ color: Theme.secondaryColour }}>
          {i18n.t("approveResponder.action.status.approved")}
        </span>
      );
    } else if (approvalStatus === RequestStatus.INACTIVE) {
      return (
        <span style={{ color: Theme.secondaryTextColour }}>
          {i18n.t("approveResponder.action.status.inactive")}
        </span>
      );
    } else if (approvalStatus === RequestStatus.CONTACTED) {
      return (
        <span style={{ color: Theme.secondaryColour }}>
          {i18n.t("approveResponder.action.status.signed")}
        </span>
      );
    } else {
      return (
        <span style={{ color: Theme.tertiaryColour }}>
          {i18n.t("approveResponder.action.status.waiting")}
        </span>
      );
    }
  };

  translateBoolean = (bool: Boolean) => {
    if (bool) {
      return i18n.t("yes");
    }
    return i18n.t("no");
  };

  handleTimeCheck = (request: Request, buffer: number) => {
    var now: Date = new Date();
    if (typeof request.startTime === "string") {
      var startOfRequest: Date = parseISO(request.startTime);
    } else {
      var startOfRequest: Date = request.startTime;
    }
    var startOfRequestInclBuffer: Date = subMinutes(startOfRequest, buffer);
    return differenceInMinutes(startOfRequestInclBuffer, now) <= 0;
  };

  skillsToString(skills: Skill[]) {
    if (skills.length == 0) {
      return "-";
    }
    let skillsArray = new Array(skills.length);
    let i = 0;

    skills.map((value) => ((skillsArray[i] = i18n.t(value.tag)), i++));

    return skillsArray.join(", ");
  }

  setProfilePic = (url: string) => {
    if (url) {
      return url;
    } else {
      return defaultProfilePic;
    }
  };

  render() {
    const { ready } = this.state;
    let withinBuffertime = true;
    if (this.state.request) {
      withinBuffertime = this.handleTimeCheck(
        this.state.request,
        BUFFER_TIME_CANCEL_REQUEST
      );
    }
    if (ready) {
      return (
        <div>
          {this.state.responder && (
            <React.Fragment>
              <div
                style={{ display: "flex", marginBottom: Theme.smallSpacing }}
              >
                <TypographyTitle
                  text={this.getStatus(this.state.status)}
                  marginLeft="auto"
                />
              </div>
              <Table>
                <colgroup>
                  <col style={{ width: "60%" }} />
                  <col style={{ width: "40%" }} />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: "none" }}>
                      <div style={{ marginBottom: Theme.largeSpacing }}>
                        <div style={{ marginBottom: Theme.smallSpacing }}>
                          <TypographySubtitle
                            text={i18n.t("adminContentRegisteredPerson_info")}
                          />
                        </div>
                        <Table>
                          <colgroup>
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "70%" }} />
                          </colgroup>
                          <TableBody>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_name"
                                )}
                              />
                              <Cell text={this.state.responder.name} />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_personalNumber"
                                )}
                              />
                              <Cell
                                text={this.state.responder.personalNumber}
                              />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_email"
                                )}
                              />
                              <Cell text={this.state.responder.email} />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_phoneNumber"
                                )}
                              />
                              <Cell text={this.state.responder.phone} />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_presentation"
                                )}
                              />
                              <Cell text={this.state.responder.presentation} />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>

                      <div style={{ marginBottom: Theme.largeSpacing }}>
                        <div style={{ marginBottom: Theme.smallSpacing }}>
                          <TypographySubtitle
                            text={i18n.t(
                              "adminContentRegisteredPerson_experienceCategory"
                            )}
                          />
                        </div>
                        <Table>
                          <colgroup>
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "70%" }} />
                          </colgroup>
                          <TableBody>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_experience"
                                )}
                              />
                              <Cell
                                text={this.convertExperienceToFullText(
                                  this.state.responder.experience
                                )}
                              />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_skills"
                                )}
                              />
                              <Cell
                                text={this.skillsToString(
                                  this.state.responder.skills
                                )}
                              />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>

                    <TableCell style={{ border: "none" }}>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: Theme.largeSpacing,
                        }}
                      >
                        <img
                          src={this.setProfilePic(
                            this.state.responder.profilePicture
                          )}
                          className={styles.rounded}
                        />
                      </div>
                      <div style={{ marginBottom: Theme.largeSpacing }}>
                        <div style={{ marginBottom: Theme.smallSpacing }}>
                          <TypographySubtitle
                            text={i18n.t(
                              "adminContentRegisteredPerson_otherInfo"
                            )}
                          />
                        </div>
                        <Table>
                          <colgroup>
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "70%" }} />
                          </colgroup>
                          <TableBody>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_eServiceCard"
                                )}
                              />
                              <Cell
                                text={this.translateBoolean(
                                  this.state.responder.eServiceCard
                                )}
                              />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_driversLicense"
                                )}
                              />
                              <Cell
                                text={this.translateBoolean(
                                  this.state.responder.driversLicence
                                )}
                              />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_hsaId"
                                )}
                              />
                              <Cell text={this.state.responder.hsaId} />
                            </TableRow>
                            <TableRow>
                              <CellBold
                                text={i18n.t(
                                  "adminContentRegisteredPerson_sithsExpiryDate"
                                )}
                              />
                              <Cell
                                text={new Date(
                                  this.state.responder.sithsExpiration
                                ).toLocaleDateString()}
                              />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <div
                style={{ display: "flex", marginBottom: Theme.smallSpacing }}
              >
                <ButtonWarning
                  onClick={this.handleCancelation}
                  disabled={
                    !(
                      this.state.status == RequestStatus.APPROVED ||
                      this.state.status == RequestStatus.CONTACTED
                    ) || withinBuffertime
                  }
                  title={i18n.t("approveResponder.action.cancel")}
                />
                <ButtonPrimary
                  onClick={this.handleApproved}
                  disabled={this.state.status !== RequestStatus.WAITING}
                  title={i18n.t("approveResponder.action.approve")}
                  marginLeft="auto"
                />
                {this.state.status == RequestStatus.APPROVED && (
                  <div>
                    <ButtonPrimary
                      onClick={this.handleSigned}
                      title={i18n.t("approveResponder.action.sign")}
                      marginLeft="5px"
                      green={true}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      );
    } else {
      return <SpinLoader />;
    }
  }
}

export default withRouter(InterestedResponder);
