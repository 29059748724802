import * as React from "react";
import { Typography } from "@material-ui/core";
import Container from "../Container";
import i18n from "i18next";
import styles from "./RegisterInterest.css";

class TextContent extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Container>
          {/* Must be styled properly if re-added
           <Typography variant="h2" component="h1" align="center">
            {i18n.t("registerInterest.textContent.heading")}
          </Typography> */}
          <Typography
            align="center"
            variant="h4"
            component="h2"
            className={styles.subheading}
          >
            {i18n.t("registerInterest.textContent.paragraph")}
          </Typography>
        </Container>
      </div>
    );
  }
}

export default TextContent;
