import * as React from "react";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router";
import TimeReportList from "../../components/RequesterTimeReport/TimeReportList";
import TimeReport from "../../components/RequesterTimeReport/TimeReport";
import styles from "./RequesterDashboard.css";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

type Props = RouteComponentProps;

const TabContainer: React.FunctionComponent = ({ children }) => (
  <Typography component="div" className={styles.tabContainer}>
    {children}
  </Typography>
);

const TimeReportTab: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { match, history, location } = props;

  return (
    <TabContainer>
        <Switch>
          <Route path={`${match.path}/:requestId`} component={TimeReport} />;
          <Route path={`${match.path}`} component={TimeReportList} />
        </Switch>
    </TabContainer>
  );
};

export default withRouter(TimeReportTab);
