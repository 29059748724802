import * as React from "react";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router";
import styles from "./RequesterDashboard.css";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import RequestList from "../../components/RequestList/RequestList";
import ResponderList from "../../components/RequestList/ResponderList";
import InterestedResponder from "../../components/RequestList/InterestedResponder";
import Container from "../../components/Container";
import Paper from "@material-ui/core/Paper";
import CreateRequest from "./CreateRequest";

type Props = RouteComponentProps;

const TabContainer: React.FunctionComponent = ({ children }) => (

  <Container className={styles.container}>
    <Paper
      style={{ border: "1px solid #BDBDBD", padding: "1.5em" }}
      elevation={0}
      square={false}
    >
      <Typography component="div" className={styles.tabContainer}>
        {children}
      </Typography>
    </Paper>
  </Container>
);

const RequestTab: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { match } = props;

  return (
    <TabContainer>
      <Switch>
        <Route path={`${match.path}/create`} component={CreateRequest} />;
        <Route path={`${match.path}/:requestId/responder/:responderId`} component={InterestedResponder} />;
        <Route path={`${match.path}/:requestId`} component={ResponderList} />;
        <Route path={`${match.path}`} component={RequestList} />;
      </Switch>
    </TabContainer>
  );
};

export default withRouter(RequestTab);
