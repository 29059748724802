import { LoginJWT } from "kgt-api/dist/Login";
import { observable, flow } from "mobx";
import jwt from "jsonwebtoken";
import API from "kgt-api";

const TOKEN_KEY = "JWT";

class Session {
  @observable data: LoginJWT | null = null;

  constructor() {
    const key = localStorage.getItem(TOKEN_KEY);
    if (key) {
      this.setToken(key);
    }
  }

  setToken = flow(function*(this: Session, token: string) {
    const data = (yield jwt.decode(token)) as LoginJWT;
    this.data = data;

    API.Instance().setJWT(token);

    localStorage.setItem(TOKEN_KEY, token);
  });

  clearToken() {
    this.data = null;
  }

  logOut() {
    this.clearToken();
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default Session;
