import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import i18n from "i18next";
import { DegreeOfAccess } from "kgt-api/dist/Login";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Session from "../../stores/Session";
import Theme from "../../utils/Theme";
import Logo from "../Logo";
import styles from "./Header.css";

interface Props extends RouteComponentProps {}
interface State {
  showDropdown: boolean;
  menuOpen: boolean;
}

interface InjectedProps extends Props {
  session: Session;
}

@inject("session")
@observer
class HeaderComponent extends React.Component<Props, State> {
  state: State = {
    showDropdown: false,
    menuOpen: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  toggleMenu = () => {
    this.setState(({ menuOpen }) => ({
      menuOpen: !menuOpen,
    }));
  };

  gotoTab = (tab: string) => {
    if (tab == "home") {
      tab = "";
    }
    this.props.history.push("/" + tab);
  };

  handleLogOut = () => {
    this.injected.session.logOut();
    this.props.history.push("/");
  };

  outsideDropdownClick = () => {
    if (this.state.showDropdown) {
      this.setState(() => {
        document.addEventListener("click", this.toggleDropDown);
      });
    } else {
      this.setState(() => {
        document.removeEventListener("click", this.toggleDropDown);
      });
    }
  };

  toggleDropDown = () => {
    this.setState(
      ({ showDropdown }) => ({
        showDropdown: !showDropdown,
      }),
      this.outsideDropdownClick
    );
  };

  render() {
    const { menuOpen } = this.state;
    const name = this.injected.session.data
      ? this.injected.session.data.accountName.toString()
      : "";

    const isLoggedIn =
      this.injected.session.data &&
      (this.injected.session.data.degreeOfAccess === DegreeOfAccess.Admin ||
        this.injected.session.data.degreeOfAccess === DegreeOfAccess.Requester);

    var dropdown = (
      <div className={styles.dropdown} onClick={this.toggleDropDown}>
        <Link
          to="/requester"
          className={styles.dropdownLink}
          style={{ color: "white" }}
          onClick={() => {
            currentTab = "requester";
          }}
        >
          {i18n.t("header_medicalCentral_dropdown1")}
        </Link>
        <br />
        <Link
          to="/login"
          className={styles.dropdownLink}
          style={{ color: "white" }}
          onClick={() => {
            currentTab = "login";
          }}
        >
          {i18n.t("header_medicalCentral_dropdown2")}
        </Link>
      </div>
    );

    let currentTab: string = "home";

    if (window.location.href.indexOf("notFound") > -1) {
      currentTab = "none";
    } else if (window.location.href.indexOf("responder") > -1) {
      currentTab = "responder";
    } else if (
      window.location.href.indexOf("requester") > -1 ||
      window.location.href.indexOf("login") > -1
    ) {
      currentTab = "requester";
    } else if (window.location.href.indexOf("about") > -1) {
      currentTab = "about";
    }

    return (
      <div className={styles.container}>
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          className={styles.appBar}
        >
          <Toolbar className={styles.headerContainer}>
            <div className={styles.logoContainer}>
              {isLoggedIn ? (
                <Logo />
              ) : (
                <Link
                  to="/"
                  onClick={() => {
                    currentTab = "home";
                  }}
                >
                  <Logo />
                </Link>
              )}
            </div>

            {/* Full screen menu */}
            <Hidden smDown>
              {isLoggedIn && (
                <div className={styles.titleContainer}>
                  <Typography variant="h6">
                    {i18n.t("header_hi") +
                      name.charAt(0).toUpperCase() +
                      name.slice(1) +
                      "!"}
                  </Typography>
                </div>
              )}
              <Typography variant="h6" className={styles.linksContainer}>
                {!isLoggedIn && (
                  <>
                    <Link
                      to="/"
                      className={
                        "home" == currentTab ? styles.linkActive : styles.link
                      }
                      onClick={() => {
                        currentTab = "home";
                      }}
                    >
                      {i18n.t("header_home")}
                    </Link>
                    <Link
                      to="/responder"
                      className={
                        "responder" == currentTab
                          ? styles.linkActive
                          : styles.link
                      }
                      onClick={() => {
                        currentTab = "responder";
                      }}
                    >
                      {i18n.t("header_medicalPersonnel")}
                    </Link>

                    <button
                      className={
                        "requester" == currentTab
                          ? styles.btnActive
                          : styles.btn
                      }
                      onClick={this.toggleDropDown}
                      style={{ color: Theme.headerTextColor }}
                    >
                      <div className={styles.imitateLink}>
                        {i18n.t("header_medicalCentral")}
                      </div>

                      {!this.state.showDropdown ? (
                        <ExpandMoreIcon
                          onMouseOver={this.toggleDropDown}
                          style={{ verticalAlign: "text-bottom" }}
                        />
                      ) : (
                        <ExpandLessIcon
                          style={{ verticalAlign: "text-bottom" }}
                        />
                      )}
                    </button>
                    {this.state.showDropdown && dropdown}

                    <Link
                      to="/about"
                      className={
                        "about" == currentTab ? styles.linkActive : styles.link
                      }
                      onClick={() => {
                        currentTab = "about";
                      }}
                    >
                      {i18n.t("header_aboutUs")}
                    </Link>
                  </>
                )}
                {isLoggedIn && (
                  <Link
                    to="/"
                    className={styles.link}
                    onClick={this.handleLogOut}
                  >
                    {i18n.t("header_signOut")}
                  </Link>
                )}
              </Typography>
            </Hidden>

            {/* Hamburger menu */}
            <Hidden mdUp>
              {!isLoggedIn && (
                <>
                  <IconButton onClick={this.toggleMenu} color="inherit">
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor="right"
                    open={menuOpen}
                    onClose={this.toggleMenu}
                  >
                    <List className={styles.list}>
                      <ListItem button onClick={() => this.gotoTab("home")}>
                        {i18n.t("header_home")}
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => this.gotoTab("responder")}
                      >
                        {i18n.t("header_medicalPersonnel")}
                      </ListItem>
                      <List>
                        <ListItem>{i18n.t("header_medicalCentral")}</ListItem>
                        <ListItem
                          button
                          className={styles.subItem}
                          onClick={() => this.gotoTab("requester")}
                        >
                          - {i18n.t("header_medicalCentral_dropdown1")}
                        </ListItem>
                        <ListItem
                          button
                          className={styles.subItem}
                          onClick={() => this.gotoTab("login")}
                        >
                          - {i18n.t("header_medicalCentral_dropdown2")}
                        </ListItem>
                      </List>
                      <ListItem button onClick={() => this.gotoTab("about")}>
                        {i18n.t("header_aboutUs")}
                      </ListItem>
                      {isLoggedIn && (
                        <ListItem button onClick={this.handleLogOut}>
                          {i18n.t("header_signOut")}
                        </ListItem>
                      )}
                    </List>
                  </Drawer>
                </>
              )}

              {isLoggedIn && (
                <Link
                  to="/"
                  className={styles.link}
                  onClick={this.handleLogOut}
                >
                  {i18n.t("header_signOut")}
                </Link>
              )}
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(HeaderComponent);
