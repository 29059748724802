import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import i18n from "i18next";
import API from "kgt-api";
import {
  PutOperation,
  PutRequestBody,
  TimeReport,
  TimeReportStatus,
} from "kgt-api/dist/TimeReport";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import Confirm from "../../components/Alert/Confirm";
import Container from "../../components/Container";
import Theme from "../../utils/Theme";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonWarning from "../Buttons/ButtonWarning";
import RequestTableRow from "../RequestList/RequestTableRow";
import SpinLoader from "../SpinLoader";
import TypographyTitle from "../Typography/TypographyTitle";
import styles from "./TimeReport.css";

interface Props extends RouteComponentProps<{ requestId: string }> {}
interface State {
  timeReport?: TimeReport;
  ready: boolean;
  errorApprove: boolean;
  confirmVisable: boolean;
  confirmMessage: string;
  inputVisable: boolean;
  inputMessage: string;
}

class InterestedResponder extends React.Component<Props, State> {
  state: State = {
    ready: false,
    errorApprove: false,
    confirmVisable: false,
    confirmMessage: "",
    inputVisable: false,
    inputMessage: "",
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const response = await API.Instance().timereport.get({
      requestId: this.props.match.params.requestId,
    });
    const timeReport = await response.json();
    this.setState({ timeReport: timeReport[0], ready: true });
  };

  handleApproveTimeReport = async () => {
    try {
      if (this.state.timeReport) {
        const body: PutRequestBody = {
          requestId: this.state.timeReport.request.id,
          responderId: this.state.timeReport.responder.id,
          operation: PutOperation.approving,
        };
        const res = await API.Instance().timereport.put(body);
        this.fetchData();
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleDeniedTimeReport = async (comment?: string | undefined) => {
    try {
      if (this.state.timeReport) {
        const body: PutRequestBody = {
          requestId: this.state.timeReport.request.id,
          responderId: this.state.timeReport.responder.id,
          requesterComment: comment ? comment : "",
          operation: PutOperation.reviewing,
        };
        const res = await API.Instance().timereport.put(body);
        this.fetchData();
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  durationVsHoursWorked = (duration: number, hoursWorked: number) => {
    let color = Theme.secondaryColour;
    if (duration < hoursWorked) {
      color = Theme.tertiaryColour;
    } else if (duration > hoursWorked) {
      color = Theme.secondaryTextColour;
    }
    return (
      <span style={{ color: color }}>
        {this.calculateHoursMinutes(hoursWorked)}
      </span>
    );
  };

  getStatus = (status: TimeReportStatus) => {
    if (status == TimeReportStatus.approved) {
      return (
        <span style={{ color: Theme.secondaryColour }}>
          {i18n.t("timeReportList_statusApproved")}
        </span>
      );
    } else if (status == TimeReportStatus.unapproved) {
      return (
        <span style={{ color: Theme.tertiaryColour }}>
          {i18n.t("timeReportList_statusUnapproved")}
        </span>
      );
    } else {
      return (
        <span style={{ color: Theme.secondaryTextColour }}>
          {i18n.t("timeReportList_statusPending")}
        </span>
      );
    }
  };

  // calculate duration of shift in h and mins
  calculateHoursMinutes = (duration: number) => {
    const hours = Math.floor(duration);
    const minutes = Math.round((duration % 1) * 59);
    return (
      hours +
      i18n.t("requestList_h") +
      " " +
      minutes +
      i18n.t("requestList_min")
    );
  };

  onDenyClick = () => {
    this.setState({
      confirmVisable: true,
      confirmMessage: i18n.t("timeReport.action.confirm"),
      inputVisable: true,
      inputMessage: i18n.t("timeReport.action.conment"),
    });
  };

  closeOverlayConfirm = (
    value: Boolean | undefined,
    input?: string | undefined
  ) => {
    this.setState({
      confirmVisable: false,
      confirmMessage: "",
    });
    if (value && this.state.inputVisable) {
      this.handleDeniedTimeReport(input);
    }
  };

  render() {
    const { ready } = this.state;
    if (ready) {
      return (
        <Container className={styles.container}>
          <Paper
            style={{ border: "1px solid #BDBDBD", padding: "1.5em" }}
            elevation={0}
            square={false}
          >
            {this.state.timeReport && (
              <React.Fragment>
                <TypographyTitle text={i18n.t("requesterTimeReport_info")} />
                <Table
                  className={styles.contentTable}
                  style={{ marginTop: Theme.mediumSpacing }}
                >
                  <colgroup>
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "70%" }} />
                  </colgroup>
                  <TableBody>
                    <RequestTableRow
                      title={i18n.t("responderList.requestInformation.title")}
                      text={this.state.timeReport.request.title}
                    />

                    <RequestTableRow
                      title={i18n.t("timeReportList_responder_name")}
                      text={this.state.timeReport.responder.name}
                    />

                    <RequestTableRow
                      title={i18n.t("timeReportList_request_date")}
                      text={new Date(
                        this.state.timeReport.request.startTime
                      ).toLocaleDateString()}
                    />

                    <RequestTableRow
                      title={i18n.t("timeReportList_request_hours")}
                      text={this.calculateHoursMinutes(
                        this.state.timeReport.request.duration
                      )}
                    />

                    <RequestTableRow
                      title={i18n.t("timeReportList_request_reported")}
                      text={this.durationVsHoursWorked(
                        this.state.timeReport.request.duration,
                        this.state.timeReport.hoursWorked
                      )}
                    />

                    <RequestTableRow
                      title={i18n.t("timeReportList_status")}
                      text={this.getStatus(this.state.timeReport.status)}
                    />
                  </TableBody>
                </Table>

                {this.state.timeReport.status ===
                  TimeReportStatus.unapproved && (
                  <div
                    style={{ display: "flex", marginTop: Theme.mediumSpacing }}
                  >
                    <ButtonWarning
                      title={i18n.t("approveTimeReport.action.deny")}
                      onClick={this.onDenyClick}
                    />
                    <ButtonPrimary
                      onClick={this.handleApproveTimeReport}
                      title={i18n.t("approveTimeReport.action.approve")}
                      marginLeft="auto"
                    />
                  </div>
                )}
                {this.state.errorApprove && (
                  <Typography component="p" color="error">
                    {i18n.t("timeReportList_error")}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Paper>
          <Confirm
            message={this.state.confirmMessage}
            visible={this.state.confirmVisable}
            closeOverlayConfirm={this.closeOverlayConfirm}
            input={this.state.inputVisable}
            inputMessage={this.state.inputMessage}
            approveButtonText={
              this.state.inputVisable ? "confirm.deny_time_report" : undefined
            }
          ></Confirm>
        </Container>
      );
    } else {
      return <SpinLoader />;
    }
  }
}

export default withRouter(InterestedResponder);
