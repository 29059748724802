import React from "react";
import Typography from "@material-ui/core/Typography";
import Theme from "../../utils/Theme"

interface TypographyRegularProps {
    text: string;
    className?: string;
    marginLeft?: any;
}

export default class TypographyRegular extends React.Component<TypographyRegularProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                style={{
                    marginLeft: this.props.marginLeft,
                    color: Theme.primaryTextColour
                }}>
                {this.props.text}
            </Typography>
        );
    }
}