import * as React from "react";
import ScreenContent from "../../components/ScreenContent";
import PrivacyContent from "../../components/PrivacyContent";

function Privacy() {
  return (
    <ScreenContent>
      <PrivacyContent />
    </ScreenContent>
  );
}

export default Privacy;
