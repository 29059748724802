import { Endpoint } from "../../../Endpoint";
import API from "../../../API";
import { RequestStatus } from "../../../Request";

export const name = "/interest";

export interface Request {
  id: string;
  startTime: string;
  title: string;
  duration: number;
  requester: { name: string; id: string };
  skills: Array<{ tag: string; id: string }>;
}

export interface Interest {
  status: RequestStatus;
  request: Request;
}
export type GetResponseBody = Array<Interest>;

export interface GetParams {
  id: string;
}
export enum Direction {
  none = 0,
  future = 1,
  past = -1
}
export interface GetQueryParams {
  direction?: Direction;
  limit?: number;
  skip?: number;
}

export default class ResponderInterestEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get(params: GetParams, queryParams: GetQueryParams = {}) {
    return this.sendGet<GetResponseBody>(params, queryParams);
  }
}
