import { addHours, differenceInMinutes, subHours } from "date-fns";
import i18n from "i18next";
import API from "kgt-api";
import { GetResponseBody as Skills } from "kgt-api/dist/Skill";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import Alert from "../../components/Alert/Alert";
import ButtonPrimary from "../../components/Buttons/ButtonPrimary";
import RequestForm, { Request } from "../../components/RequestForm";
import TypographyRegular from "../../components/Typography/TypographyRegular";
import TypographyTitle from "../../components/Typography/TypographyTitle";
import Theme from "../../utils/Theme";

// Buffer time (in whole hours); a request cannot be modified for this many hours before its start time
// A value of 0.5 corresponds to locking out the request 30min before its start time
const BUFFER_TIME: number = 0.5;

let MAX_TITLE_LENGTH = 60;
let MAX_DESC_LENGTH = 400;

type CreateRequestProps = RouteComponentProps;

const CreateRequest: React.FC<CreateRequestProps> = ({ history }) => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([] as Request[]);
  const [skills, setSkills] = useState({
    tools: [] as Skills,
    competences: [] as Skills,
  });

  const goBack = () => history.push("/requester/request/list");
  const [isVisable, setVisableAlert] = useState(false);
  const [alertMessage, setMessage] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    addRequest();
    async function fetchSkills() {
      const res = await API.Instance().skill.get();

      if (res.ok) {
        const skills = await res.json();
        const tools = skills.filter((skill) => skill.type === "tool");
        const competences = skills.filter(
          (skill) => skill.type === "competence"
        );
        setSkills({ tools, competences });
      }
    }
    fetchSkills();
  }, []);

  function handleOnRemove(localId: number) {
    const newRequests = requests.filter((request) => {
      return request.localId !== localId;
    });
    setRequests(newRequests);
  }

  function handleOnChange(localId: number, newRequest: Request) {
    const newRequests = requests.map((request) => {
      if (request.localId === localId) {
        return newRequest;
      } else {
        return request;
      }
    });
    setRequests(newRequests);
  }

  function addRequest() {
    const newRequests = [
      ...requests,
      {
        localId: Math.random(), // This will probably™ never collide
        startTime: addHours(new Date(), 1),
        duration: 8,
        yearExperience: 0,
        title: "",
        description: "",
        compensation: NaN,
        competences: [],
        tools: [],
      },
    ];
    setRequests(newRequests);
  }

  async function sendRequests() {
    let isPublishable: boolean = true;

    if (requests.length < 1) {
      setVisableAlert(true);
      setMessage(i18n.t("createRequest.alert.noRequests"));
      isPublishable = false;
      return;
    }

    // Check if the request is within BUFFER_TIME of start
    // If so, trigger an Alert and stop the publication process
    requests.forEach((request) => {
      var expired = handleTimeCheck(request, BUFFER_TIME);
      if (expired) {
        isPublishable = false;
        setVisableAlert(true);
        setMessage(i18n.t("createRequest.alert.expired"));
        return;
      }
    });

    if (isPublishable) {
      const res = await API.Instance().request.post(
        requests.map((r) => ({
          startTime: r.startTime.toISOString(),
          duration: r.duration,
          yearExperience: r.yearExperience,
          title: r.title,
          description: r.description,
          compensation: r.compensation,
          skills: r.tools.concat(r.competences),
        }))
      );

      if (res.ok) {
        setRequests([]);
        if (requests.length > 1) {
          setVisableAlert(true);
          setMessage(i18n.t("createRequest.alert.submit.many"));
          setSubmitted(true);
        } else {
          setVisableAlert(true);
          setMessage(i18n.t("createRequest.alert.submit.one"));
          setSubmitted(true);
        }
      } else if (res.text) {
        setVisableAlert(true);
        setMessage(i18n.t("createRequest.alert.error") + (await res.text()));
      } else {
        setVisableAlert(true);
        setMessage(i18n.t("error_noNetwork"));
      }
    }
  }

  function handleTimeCheck(request: Request, buffer: number) {
    // In order to compare times, create both current time and start of request (incl. buffer) as Dates
    // Remove amount of hours (buffer) from the start time
    // If too close to start time, return TRUE, else return FALSE
    var now: Date = new Date();
    var startOfRequest: Date = request.startTime;
    var startOfRequestInclBuffer: Date = subHours(startOfRequest, buffer);

    return differenceInMinutes(startOfRequestInclBuffer, now) <= 0
      ? true
      : false;
  }

  function checkTextAndSend() {
    let textLengths = requests.map((r) => ({
      title: r.title.length,
      description: r.description.length,
      compensation: r.compensation,
    }));
    let validInput = true;
    textLengths.forEach((element) => {
      if (
        element.title > MAX_TITLE_LENGTH ||
        element.description > MAX_DESC_LENGTH
      ) {
        setVisableAlert(true);
        setMessage(i18n.t("createRequest.alert.input"));
        validInput = false;
        return;
      } else if (
        element.title == 0 &&
        element.description == 0 &&
        Number.isNaN(element.compensation)
      ) {
        setVisableAlert(true);
        setMessage(i18n.t("createRequest.alert.emptyForm"));
        validInput = false;
        return;
      } else if (element.title == 0 || Number.isNaN(element.compensation)) {
        setVisableAlert(true);
        setMessage(i18n.t("createRequest.alert.inputNotEnough"));
        validInput = false;
        return;
      }
    });
    if (validInput) {
      sendRequests();
    }
  }

  function closeOverlay() {
    setVisableAlert(false);
    setMessage("");
    if (isSubmitted) {
      goBack();
    }
  }

  return (
    <React.Fragment>
      <TypographyTitle text={t("createRequest.title")} />

      {requests.map((request) => (
        <RequestForm
          key={request.localId}
          tools={skills.tools}
          competences={skills.competences}
          request={request}
          onRemove={handleOnRemove}
          onChange={handleOnChange}
        />
      ))}
      {requests.length < 1 && (
        <div style={{ paddingTop: 8, paddingRight: "10em", paddingBottom: 16 }}>
          <TypographyRegular text={t("requestForm.noRequests")} />
        </div>
      )}

      <div style={{ display: "flex" }}>
        <ButtonPrimary
          onClick={addRequest}
          title={t("createRequest.action.new")}
          marginLeft="auto"
        />
        <ButtonPrimary
          onClick={checkTextAndSend}
          title={t("createRequest.action.submit")}
          marginLeft={Theme.smallSpacing}
        />
      </div>
      <Alert
        message={alertMessage}
        visible={isVisable}
        closeOverlay={closeOverlay}
      ></Alert>
    </React.Fragment>
  );
};

export default CreateRequest;
