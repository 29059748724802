import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/login";

//Enumerator for degrees of access on a login JWT
enum DegreeOfAccess {
  Developer = "7291ae1d-867d-4524-9b2e-7ccfa2812cbd",
  Admin = "a7529fab-932b-48fe-87f4-1783faf81913",
  Requester = "20fa6f4c-83d5-4dc6-ac5d-5956d3f24f9c",
  Responder = "d5bdb7d6-a37a-4adb-9fda-bdcaa782d4bb",
  Unauthorized = "1fa5e13c-3189-48b9-a2ac-5d8f923552c0"
}

//Class for our LoginJWT, used in various places
interface LoginJWT {
  id: string;
  profileId: string;
  accountName: string;
  degreeOfAccess: DegreeOfAccess;
}

interface PostRequestBody {
  accountName: string;
  password: string;
}
type PostResponseBody = string;

export { name, PostRequestBody, PostResponseBody, LoginJWT, DegreeOfAccess };

export default class Login extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
}

