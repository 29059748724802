import * as React from "react";
import styles from "./Logo.css";
import logo from "../../images/logo.png";

class Logo extends React.Component {
  render() {
    return (
      <img
        src={logo}
        alt="Vårdkallan"
        className={styles.logoContainer}
      />
    );
  }
}

export default Logo;
