import { Endpoint } from "../Endpoint";
import API from "../API";
import { RequestStatus } from "../Request";

const name = "/responderRequest";

interface GetQueryParams {
  id: string;
}

type GetResponseBody = Array<{
  responder: Responder;
  status: RequestStatus;
}>;

interface PostRequestBody {
  requestId: string;
  responderId: string;
  requesterComment?: string;
}

interface PutRequestBody {
  requestId: string;
  responderId: string;
  status: RequestStatus;
}

type PostResponseBody = "OK";
type PutResponseBody = "OK";

interface Skill {
  id: string;
}

interface Responder {
  name: string;
  experience: number;
  id: string;
  skills: Array<Skill>;
}

export {
  name,
  GetResponseBody,
  GetQueryParams,
  PostRequestBody,
  PostResponseBody,
  Responder,
  PutRequestBody,
  PutResponseBody,
};

export default class ResponderRequestEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }
  get(queryParams: GetQueryParams) {
    return this.sendGet<GetResponseBody>({}, queryParams);
  }
  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
  put(body: PutRequestBody) {
    return this.sendPut<PutResponseBody>(body);
  }
}
