import { Endpoint } from "../Endpoint";
import API from "../API";

export const name = "/ping";

export type GetResponseBody = "OK";

export default class PingEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }
}
