import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/admin";

interface PostRequestBody {
  name: string;
  password: string;
}
interface PostResponseBody {
  id: string;
  name: string;
}

export { name, PostRequestBody, PostResponseBody };

export default class Admin extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
}
