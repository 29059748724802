import { AppBar, Link, List, ListItem, Toolbar } from "@material-ui/core";
import { default as i18n, default as i18next } from "i18next";
import * as React from "react";
import appstore from "../../images/appstore.svg";
import playstore from "../../images/playstore.svg";
import styles from "./Footer.css";

interface Props {
  location?: string;
}
class Footer extends React.Component<Props> {
  render() {
    const isHome: boolean = this.props.location == "/";
    return (
      <React.Fragment>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar
            className={
              isHome ? styles.mediaContainerHome : styles.mediaContainer
            }
          >
            <List className={styles.mediaList}>
              <ListItem>
                <Link
                  target="_blank"
                  href="https://apps.apple.com/us/app/v%C3%A5rdk%C3%A4llan/id1470370822?ls=1"
                >
                  <img
                    src={appstore}
                    className={styles.image}
                    alt={i18next.t("appStoreInfo.appStore.download")}
                  />
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  target="_blank"
                  href="http://play.google.com/store/apps/details?id=com.precisit.vardkallan"
                >
                  <img
                    src={playstore}
                    className={styles.image}
                    alt={i18next.t("appStoreInfo.playStore.download")}
                  />
                </Link>
              </ListItem>
              <ListItem
                className={
                  isHome ? styles.mediaListItemHome : styles.mediaListItem
                }
              >
                {i18n.t("footer_contactUs")}
              </ListItem>
            </List>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default Footer;
