import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Cell from "./Cell";

interface RequestRowProps {
    title: string;
    startTime: Date;
    duration: number;
    requestId: string;
    onClick: (id: string) => void;
}

export default class RequestRow extends React.Component<RequestRowProps> {
    handleOnClick = () => {
        this.props.onClick(this.props.requestId);
    };

    render() {
        return (
            <TableRow onClick={this.handleOnClick} hover style={{ cursor: "pointer" }}>
                <Cell
                    text={new Date(this.props.startTime).toLocaleDateString()} />
                <Cell
                    text={this.props.title} />
                <Cell
                    text={new Date(this.props.startTime).toLocaleTimeString(navigator.language,
                        { hour: '2-digit', minute: '2-digit' })} />
                <Cell
                    text={this.props.duration.toFixed(2).toString() + "h"} />
                <TableCell>
                    <ArrowRight />
                </TableCell>
            </TableRow>
        );
    }
}