import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/translation";

type PostRequestBody = {
  tag: string;
  language: string;
  text: string;
}

type PostResponseBody = "OK";

type GetResponseBody = {
  translations: Array<{
    tag: string;
    language: string;
    text: string;
  }>
}

export { name, PostRequestBody, PostResponseBody, GetResponseBody };

export default class Translation extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }
}
