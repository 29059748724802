import { InputAdornment } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import API from "kgt-api";
import { GetResponseBody as RegionData } from "kgt-api/dist/Region";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Theme from "../../utils/Theme";
import Alert from "../Alert/Alert";
import Confirm from "../Alert/Confirm";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonWarning from "../Buttons/ButtonWarning";
import ButtonWhite from "../Buttons/ButtonWhite";
import TextFieldBarlow from "../TextField/TextFieldBarlow";
import TypographyBold from "../Typography/TypographyBold";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitle from "../Typography/TypographyTitle";
import styles from "./RequesterTable.css";
import RequesterTableRow from "./RequesterTableRow";

export interface Requester {
  id: string;
  name: string;
  description: string;
  requesterIdentifier: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  contactPersonName: string;
  accountName: string;
  phoneNumber: string;
  region: { name: string; id: string } | null;
  standardPayMargin?: number;
}

interface Props {
  requester: Requester;
  editable?: boolean;
  onEdited?: (requester: Requester, password: string) => void;
}

const RequesterTable: React.SFC<Props> = ({
  requester,
  editable,
  onEdited,
}) => {
  const { t } = useTranslation();
  const [regions, setRegions] = React.useState<RegionData>({ regions: [] });
  const [editing, setEditing] = React.useState(false);
  const [editedRequester, setEditedRequester] = React.useState(requester);
  const [alertVisible, setAlertVisible] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<string>("");
  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);
  const [isEnabled, setEnabled] = React.useState<boolean>(true);
  const [regionsLoaded, setRegionsLoaded] = React.useState<boolean>(false);

  const MIN_PASSWORD_LENGTH: number = 8;

  React.useEffect(() => {
    async function fetchRegions() {
      try {
        const res = await API.Instance().region.get();
        if (res.ok) {
          setRegions(await res.json());
          setRegionsLoaded(true);
        }
      } catch {
        setAlertVisible(true);
        setAlertMessage(t("error_noNetwork"));
        setEnabled(false);
        setRegionsLoaded(false);
      }
    }
    fetchRegions();
  }, []);

  React.useEffect(() => {
    canBeSubmitted();
  }, [password, editedRequester]);

  const updateEdited = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;

      setEditedRequester({
        ...editedRequester,
        [name]: value,
      });
    },
    [editedRequester]
  );

  const updateRegion = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value;

      setEditedRequester({
        ...editedRequester,
        region: regions.regions.find((r) => r.id === id) || null,
      });
    },
    [editedRequester, regions]
  );

  const updatePayMargin = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.valueAsNumber;

      setEditedRequester({
        ...editedRequester,
        [name]: value / 100,
      });
    },
    [editedRequester]
  );

  const saveEdited = React.useCallback(() => {
    if (onEdited) {
      onEdited(editedRequester, password);
    }
    setEditing(false);
  }, [editedRequester, password]);

  const checkPasswordCompliance = (e: React.ChangeEvent<any>) => {
    setPassword(e.currentTarget.value);
    if (
      e.currentTarget.value.length < MIN_PASSWORD_LENGTH &&
      e.currentTarget.value.length > 0
    ) {
      setPasswordError("reset.password.helper");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordReset = async (requester: Requester) => {
    if (validateEmail(requester.email)) {
      const res = await API.Instance().resettoken.post({
        accountName: requester.accountName,
      });

      if (res.ok) {
        // If the user is an Admin, show a different instruction than a requester
        if (
          requester.standardPayMargin !== undefined &&
          editedRequester.standardPayMargin !== undefined
        ) {
          setAlertMessage(t("forgot.success_admin"));
          setAlertVisible(true);
        }
        // It is a requester, show the standard dialogue
        else {
          setAlertMessage(t("forgot.success"));
          setAlertVisible(true);
        }
      } else if (res.status === 404 || res.status === 500) {
        setAlertMessage(t("forgot.error.internal"));
        setAlertVisible(true);
      }
    } else {
      setAlertMessage(t("forgot.error.noEmail"));
      setAlertVisible(true);
    }
  };

  const validateEmail = (mail: string) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = regex.test(mail);
    return isEmail;
  };

  const onDeleteConfirm = async () => {
    try {
      const res = await API.Instance().deleterequester.put({
        id: requester.id,
        accountName: requester.accountName,
      });
      if (res.ok) {
        window.history.back();
      }
    } catch (err) {
      setAlertMessage(t("adminOneRequester.delete_error"));
      setAlertVisible(true);
    }
  };

  const closeAlert = () => {
    setAlertMessage("");
    setAlertVisible(false);
  };

  const closeOverlayConfirm = (value: Boolean | undefined) => {
    setAlertMessage("");
    setConfirmVisible(false);
    if (value) {
      onDeleteConfirm();
    }
  };

  const onDeleteClick = () => {
    setAlertMessage(t("adminOneRequester.delete_areYouSure"));
    setConfirmVisible(true);
  };

  const canBeSubmitted = () => {
    if (
      editedRequester.name.length > 0 &&
      editedRequester.requesterIdentifier.length > 0 &&
      editedRequester.address.length > 0 &&
      editedRequester.city.length > 0 &&
      editedRequester.zip.length > 0 &&
      editedRequester.region &&
      validateEmail(editedRequester.email) &&
      !Number.isNaN(editedRequester.standardPayMargin)
    ) {
      if (
        requester.standardPayMargin === undefined &&
        editedRequester.standardPayMargin === undefined
      ) {
        if (
          password.length < MIN_PASSWORD_LENGTH &&
          !(password.length == 0) &&
          password
        ) {
          setEnabled(false);
        } else {
          setEnabled(true);
        }
      } else {
        setEnabled(true);
      }
    } else {
      setEnabled(false);
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <TypographyTitle text={t("requesterTable.heading.title")} />
        {editable && !editing && (
          <ButtonPrimary
            title={t("requesterTable.button.edit")}
            onClick={() => setEditing(true)}
            marginLeft="auto"
          />
        )}
      </div>
      <Table className={styles.contentTable}>
        <TableBody>
          {!editing && (
            <React.Fragment>
              <RequesterTableRow
                title={t("requesterTable.heading.name")}
                text={requester.name}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.identifier")}
                text={requester.requesterIdentifier}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.description")}
                text={requester.description}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.phoneNumber")}
                text={requester.phoneNumber}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.email")}
                text={requester.email}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.contactPersonName")}
                text={requester.contactPersonName}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.address")}
                text={requester.address}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.city")}
                text={requester.city}
              />

              <RequesterTableRow
                title={t("requesterTable.heading.zip")}
                text={requester.zip}
              />

              <TableRow>
                <TableCell component="th" style={{ padding: "1em" }}>
                  <TypographyBold text={t("requesterTable.heading.region")} />
                </TableCell>
                <TableCell>
                  {requester.region !== null ? (
                    <TypographyRegular text={t(requester.region.name)} />
                  ) : (
                    <TypographyRegular
                      text={t("requesterTable.missingRegion")}
                    />
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          )}

          {editing && (
            <div className={styles.edit}>
              <TextFieldBarlow
                name="name"
                label={t("requesterTable.heading.name")}
                value={editedRequester.name}
                onChange={updateEdited}
                error={editedRequester.name.length == 0}
                helperText={
                  editedRequester.name.length == 0
                    ? t("requesterTable.requiredRemoved")
                    : t("")
                }
              />

              <TextFieldBarlow
                name="requesterIdentifier"
                label={t("requesterTable.heading.identifier")}
                value={editedRequester.requesterIdentifier}
                onChange={updateEdited}
                error={editedRequester.requesterIdentifier.length == 0}
                helperText={
                  editedRequester.requesterIdentifier.length == 0
                    ? t("requesterTable.requiredRemoved")
                    : t("")
                }
              />

              <TextFieldBarlow
                name="description"
                label={t("requesterTable.heading.description")}
                value={editedRequester.description}
                onChange={updateEdited}
              />

              <TextFieldBarlow
                type="number"
                name="phoneNumber"
                label={t("requesterTable.heading.phoneNumber")}
                value={editedRequester.phoneNumber || ""}
                onChange={updateEdited}
              />

              {requester.standardPayMargin !== undefined &&
              editedRequester.standardPayMargin !== undefined && (
                // Only allowed to change email if admin, since requesters do not have the privilege to check if the e-mail is already in use or not
                <TextFieldBarlow
                  name="email"
                  label={t("requesterTable.heading.email")}
                  value={editedRequester.email}
                  onChange={updateEdited}
                  error={!validateEmail(editedRequester.email)}
                  helperText={
                    validateEmail(editedRequester.email)
                      ? t("")
                      : t("requesterTable.noEmail")
                  }
                  // required can be used to identify mandatory fields, but then you need to implement
                  // mandatorium checks on the Save button action (not currecntly implemented)
                />
              )}
              
              {requester.standardPayMargin === undefined &&
              editedRequester.standardPayMargin === undefined && (
                // Only allowed to change email if admin, since requesters do not have the privilege to check if the e-mail is already in use or not
                <TextFieldBarlow
                  name="email"
                  label={t("requesterTable.heading.email")}
                  value={editedRequester.email}
                  onChange={() => {}}
                  disabled
                  helperText={t("requester_editInfo_attempted_email_change")}
                />
              )}

              <TextFieldBarlow
                name="contactPersonName"
                label={t("requesterTable.heading.contactPersonName")}
                value={editedRequester.contactPersonName}
                onChange={updateEdited}
              />

              <TextFieldBarlow
                name="address"
                label={t("requesterTable.heading.address")}
                value={editedRequester.address}
                onChange={updateEdited}
                error={editedRequester.address.length == 0}
                helperText={
                  editedRequester.address.length == 0
                    ? t("requesterTable.requiredRemoved")
                    : t("")
                }
              />

              <TextFieldBarlow
                name="city"
                label={t("requesterTable.heading.city")}
                value={editedRequester.city}
                onChange={updateEdited}
                error={editedRequester.city.length == 0}
                helperText={
                  editedRequester.city.length == 0
                    ? t("requesterTable.requiredRemoved")
                    : t("")
                }
              />

              <TextFieldBarlow
                name="zip"
                label={t("requesterTable.heading.zip")}
                value={editedRequester.zip}
                onChange={updateEdited}
                error={editedRequester.zip.length == 0}
                helperText={
                  editedRequester.zip.length == 0
                    ? t("requesterTable.requiredRemoved")
                    : t("")
                }
              />

              <FormControl
                fullWidth
                style={{ marginTop: "1em" }}
                error={!editedRequester.region}
              >
                <InputLabel htmlFor="region">
                  {t("requesterTable.heading.region")}
                </InputLabel>
                <Select
                  value={
                    editedRequester.region ? editedRequester.region.id : ""
                  }
                  onChange={updateRegion}
                  inputProps={{ name: "region", id: "region" }}
                >
                  <MenuItem value="">
                    <em>{t("requesterTable.missingRegion")}</em>
                  </MenuItem>
                  {!regionsLoaded && (
                    <option value="" disabled>
                      {t("requesterTable.noLoadedRegions")}
                    </option>
                  )}
                  {regionsLoaded &&
                    regions.regions.map((r) => (
                      <option key={r.id} value={r.id}>
                        {t(r.name)}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {
                // Only requester themselves can edit the password directly
              }
              {requester.standardPayMargin === undefined &&
                editedRequester.standardPayMargin === undefined && (
                  <>
                    <InputLabel className={`${styles.inputLabel}`}>
                      {t("requesterTable.changePassword")}
                    </InputLabel>
                    <TextFieldBarlow
                      name="password"
                      label={t("register.form.textfield.password")}
                      value={password}
                      type={passwordVisible ? "text" : "password"}
                      onChange={(e) => checkPasswordCompliance(e)}
                      error={passwordError === "" ? false : true}
                      helperText={t(passwordError)}
                      placeholder="********"
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {passwordVisible ? (
                              <VisibilityOff
                                color="primary"
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              />
                            ) : (
                              <Visibility
                                color="primary"
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
            </div>
          )}

          {requester.standardPayMargin !== undefined &&
            editedRequester.standardPayMargin !== undefined && (
              <React.Fragment>
                {!editing ? (
                  <>
                    <TableRow>
                      <TableCell component="th" style={{ padding: "1em" }}>
                        <TypographyBold
                          text={t("requesterTable.heading.standardPayMargin")}
                        />
                      </TableCell>
                      <TableCell>
                        <TypographyRegular
                          text={requester.standardPayMargin * 100 + "%"}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TextField
                    style={{ marginTop: "2em" }}
                    variant="outlined"
                    name="standardPayMargin"
                    fullWidth
                    type="number"
                    label={t("requesterTable.heading.standardPayMargin")}
                    value={
                      Number.isNaN(editedRequester.standardPayMargin)
                        ? ""
                        : editedRequester.standardPayMargin * 100
                    }
                    onChange={updatePayMargin}
                    error={Number.isNaN(editedRequester.standardPayMargin)}
                    helperText={
                      Number.isNaN(editedRequester.standardPayMargin)
                        ? t("requesterTable.requiredRemoved")
                        : t("")
                    }
                  />
                )}
              </React.Fragment>
            )}
        </TableBody>
      </Table>

      {editing &&
        requester.standardPayMargin === undefined &&
        editedRequester.standardPayMargin === undefined && (
          <Alert
            message={alertMessage}
            title={t("forgot.heading")}
            closeOverlay={() => closeAlert()}
            visible={alertVisible}
          />
        )}

      {!editing &&
        requester.standardPayMargin !== undefined &&
        editedRequester.standardPayMargin !== undefined && (
          <React.Fragment>
            <td className={styles.resetButton}>
              <ButtonWarning
                title={t("adminOneRequester.delete")}
                onClick={() => onDeleteClick()}
                marginRight="auto"
              />
              <ButtonWarning
                title={t("forgot.heading")}
                onClick={() => handlePasswordReset(requester)}
                marginLeft="auto"
              />
            </td>
            <Alert
              message={alertMessage}
              title={t("forgot.heading")}
              closeOverlay={() => closeAlert()}
              visible={alertVisible}
            />
            <Confirm
              message={alertMessage}
              visible={confirmVisible}
              closeOverlayConfirm={closeOverlayConfirm}
            ></Confirm>
          </React.Fragment>
        )}

      {editable && editing && (
        <td style={{ display: "flex", marginBottom: Theme.mediumSpacing }}>
          <ButtonWhite
            title={t("requesterTable.button.cancel")}
            onClick={() => setEditing(false)}
          />
          <ButtonPrimary
            disabled={!isEnabled}
            title={t("requesterTable.button.save")}
            onClick={
              passwordError === ""
                ? saveEdited
                : () => (
                    setAlertVisible(true),
                    setAlertMessage("reset.password.helper")
                  )
            }
            marginLeft="auto"
          />
        </td>
      )}
    </React.Fragment>
  );
};

export default RequesterTable;
