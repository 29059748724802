import * as React from "react";
import ScreenContent from "../../components/ScreenContent";
import ResponderContent from "../../components/ResponderContent";

import styles from "./Responder.css";

function Responder() {
  return (
    <ScreenContent>
      <ResponderContent />
    </ScreenContent>
  );
}

export default Responder;
