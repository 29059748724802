import * as React from "react";
import ResponderTable from "../../../components/AdminContent/ResponderTable";
import Paper from "@material-ui/core/Paper";

function AdminResponder() {
  return (
    <Paper
      style={{ border: "1px solid #BDBDBD", padding: "1.5em"}}
      elevation={0}
      square={false}>
      <ResponderTable />
    </Paper>
  );
}

export default AdminResponder;
