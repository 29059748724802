//Gets all the swedish translation data
const getSwedishTranslateData = () => {
  return {
    //Common phrases
    commonContent_step: "Steg",

    //MenuBar.tsx
    menubar_Home: "Hem",
    menubar_About: "Om oss",
    menubar_Responder: "Responder",
    menubar_Requester: "Requester",

    //404 NotFound.tsx
    notFound_title: "Sidan kunde inte hittas",
    notFound_text:
      "Felet kan bero på att du har skrivit in fel adress i webbläsaren eller att sidan har tagits bort.",

    //Network connection error
    error_noNetwork:
      "Något gick fel. Vänligen undersök er internetuppkoppling och försök igen.",

    //HomeTopContent.tsx
    homeTopContent_learn_more: "Läs mer",
    "homePageContent.heading": "En räddare i vården",
    "homePageContent.subheading": "Så fungerar det",
    "homePageContent.step1.paragraph":
      "Ladda hem appen och registrera dig, till exempel via länken längst ner på sidan",
    "homePageContent.step2.paragraph":
      "Se tillgängliga uppdrag och lön, sök lediga pass, eller önska att jobba på din favoritarbetsplats just de datum som passar dig",
    "homePageContent.step3.paragraph":
      "Tidrapportera direkt i appen och se avtal och löneutbetalningar direkt  i mobilen",
    "homePageContent.subheading_second":
      "Hur fungerar Vårdkällans önskeschema?",
    "homePageContent.paragraph_first":
      "Du hittar en arbetsplats du tycker om, och vill eller behöver planera långt fram i tiden. Dock har din nuvarande arbetsplats inga vakanser, och när de väl kommer ut försvinner de fort. Vi på Vårdkällan kan hjälpa dig att hitta arbetspass som passar just dig!",
    "homePageContent.paragraph_second":
      "Här på Vårdkällan tycker vi att det skall vara enkelt, smidigt och flexibelt att bidra till sjukvården. Hos oss hittar du enkelt intressanta pass via vår app, där du även hanterar tidrapportering och bygger upp din profil. Genom att hålla ett öga på appen håller du dig också uppdaterad på alla arbetspass som våra partners erbjuder, och kan direkt ansöka om passets kontrakt.",
    "homePageContent.paragraph_third":
      "Vi på Vårdkällan bidrar till ett smidigt och flexibelt schema, med högre lön och arbete på dina villkor. Vi hoppas att du vill hänga med!",

    //Confirm.tsx
    "confirm.approved": "Ta Bort",
    "confirm.decline": "Avbryt",
    "confirm.deny_time_report": "Neka tidsrapport",

    //ResponderContent.tsx
    "responderPageContent.heading": "För sjukvårdspersonal",
    "responderPageContent.paragraph":
      "Genom Vårdkällans app kan sjuksöterskor och läkare enkelt söka och önska tillgängliga arbetspass och boka in precis det som passar. Det ger ökad kontroll och flexibilitet. Inga mer telefonsamtal eller mailande fram och tillbaka med vårdenheter eller konsultchefer.",
    "responderPageContent.paragraph_second":
      "Unikt hos Vårdkällan är möjligheten att önska pass hos din favoritarbetsplats. Genom att önska och planera för när Du vill jobba istället för att täcka luckor bidrar vi till att stärka svensk sjukvård.",
    "responderPageContent.paragraph_third":
      "Ladda hem appen nu och följ med på resan då vi revolutionerar bemanningsbranschen för sjukvården!",
    "responderPageContent.paragraph_fourth":
      "Är du sjukvårdspersonal och vill veta mer om hur du kan lösa era akuta resursbehov, klicka på Vårdenheter i menyraden!",
    "responderPageContent.step1_paragraph":
      "Ladda hem appen och registrera dig, till exempel via länken längst ner på sidan",
    "responderPageContent.step2_paragraph":
      "Se tillgängliga uppdrag och lön, sök lediga pass, eller önska att jobba på din favoritarbetsplats just de datum som passar dig",
    "responderPageContent.step3_paragraph":
      "Tidrapportera direkt i appen och se avtal och löneutbetalningar direkt  i mobilen",
    "responderPageContent.subheading": "Så fungerar det",
    "responderPageContent.step1": "1. Hämta appen",
    "responderPageContent.step2": "2. Planera och önska",
    "responderPageContent.step3": "3. Tidrapportera",
    responderContent_registerInterest: "Registrera Konto",

    //RequesterContent.tsx
    "requesterPageContent.heading": "För vårdenheter",
    "requesterPageContent.paragraph":
      "Vårdkällan är en digital bemanningstjänst som tillhandahåller sjuksköterskor och läkare för att lösa era personalbehov. Tillgängligheten gäller både kortare och längre uppdrag. Genom att digitalisera processen kan schemaläggningen lösas utan att behöva gå genom traditionella bemanningsföretag. Det ger en enklare och snabbare hantering till lägre kostnader!",
    "requesterPageContent.paragraph_second":
      "Genom Vårdkällan har du tillgång till legitimerad personal dygnet runt och är inte begränsad till kontorstider för att lösa schemaläggningen. I vår webbtjänst kan du enkelt och snabbt annonsera bemanningsluckor. Du ställer in arbetspassets längd och kompetenskrav. Inom några minuter når din annons Vårdkällans alla appanvändare med notiser direkt i mobilen. Du bokar själv den personal som du tycker matchar din verksamhet bäst och all bokning sker direkt i webbtjänsten. Inga telefonsamtal, inga mailkonversationer och inget krångel!",
    "requesterPageContent.paragraph_third":
      "Är du sjukvårdspersonal och vill börja använda Vårdkällan, kontakta oss på kontakt@vårdkällan.se.",
    "requesterPageContent.paragraph_fourth":
      "Och självklart är tjänsten gratis att använda!",
    "requesterPageContent.subheading": "Så fungerar det",
    "requesterPageContent.step1": "1. Registrera er",
    "requesterPageContent.step2": "2. Skapa pass",
    "requesterPageContent.step3": "3. Boka personal",
    "requesterPageContent.step4": "4. Godkänn tidrapporter",
    "requesterPageContent.step1_paragraph":
      "Registrera dig som vårdenhet genom att kontakta oss på kontakt@vårdkällan.se",
    "requesterPageContent.step2_paragraph":
      "Lägg upp tillgängliga pass med information om plats, tid, kort beskrivning och ersättning",
    "requesterPageContent.step3_paragraph":
      "Se ansökningar för annonserade pass och välj att kontakta och boka personal",
    "requesterPageContent.step4_paragraph":
      "Godkänn tidrapporter för avslutade pass",

    //AboutContent.tsx
    "aboutPageContent.heading": "Om oss",
    "aboutPageContent.paragraph":
      "Vårdkällan startades av personer med lång bakgrund inom svensk sjukvård. Ett av de mest brännande problemen inom vården är svårigheten att erbjuda personalen konkurrenskraftiga arbetsvillkor och möjlighet att flexibelt planera sin tid. Samtidigt som det saknas personal behöver vårdenheter hålla budgetar och säkra kvalitet och resultat. Vi på Vårdkällan är övertygade om det här problemet är lösbart!",
    "aboutPageContent.paragraph_second":
      "Vi tror att en nyckel till framgång är att på ett enkelt och effektivt sätt matcha personals och vårdenheters behov. Genom att utnyttja digitala plattformar, som effektivt löst liknande problem i den privata sektorn och inom andra samhällsområden, finns mycket stora möjlighterer till att möta vårdbehoven, förbättra resulten och minska kostnaderna.",
    "aboutPageContent.paragraph_third":
      "Vårdkällans svar på bemanningsfrågan är en digitaliserad tjänst som riktar sig mot sjuksköterskor, läkare och vårdenheter. Genom en enkel och effektiv matchning kan personal och vårdenheter mötas för att hitta lösningar vid såväl akut personalbrist som för längre planering. Allt utan att ta in kostsamma, tradionella bemanningsbolag som mellanhänder.",
    "aboutPageContent.paragraph_fourth":
      "Vårdkällan vill vara den osynliga hand som hjälper alla parter att lösa vårdens bemanningsproblem.",

    //RegisterInterest/RegisterInterest.tsx
    registerInterest_thanksForSubmit: "Tack för din registrering!",
    registerInterest_readyForAppLogin:
      "Du kan nu logga in via appen, som du hittar på AppStore och Google Play Store",
    "register.button.register": "Skapa Konto",
    "register.form.submit_error": "Någonting gick fel. Prova igen senare.",
    "register.form.textfield.email": "E-post",
    "register.form.textfield.personalnumber": "Personnummer",
    "register.form.textfield.name": "Namn",
    "register.form.textfield.password": "Lösenord",
    "register.form.checkbox.termsOfService": "Jag godkänner Vårdkällans ",
    "register.form.termsOfService": "Användarvillkor",
    "register.form.checkbox.privacyPolicy": "Jag godkänner Vårdkällans ",
    "register.form.privacyPolicy": "Lagring av persondata",
    "register.form.error.personalnumber_invalid":
      "Du måste ange ett giltigt personnummer",
    "register.form.error.personalnumber_exists":
      "Personnumret är redan registrerat",
    "register.form.error.email_exists": "E-postadressen är redan registrerad",

    //RegisterInterest/TextContent.tsx
    "registerInterest.textContent.heading": "Skapa ett konto nedan",
    "registerInterest.textContent.paragraph":
      "Skapa ett konto nedan för att komma igång!",

    //Login.tsx
    login_title: "Logga in",
    login_username: "Användarnamn",
    login_password: "Lösenord",
    login_login: "Logga in",
    login_forgotPassword: "Glömt lösenord?",
    login_fail:
      "Något gick fel. Är du säker på att du har skrivit in rätt användarnamn och lösenord?",
    login_errorResponder:
      "Sjukvårdspersonal kan inte logga in på webben, vänligen använd vår app Vårdkällan istället.",

    //AdminResponder.tsx
    adminResponder_detailedInfo: "Detaljerad Information",

    //Admin.tsx
    admin_interestedPeople: "Sjukvårdspersonal",

    "admin.requester.title": "Vårdenheter",

    //AdminContent/ResponderTable
    adminContentResponderTable_name: "Namn",
    adminContentResponderTable_email: "E-post",
    adminContentResponderTable_verified: "Verifierad",

    //AdminContent/RegisteredPerson
    adminContentRegisteredPerson_info: "Profil",
    adminContentRegisteredPerson_name: "Namn",
    adminContentRegisteredPerson_personalNumber: "Personnummer",
    adminContentRegisteredPerson_email: "E-post",
    adminContentRegisteredPerson_phoneNumber: "Telefonnummer",
    adminContentRegisteredPerson_presentation: "Beskrivning",
    adminContentRegisteredPerson_bankInfo: "Bankinformation",
    adminContentRegisteredPerson_bankName: "Banknamn",
    adminContentRegisteredPerson_bankAccountNumber: "Bankkontonummer",
    adminContentRegisteredPerson_clearingNumber: "Clearing-nummer",
    adminContentRegisteredPerson_address: "Adress",
    adminContentRegisteredPerson_cityArea: "Stad/område",
    adminContentRegisteredPerson_zipcode: "Postkod",
    adminContentRegisteredPerson_experienceCategory:
      "Erfarenhet och kompetenser",
    adminContentRegisteredPerson_skills: "Kompetenser",
    adminContentRegisteredPerson_experience: "Erfarenhet",
    adminContentRegisteredPerson_otherInfo: "Övrigt",
    adminContentRegisteredPerson_eServiceCard: "e-Tjänstekort",
    adminContentRegisteredPerson_driversLicense: "Körkort",
    adminContentRegisteredPerson_hsaId: "HSA-ID",
    adminContentRegisteredPerson_sithsExpiryDate: "SITHS utgångsdatum",
    adminContentRegisteredPerson_verifiedUser: "Verifierad användare",
    adminContentRegisteredPerson_approveUser: "Verifiera användare",
    adminContentRegisteredPerson_deleteButton: "Ta bort användare",
    adminContentRegisteredPerson_approved: "Användaren har verifierats!",
    adminContentRegisteredPerson_areYouSure:
      "Är du helt säker på att du vill ta bort denna användare? Detta kan INTE ångras.",

    //AdminContent/RequesterCreatorForm
    adminContentReqCreatorForm_somethingWentWrong:
      "Någonting gick fel. Prova igen senare.",
    adminContentReqCreatorForm_usernameAlreadyExists:
      "Det angivna användarnamnet används redan av en annan användare!",
    adminContentReqCreatorForm_organisationNrAlreadyExists:
      "Det angivna organisationsnumret används redan av en annan användare!",
    adminContentReqCreatorForm_emailAlreadyExists:
      "Den angivna e-posten används redan av en annan användare!",
    adminContentReqCreatorForm_forExample: "T.ex.",
    adminContentReqCreatorForm_newRequester: "En vårdenhet har nu lagts till!",
    adminContentReqCreatorForm_updatePage:
      "Uppdatera sidan för att lägga till en ny vårdenhet",
    adminContentReqCreatorForm_addNewRequesterHere:
      "Lägg till en ny vårdenhet här:",
    adminContentReqCreatorForm_nameField: "Vårdenhet",
    adminContentReqCreatorForm_nameFieldExample:
      "'Akademiska Sjukhuset, Akutmottagningsenheten'",
    adminContentReqCreatorForm_idField: "Organisationsnummer",
    adminContentReqCreatorForm_idFieldExample: "'559116-1459'",
    adminContentReqCreatorForm_description: "Beskrivning",
    adminContentReqCreatorForm_descriptionExample:
      "'Medelstor vårdenhet nära centralstationen'",
    adminContentReqCreatorForm_phoneNumber: "Telefonnummer",
    adminContentReqCreatorForm_phoneNumberExample: "'070 123 45678'",
    adminContentReqCreatorForm_addressField: "Adress",
    adminContentReqCreatorForm_addressFieldExample: "'Stockholmsgatan 1'",
    adminContentReqCreatorForm_cityField: "Stad",
    adminContentReqCreatorForm_cityFieldExample: "'Uppsala'",
    adminContentReqCreatorForm_zipField: "Postkod",
    adminContentReqCreatorForm_zipFieldExample: "'11351'",
    adminContentReqCreatorForm_emailField: "E-post",
    adminContentReqCreatorForm_emailFieldExample: "'exempel@exempel.se'",
    adminContentReqCreatorForm_emailFieldhelp:
      "Vänligen fyll i email i korrekt format",
    adminContentReqCreatorForm_contactPersonNameHelp: "Kontaktperson",
    adminContentReqCreatorForm_contactPersonNameHelpExample: "'Helena Nilsson'",
    adminContentReqCreatorForm_usernameField: "Användarnamn",
    adminContentReqCreatorForm_usernameExample: "Användarnamn_1",
    adminContentReqCreatorForm_usernamehelp:
      "Användarnamn får inte ha mellanslag",
    adminContentReqCreatorForm_payMarginField: "Betalmarginal",
    adminContentReqCreatorForm_payMarginFieldExample: "20",
    adminContentReqCreatorForm_payMarginHelp:
      "T.ex. representerar 20 att 20% av betalningen från vårdenheten tillfaller KGT Care.",
    adminContentReqCreatorForm_passwordField: "Lösenord",
    adminContentReqCreatorForm_passwordFieldExample: "Qwerty-12345",
    adminContentReqCreatorForm_passwordFieldhelp:
      "Använd inte mellanslag i lösenordet",
    adminContentReqCreatorForm_submit: "Registrera vårdenhet",
    adminContentReqCreatorForm_cancel: "Avbryt",
    adminContentReqCreatorForm_createNew: "Skapa ny vårdenhet",
    adminContentReqCreatorForm_atLeast5: "Måste vara minst fem tecken långt.",
    adminContentReqCreatorForm_validityText: "Vänligen fyll i fältet",
    adminContentReqCreatorForm_validityRegion: "Vänligen välj en region",
    adminContentReqCreatorForm_validityPayMargin:
      "Välj en siffra mellan 0 och 100 som procent.",
    adminContentReqCreatorForm_validityUsername:
      "Användarnamn måste vara ifyllt och minst fem tecken",
    adminContentReqCreatorForm_validityUsernameHelper:
      "Användarnamnet kan bestå av bostäver, nummer och '_', användarnamnet kan inte över över 40 karaktärer. Lösenordet ska börja och avslutas med nummer eller bokstav.",
    adminContentReqCreatorForm_validityPasswordHelper:
      "Lösenordet ska vara minst 8 karaktärer och måste innehålla både bokstäver och siffror.",

    //Requester/RequestList
    requestList_header: "Skapade pass",
    requestList_title: "Passtitel",
    requestList_date: "Datum",
    requestList_time: "Starttid",
    requestList_duration: "Längd",
    requestList_count: "Antal intresserad sjukvårdspersonal",
    requestList_status: "Status",
    requestList_h: " h",
    requestList_min: " min",
    requestList_noCreatedTable: "Det finns inga skapade pass än",

    //Requester/RequestInterestList
    "requestInterestList.headline": "Intresserad vårdpersonal",
    requestInterestList_name: "Namn",
    requestInterestList_experience: "Erfarenhet",
    requestList_skills: "Kompetenser",
    requestList_journalSystems: "Journalsystem",
    "profile.year": "år",
    "profile.years": "år",

    //Requester/RequestInformation
    "responderList.requestInformation.headline": "Passinformation",
    "responderList.requestInformation.expired":
      "Passet kan inte längre redigeras eftersom det antingen snart ska börja eller redan har börjat.",
    "responderList.requestInformation.warning":
      "Tänk på att inte sätta passets starttid för nära inpå. När passet snart skall börja kan man inte längre redigera passet!",
    "responderList.requestInformation.tooClose":
      "Passets starttid är i dåtid eller alltför nära nutid. Tänk på att sjukvårdspersonal måste hinna söka och sedan godkännas innan passet startar!",
    "responderList.requestInformation.title": "Titel",
    "responderList.requestInformation.description": "Beskrivning",
    "responderList.requestInformation.startTime": "Starttid",
    "responderList.requestInformation.duration": "Längd",
    "responderList.requestInformation.h": " h",
    "responderList.requestInformation.compensation": "Betalning",
    "responderList.requestInformation.perHour": " kr/h",
    "responderList.requestInformation.experience": "Erfarenhetskrav",
    "responderList.requestInformation.skills": "Kompetenser",
    "responderList.requestInformation.removeButton.text": "Ta bort pass",
    "responderList.requestInformation.removeError":
      "Det gick inte att ta bort passet. Detta kan ske bl.a. när en kontraktförfrågan redan har skickats till en annan användare.",
    "responderList.requestInformation.removeConfirmation":
      "Är du säker på att du vill ta bort detta pass?",
    "responderList.requestInformation.edit": "Redigera pass",
    "responderList.requestInformation.cancel": "Avbryt",
    "responderList.requestInformation.save": "Spara",
    "responderList.requestInformation.error":
      "Någonting gick fel med att hantera datan, försök igen!",
    "requestInfo.canNoLongerEdit":
      "Passet kommer snart att påbörjas, du kan därav inte längre ändra passet",

    //ResponderExperience
    responderExperience_0: "<1 år",
    responderExperience_1: "1-2 år",
    responderExperience_2: "2-5 år",
    responderExperience_3: "5-10 år",
    responderExperience_4: "10+ år",

    //Header.tsx
    header_hi: "Hej ",
    header_home: "Kom igång",
    header_aboutUs: "Om oss",
    header_medicalPersonnel: "Sjukvårdspersonal",
    header_medicalCentral: "Vårdenheter",
    header_medicalCentral_dropdown1: "För vårdenheter",
    header_medicalCentral_dropdown2: "Logga in",
    header_signOut: "Logga ut",

    //Footer.tsx
    footer_contactUs: "Har du frågor? kontakt@vårdkällan.se",

    "requestDashboard.tabs.request": "Skapa pass",
    "requestDashboard.tabs.request_list": "Pass",
    "requestDashboard.tabs.time_report": "Tidrapporter",
    "requestDashboard.tabs.info": "Vårdenhetsinformation",

    "createRequest.title": "Skapa nytt pass",
    "createRequest.action.new": "Skapa ett till pass",
    "createRequest.action.submit": "Publicera pass",
    "createRequest.alert.submit.one": "Ditt pass har publicerats",
    "createRequest.alert.submit.many": "Dina pass har publicerats",
    "createRequest.alert.error": "Någonting gick fel, meddelande: ",
    "createRequest.alert.expired":
      "Ett eller fler av passen börjar för snart för att vårdpersonal ska hinna godkänna passet. Publicering nekad.",
    "createRequest.alert.input":
      "Vänligen kontrollera att all information är korrekt ifylld.",
    "createRequest.alert.inputNotEnough":
      "Vänligen kontrollera att all information är korrekt ifylld.",
    "createRequest.alert.emptyForm":
      "Du försökte publicera tomma pass! Ta bort överflödiga pass-mallar eller fyll i information för samtliga pass.",

    "requestForm.action.delete": "Ta bort pass",
    "requestForm.label.name": "Passets titel",
    "requestForm.label.description": "Beskrivning för passet",
    "requestForm.label.compensation": "Betalning för passet",
    "requestForm.label.compensationExtraInfo":
      "Belopp i kr/h. Kompensationen ni betalar till KGT Care exkl. moms.",
    "requestForm.label.startDate": "Startdatum och tid",
    "requestForm.label.endDate": "Slutdatum och tid",
    "requestForm.header.requirements": "Krav på sökande",
    "requestForm.label.years": "Erfarenhet",
    "requestForm.years": "år",
    "requestForm.label.competences": "Kompetenser",
    "requestForm.label.tools": "Journalsystem",
    "requestForm.helper.titleShort":
      "Passtitieln måste innehålla åtminstone 5 tecken",
    "requestForm.helper.titleLong":
      "Passtiteln måste innehålla färre än 60 tecken",
    "requestForm.helper.description":
      "Beskrivningen är för lång för att få plats",
    "requestForm.noRequests":
      'Tryck på "Skapa ett till pass" för att börja skapa pass. Du kan sedan fylla i flertalet pass innan du publicerar dem. Knappen "Publicera pass" publicerar samtliga pass öppna på denna sidan.',
    "createRequest.alert.noRequests":
      'Du kan inte publicera pass utan att först skapa dem. Tryck på "Skapa ett till pass" för att börja skapa pass.',

    //InterestedResponder.tsx
    "approveResponder.action.approve": "Skicka kontraktförfrågan",
    "approveResponder.action.cancel": "Avbryt kontraktförfrågan",
    "approveResponder.action.sign": "Bekräfta signerad kontraktförfrågan",
    "approveResponder.action.status.approved": " Kontraktförfrågan skickad",
    "approveResponder.action.status.inactive": " Inaktiv",
    "approveResponder.action.status.waiting": " Intresserad",
    "approveResponder.action.status.signed": " Signerad",

    //RequesterTimeReportList
    timeReportList_unapproved_timeReports: "Ej godkända tidrapporter",
    timeReportList_approved_timeReports: "Godkända tidrapporter",
    timeReportList_choose_all: "Markera alla",
    timeReportList_approve_all: "Godkänn markerade tidrapporter",
    timeReportList_request_check: "Markera",
    timeReportList_request_title: "Passtitel",
    timeReportList_request_date: "Datum",
    timeReportList_responder_name: "Utförarens namn",
    timeReportList_request_hours: "Passlängd",
    timeReportList_request_reported: "Rapporterad tid",
    timeReportList_request_h: " h",
    timeReportList_status: "Status",
    timeReportList_statusApproved: "Godkänd",
    timeReportList_statusUnapproved: "Ej godkänd",
    timeReportList_statusPending: "Väntar",
    timeReportList_noChecked: "Du måste välja en eller flera tidrapporter",
    timeReportList_noUnnapproved: "Det finns inga tidrapporter att godkänna",
    timeReportList_noUnnapprovedTable:
      "Det finns inga ej godkända tidrapporter",
    timeReportList_noApprovedTable: "Det finns inga godkända tidrapporter",

    //RequesterTimeReport
    requesterTimeReport_info: "Information om tidrapporten",
    "approveTimeReport.action.approve": "Godkänn tidrapport",
    "approveTimeReport.action.deny": "Neka tidsrapport",
    "timeReport.action.confirm": "Är du säker på du vill neka tidsrapporten?",
    "timeReport.action.conment": "Kommentar",
    timeReportList_error: "Något gick fel. Försök igen senare.",

    "adminDashboard.tabs.responder": "Sjukvårdspersonal",
    "adminDashboard.tabs.requester": "Vårdenheter",

    "admin.requester.create.heading": "Skapa ny vårdenhet",

    "requesterList.head.name": "Vårdenhet",
    "requesterList.head.address": "Adress",
    "requesterList.head.time_reports": "Exportera godkända tidrapporter",
    "requesterList.export": "Exportera .csv",
    requesterList_none_to_export:
      "Det finns inga godkända tidrapporter att exportera",

    "requesterTable.heading.title": "Din vårdenhet",
    "requesterTable.heading.name": "Vårdenhet",
    "requesterTable.heading.description": "Beskrivning",
    "requesterTable.heading.identifier": "Organisationsnummer",
    "requesterTable.heading.phoneNumber": "Telefonnummer",
    "requesterTable.heading.address": "Adress",
    "requesterTable.heading.zip": "Postkod",
    "requesterTable.heading.city": "Stad",
    "requesterTable.heading.region": "Region",
    "requesterTable.heading.regionNotChosen": "Välj region",
    "requesterTable.heading.standardPayMargin": "Betalmarginal",
    "requesterTable.heading.email": "E-post",
    "requesterTable.heading.contactPersonName": "Kontaktperson",
    "requesterTable.missingRegion": "Vårdenheten har ingen tilldelad region",
    "requesterTable.noLoadedRegions": "Gick inte att hämta",
    "requesterTable.changePassword": "Ändra/Byt lösenord",
    "requesterTable.button.edit": "Redigera",
    "requesterTable.button.save": "Spara",
    "requesterTable.button.cancel": "Avbryt",
    "requesterTable.noEmail":
      "Den angivna e-posten verkar inte stämma; den är på fel format. Det är viktigt att du anger din e-post rätt, annars kan du inte återställa ditt lösenord från utloggat läge.",
    "requesterTable.requiredRemoved": "Måste vara ifylld",
    "reset.heading": "Välj ett nytt lösenord",
    "reset.success": "Ditt lösenord har ändrats!",
    "reset.password.label": "Nytt lösenord",
    "reset.password.helper": "Lösenordet måste vara minst 8 tecken långt",
    "reset.button.reset": "Ändra lösenord",
    "reset.error.missing":
      "Denna återställningslänk fungerar inte. Den är antingen för gammal eller felaktig.",
    "reset.error.unknown": "Ett okänt fel har uppstått.",
    "reset.password.goBack": "Gå tillbaka",

    "forgot.heading": "Återställ lösenord",
    "forgot.email": "E-post",
    "forgot.cancel": "Avbryt",
    "forgot.reset": "Återställ",
    "forgot.fail": "E-posten hittades inte! Försök igen.",
    "forgot.success":
      "En länk har nu skickats via e-post. Följ instruktionerna för att återställa ditt lösenord!",
    "forgot.success_admin":
      "En återställningslänk har skickats till den angivna e-posten. Be kontaktpersonen för vårdenheten att följa instruktionerna som skickas med i mailet.",
    "forgot.information":
      "Om du är sjukvårdspersonal och vill återställa ditt lösenord, tryck på knappen nedan och följ sedan instruktionerna som skickas via e-post.",
    "forgot.information_addendum":
      "Av säkerhetsskäl kan vi inte återställa lösenord för vårdenheter utanför inloggat läge. Är du en vårdenhet och har glömt ditt lösenord, kontakta oss direkt på kontakt@vårdkällan.se eller 070-731 92 76 så hjälper vi dig att komma tillbaka in i ditt konto!",
    "appStoreInfo.appstore.download": "Hämta Vårdkällan i Apple App Store",
    "appStoreInfo.playstore.download": "Ladda ned Vårdkällan på Google Play",
    "forgot.error.missing": "Användaren hittades ej, försök igen!",
    "forgot.error.noEmail":
      "Vi försökte skicka ett mail med återställningslänk, men vi hittade inte din e-post i databasen! Är du säker på att du har angivit en giltig e-post?",
    "forgot.error.internal": "Vi påträffade ett internt fel. Försök igen!",
    "forgot.iAmResponder": "Jag är sjukvårdspersonal",

    requester_editInfo_attempted_email_change:
      "Av säkerhetsskäl kan du själv inte ändra e-post. Kontakta oss direkt på kontakt@vårdkällan.se så hjälper vi dig!",
    requester_editInfo_unique_violation_email_asAdmin:
      "Den angivna e-posten är redan registrerad hos en annan användare.",

    requester_editInfo_error: "Någonting gick fel. Vänligen försök igen.",

    "adminOneRequester.loginButton.text": "Logga in som denna vårdenhet",
    "adminOneRequester.loginButton.error":
      "Ett okänt fel inträffade och du kunde inte loggas in.",
    "adminOneRequester.delete": "Ta bort vårdenhet",
    "adminOneRequester.delete_areYouSure":
      "Är du helt säker på att du vill ta bort denna vårdenhet? Detta kan INTE ångras.",
    "adminOneRequester.delete_error":
      "Gick inte att ta bort vårdenhet, testa igen senare.",

    yes: "Ja",
    no: "Nej",
  };
};

export default getSwedishTranslateData;
