import { Endpoint } from "../Endpoint";
import API from "../API";
import OneResponderEndpoint from "./_id";

const name = "/responder";

interface PostRequestBody {
  name: string;
  personalNumber: string;
  email: string;
  phone?: string;
  presentation?: string;
  skills?: Array<{ id: string }>;
  experience?: number;
  bankAccountNumber?: string;
  clearingNumber?: string;
  bankName?: string;
  billingStreet?: string;
  billingCity?: string;
  billingZip?: string;
  hsaId?: string;
  sithsExpiration?: Date;
  eServiceCard?: boolean;
  driversLicence?: boolean;
  password: string;
}

interface PutRequestBody {
  id: string;
  verified: boolean;
}

type PostResponseBody = "OK";
type PutResponseBody = "OK";

interface GetResponseBody {
  responders: Array<Responder>;
}

interface Responder {
  name: string;
  email: string;
  id: string;
  verified: boolean;
}

export {
  name,
  PostRequestBody,
  PostResponseBody,
  GetResponseBody,
  Responder,
  PutRequestBody,
  PutResponseBody
};

export default class ResponderEndpoint extends Endpoint {
  _id: OneResponderEndpoint;
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
    this._id = new OneResponderEndpoint(api, this);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }

  put(body: PutRequestBody) {
    return this.sendPut<PutResponseBody>(body);
  }
}
