import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/matchresponders";

type GetQueryParamsBody = {
  id: string;
};

type GetResponseBody = Array<{
  name: string;
  email: string;
  id: string;
}>;

export { name, GetQueryParamsBody, GetResponseBody };

export default class MatchResponders extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get(urlParams: GetQueryParamsBody) {
    return this.sendGet<GetResponseBody>(urlParams);
  }
}
