import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import { differenceInMinutes, format, parseISO, subHours } from "date-fns";
import svLocale from "date-fns/locale/sv";
import i18n from "i18next";
import API from "kgt-api";
import { PutRequestBody, Request } from "kgt-api/dist/Request/_id";
import { GetResponseBody as Skills } from "kgt-api/dist/Skill";
import { convertExperienceToText } from "kgt-api/dist/utils/experienceConverter";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Theme from "../../utils/Theme";
import Alert from "../Alert/Alert";
import Confirm from "../Alert/Confirm";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonWarning from "../Buttons/ButtonWarning";
import ButtonWhite from "../Buttons/ButtonWhite";
import RequestForm, { Request as EditingRequest } from "../RequestForm";
import RequestTableRow from "../RequestList/RequestTableRow";
import SpinLoader from "../SpinLoader";
import TypographyTitle from "../Typography/TypographyTitle";
import styles from "./RequestInformation.css";

// Buffer time (in whole hours); a request cannot be modified for this many hours before its start time
// A value of 0.5 corresponds to locking out the request 30min before its start time
const BUFFER_TIME: number = 0.5;

interface Props extends RouteComponentProps<{ requestId: string }> {}
interface State {
  request?: Request;
  skills?: Skills;
  editedRequest: EditingRequest | null;
  loading: boolean;
  removeError?: boolean;
  isRequestStillEditable: boolean;
  alertVisible: boolean;
  confirmVisable: boolean;
  alertMessage: string;
}

class RequestInformation extends React.Component<Props, State> {
  state: State = {
    editedRequest: null,
    loading: false,
    isRequestStillEditable: true,
    alertVisible: false,
    confirmVisable: false,
    alertMessage: "",
  };

  async componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const skills = await API.Instance().skill.get();
        const response = await API.Instance().request._id.get({
          id: this.props.match.params.requestId,
        });
        const data = await response.json();
        const skillData = await skills.json();
        this.setState({
          loading: false,
          request: data.request,
          skills: skillData,
        });

        // Check if the request is within BUFFER_TIME of start
        // If so, the Edit button will be disabled
        if (this.state.request !== undefined) {
          var expired = this.handleTimeCheck(this.state.request, BUFFER_TIME);
          if (expired) {
            this.setState({ isRequestStillEditable: false });
          } else {
            this.setState({ isRequestStillEditable: true });
          }
        }
      }
    );
  }

  startEdit = () => {
    if (this.state.request) {
      const editedRequest = {
        ...this.state.request,
        localId: 0,
        tools: this.state.request.skills.filter((s) => s.type === "tool"),
        competences: this.state.request.skills.filter(
          (s) => s.type === "competence"
        ),
        startTime: new Date(this.state.request.startTime),
      };
      this.setState({
        editedRequest,
      });
    }
  };

  cancelEdit = () => {
    this.setState({
      editedRequest: null,
    });
  };

  saveEdit = () => {
    const editedRequest = this.state.editedRequest;

    if (editedRequest && this.state.request) {
      // Check if the request is too close to starting to edit
      var expired = this.handleTimeCheck(editedRequest, BUFFER_TIME);

      if (expired) {
        this.setState({ isRequestStillEditable: false });
        this.setState({
          alertVisible: true,
          alertMessage: i18n.t("responderList.requestInformation.tooClose"),
        });
        return;
      } else {
        this.setState({ isRequestStillEditable: true });

        const requestId = this.state.request.id;
        const putData: PutRequestBody = {
          title: editedRequest.title,
          description: editedRequest.description,
          compensation: editedRequest.compensation,
          startTime: editedRequest.startTime.toISOString(),
          duration: editedRequest.duration,
          skills: editedRequest.tools.concat(editedRequest.competences),
          yearExperience: editedRequest.yearExperience,
        };

        this.setState(
          {
            loading: true,
            editedRequest: null,
          },
          async () => {
            await API.Instance().request._id.put(putData, {
              id: requestId,
            });

            // Fetch the new updated version
            const res = await API.Instance().request._id.get({
              id: this.props.match.params.requestId,
            });
            const data = await res.json();
            this.setState({
              loading: false,
              request: data.request,
            });
          }
        );
      }
    } else {
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("responderList.requestInformation.error"),
      });
    }
  };

  handleInputChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (this.state.editedRequest === null) {
      return;
    }

    const field: string = e.target.name;
    const val: string = e.target.value;

    const newRequest = {
      ...this.state.editedRequest,
      [field]: val,
    };
    this.setState({
      editedRequest: newRequest,
    });
  };

  onDeleteClick = () => {
    this.setState({
      confirmVisable: true,
      alertMessage: i18n.t(
        "responderList.requestInformation.removeConfirmation"
      ),
    });
  };

  handleRemove = async () => {
    const res = await API.Instance().request._id.delete({
      id: this.props.match.params.requestId,
    });
    if (res.ok) {
      this.props.history.goBack();
    } else {
      this.setState({
        removeError: true,
      });
    }
  };

  handleChange = ({}, changedRequest: EditingRequest) => {
    this.setState({
      editedRequest: changedRequest,
    });
  };

  handleTimeCheck = (request: Request | EditingRequest, buffer: number) => {
    // In order to compare times, create both current time and start of request (incl. buffer) as Dates
    // Remove amount of hours (buffer) from the start time
    // If too close to start time, return TRUE, else return FALSE
    var now: Date = new Date();
    if (typeof request.startTime === "string") {
      var startOfRequest: Date = parseISO(request.startTime);
    } else {
      var startOfRequest: Date = request.startTime;
    }
    var startOfRequestInclBuffer: Date = subHours(startOfRequest, buffer);

    return differenceInMinutes(startOfRequestInclBuffer, now) <= 0
      ? true
      : false;
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  closeOverlayConfirm = (value: Boolean | undefined) => {
    this.setState({
      confirmVisable: false,
      alertMessage: "",
    });
    if (value !== undefined) {
      if (value) {
        this.handleRemove();
      }
    }
  };

  render() {
    const { request, editedRequest, skills, loading, removeError } = this.state;
    const isEditing = editedRequest !== null;
    let booked: boolean = true;
    if (request) {
      booked = request.booked;
    }
    return (
      <article style={{ marginBottom: Theme.largeSpacing }}>
        {loading && <SpinLoader />}

        {request && !isEditing && !loading && (
          <React.Fragment>
            <div style={{ display: "flex" }}>
              <TypographyTitle
                text={i18n.t("responderList.requestInformation.headline")}
              />
              {!isEditing && (
                <ButtonPrimary
                  disabled={!this.state.isRequestStillEditable || booked}
                  title={i18n.t("responderList.requestInformation.edit")}
                  onClick={this.startEdit}
                  marginLeft="auto"
                />
              )}
            </div>
            <Table
              className={styles.contentTable}
              style={{ marginTop: Theme.mediumSpacing }}
            >
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "70%" }} />
              </colgroup>
              <TableBody>
                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.title")}
                  text={request.title}
                />

                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.description")}
                  text={request.description}
                />

                <RequestTableRow
                  title={i18n.t(
                    "responderList.requestInformation.compensation"
                  )}
                  text={
                    request.compensation.toString() +
                    i18n.t("responderList.requestInformation.perHour")
                  }
                />

                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.startTime")}
                  text={format(
                    new Date(request.startTime),
                    "yyyy-MM-dd, HH:mm",
                    {
                      locale: svLocale,
                    }
                  )}
                />

                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.duration")}
                  text={
                    request.duration.toString() +
                    i18n.t("responderList.requestInformation.h")
                  }
                />

                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.experience")}
                  text={experienceToString(request.yearExperience)}
                />

                <RequestTableRow
                  title={i18n.t("responderList.requestInformation.skills")}
                  text={skillsToString(request.skills)}
                />
              </TableBody>
            </Table>
            {!isEditing && !this.state.isRequestStillEditable && (
              <Typography
                style={{
                  flex: 1,
                  textAlign: "center",
                  paddingTop: 8,
                }}
              >
                {i18n.t("responderList.requestInformation.expired")}
              </Typography>
            )}
          </React.Fragment>
        )}
        {editedRequest && skills && isEditing && (
          <RequestForm
            tools={skills.filter((s) => s.type === "tool")}
            competences={skills.filter((s) => s.type === "competence")}
            request={editedRequest}
            onChange={this.handleChange}
            hideRemovalButton={true}
          />
        )}

        {isEditing && (
          <div style={{ display: "flex" }}>
            <ButtonWhite
              title={i18n.t("responderList.requestInformation.cancel")}
              onClick={this.cancelEdit}
            />
            <ButtonWarning
              title={i18n.t("requestForm.action.delete")}
              onClick={this.onDeleteClick}
              marginLeft="auto"
            />
            <ButtonPrimary
              title={i18n.t("responderList.requestInformation.save")}
              onClick={this.saveEdit}
              marginLeft="1em"
            />
            <Confirm
              message={this.state.alertMessage}
              visible={this.state.confirmVisable}
              closeOverlayConfirm={this.closeOverlayConfirm}
            ></Confirm>
            <Alert
              message={this.state.alertMessage}
              visible={this.state.alertVisible}
              closeOverlay={this.closeOverlay}
            ></Alert>
          </div>
        )}
        {removeError && (
          <Typography color="error">
            {i18n.t("responderList.requestInformation.removeError")}
          </Typography>
        )}
      </article>
    );
  }
}

export default withRouter(RequestInformation);

//Shorthand for converting experience from number to proper year-span format
//  Uses the API solution but also adds the "Years" text at the end
function experienceToString(num: number) {
  if (num <= 0) {
    return convertExperienceToText(num) + " " + i18n.t("profile.year");
  } else {
    return convertExperienceToText(num) + " " + i18n.t("profile.years");
  }
}

function skillsToString(skills: Skills) {
  if (skills.length == 0) {
    return "-";
  }
  let skillsArray = new Array(skills.length);
  let i = 0;

  skills.map((value) => ((skillsArray[i] = i18n.t(value.tag)), i++));

  return skillsArray.join(", ");
}
