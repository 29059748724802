import i18n from "i18next";
import * as React from "react";
import illustration from "../../images/getApp.png";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitleBig from "../Typography/TypographyTitleBig";
import styles from "./AboutContent.css";

class AboutPageContent extends React.Component {
  render() {
    return (
      <div style={{ paddingBottom: "11em", display: "flex" }}>
        <div className={styles.container}>
          <TypographyTitleBig text={i18n.t("aboutPageContent.heading")} />

          <div className={styles.leftContainer}>
            <TypographyRegular text={i18n.t("aboutPageContent.paragraph")} />

            <div>
              <TypographyRegular
                text={i18n.t("aboutPageContent.paragraph_second")}
              />
            </div>
            <div>
              <TypographyRegular
                text={i18n.t("aboutPageContent.paragraph_third")}
              />
            </div>
            <div>
              <TypographyRegular
                text={i18n.t("aboutPageContent.paragraph_fourth")}
              />
            </div>
          </div>

          <div className={styles.rightContainer}>
            <img
              src={illustration}
              alt="Vardkallan"
              className={styles.imageContainer}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutPageContent;
