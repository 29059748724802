import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/account";

interface PostRequestBody {
  accountName: string;
  password: string;
  mustChangePassword: boolean;
}
type PostResponseBody = string;

export { name, PostRequestBody, PostResponseBody };

export default class Account extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
}