import * as React from "react";
import ScreenContent from "../../components/ScreenContent";
import RequesterTopContent from "../../components/RequesterTopContent/RequesterTopContent";

function Requester() {
  return (
    <ScreenContent>
      <RequesterTopContent />
    </ScreenContent>
  );
}

export default Requester;
