import Typography from "@material-ui/core/Typography";
import React from "react";
import Theme from "../../utils/Theme";

interface TypographyTitleBigProps {
    text: string;
    className?: string;
    marginLeft?: any;
}

export default class TypographyTitleBig extends React.Component<TypographyTitleBigProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                variant="h3"
                style={{
                    marginLeft: this.props.marginLeft,
                    marginBottom: Theme.smallSpacing,
                    color: Theme.primaryTextColour
                }}
            >
                {this.props.text}
            </Typography>
        );
    }
}