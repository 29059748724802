import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import i18n from "i18next";
import API from "kgt-api";
import { GetResponseBody, Responder } from "kgt-api/dist/Responder";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Theme from "../../../utils/Theme";
import Alert from "../../Alert/Alert";
import SpinLoader from "../../SpinLoader";
import CellBold from "../../Table/CellBold";
import TypographyTitle from "../../Typography/TypographyTitle";
import style from "./ResponderTable.css";

interface Props extends RouteComponentProps {}

interface State {
  verifiedResponders?: Responder[];
  unverifiedResponders?: Responder[];
  ready: boolean;
  alertVisible: boolean;
  alertMessage: string;
}

class ResponderTable extends React.Component<Props, State> {
  state: State = {
    ready: false,
    alertVisible: false,
    alertMessage: "",
  };

  async componentDidMount() {
    const response = await API.Instance().responder.get();
    if (response.ok) {
      const responseJson = await response.json();
      const responders = this.filterDeletedRows(responseJson);
      const verifiedResponders = responders.filter((r) => r.verified);
      const unverifiedResponders = responders.filter((r) => !r.verified);
      this.setState({
        verifiedResponders: verifiedResponders,
        unverifiedResponders: unverifiedResponders,
        ready: true,
      });
    } else {
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("error_noNetwork"),
        ready: true,
      });
    }
  }

  filterDeletedRows(response: GetResponseBody) {
    return response.responders.filter((r) => r.name !== "DELETED");
  }

  handleOnClick = (id: string) => {
    this.props.history.push(`${this.props.match.path}/${id}`);
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  render() {
    const { ready } = this.state;

    if (ready) {
      return (
        <React.Fragment>
          <TypographyTitle text={i18n.t("admin_interestedPeople")} />
          <Table>
            <TableHead>
              <TableRow>
                <CellBold text={i18n.t("adminContentResponderTable_name")} />
                <CellBold text={i18n.t("adminContentResponderTable_email")} />
                <CellBold
                  text={i18n.t("adminContentResponderTable_verified")}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.unverifiedResponders &&
                this.state.unverifiedResponders.map((responder) => (
                  <ResponderRow
                    responder={responder}
                    key={responder.id}
                    onClick={this.handleOnClick}
                  />
                ))}
              {this.state.verifiedResponders &&
                this.state.verifiedResponders.map((responder) => (
                  <ResponderRow
                    responder={responder}
                    key={responder.id}
                    onClick={this.handleOnClick}
                  />
                ))}
            </TableBody>
          </Table>
          <Alert
            message={this.state.alertMessage}
            visible={this.state.alertVisible}
            closeOverlay={this.closeOverlay}
          ></Alert>
        </React.Fragment>
      );
    } else {
      return <SpinLoader />;
    }
  }
}
export default withRouter(ResponderTable);

interface RowProps {
  responder: Responder;
  onClick: (id: string) => void;
}

class ResponderRow extends React.Component<RowProps> {
  handleOnClick = () => {
    this.props.onClick(this.props.responder.id);
  };
  render() {
    const { responder } = this.props;
    return (
      <>
        <TableRow onClick={this.handleOnClick} hover className={style.row}>
          <TableCell>{responder.name} </TableCell>
          <TableCell>{responder.email} </TableCell>
          <TableCell>
            {responder.verified ? (
              <div
                className={style.indicator}
                style={{ backgroundColor: Theme.secondaryColour }}
              ></div>
            ) : (
              <div
                className={style.indicator}
                style={{ backgroundColor: Theme.inactiveButtonColorWarning }}
              ></div>
            )}
          </TableCell>
          <TableCell>
            <ArrowRight />
          </TableCell>
        </TableRow>
      </>
    );
  }
}
