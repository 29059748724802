import { Endpoint } from "../../Endpoint";
import API from "../../API";
import { RequestStatus } from "../Request";

const name = "/:id";

export interface GetUrlParams {
  id: string;
}
export interface GetQueryParams {
  responderId?: string;
}

export interface Skill {
  id: string;
  tag: string;
  type: string;
}
export interface Requester {
  id: string;
  name: string;
  address: string;
}
export interface Request {
  id: string;
  startTime: string;
  duration: number;
  yearExperience: number;
  createdAt: string;
  title: string;
  description: string;
  payMargin: number;
  compensation: number;
  skills: Skill[];
  requester: Requester;
  booked: boolean;
}
export interface Interest {
  status: RequestStatus;
}
export interface GetResponseBody {
  request: Request;
  responderInterest?: Interest;
}

export interface DeleteUrlParams {
  id: string;
}
export type DeleteResponseBody =
  | "OK"
  | "Forbidden"
  | "Unauthrorized"
  | "Not Found"
  | "Bad Request"
  | "Internal Server Error";

export interface PutUrlParams {
  id: string;
}
export type PutResponseBody =
  | "OK"
  | "Forbidden"
  | "Unauthorized"
  | "Not Found"
  | "Bad Request";

export interface PutRequestBody {
  title?: string;
  description?: string;
  compensation?: number;
  startTime?: string;
  duration?: number;
  payMargin?: number;
  skills?: Array<{ id: string }>;
  yearExperience?: number;
}

export default class OneRequestEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  /**
   * Returns a specific request, with additional extra data if desired
   * @param urlParams Includes responderInterest if responderId is provided in query parameters
   * @param queryParams Can be included if responderInterest should be populated
   */
  get(urlParams: GetUrlParams, queryParams: GetQueryParams = {}) {
    return this.sendGet<GetResponseBody>(urlParams, queryParams);
  }

  /**
   * Deletes the specific resource given by id in urlParams
   * @param urlParams An object containing all needed url parameters
   */
  delete(urlParams: DeleteUrlParams) {
    return this.sendDelete({}, urlParams);
  }

  put(body: PutRequestBody, urlParams: PutUrlParams) {
    return this.sendPut<PutResponseBody>(body, urlParams);
  }
}
