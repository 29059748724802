import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import addMinutes from "date-fns/addMinutes";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { GetResponseBody as Skills } from "kgt-api/dist/Skill";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "../Buttons/ButtonWarning";
import KeyboardADateTimePicker from "../KeyboardDateTimePicker";
import TextFieldBarlow from "../TextField/TextFieldBarlow";
import TypographyRegular from "../Typography/TypographyRegular";
import styles from "./RequestForm.css";
import Theme from "../../utils/Theme";

export interface Request {
  localId: number;
  startTime: Date;
  duration: number;
  yearExperience: number;
  title: string;
  description: string;
  compensation: number;
  competences: Array<{ id: string }>;
  tools: Array<{ id: string }>;
}

interface Props {
  tools: Skills;
  competences: Skills;
  request: Request;
  onRemove: (localId: number) => void;
  onChange: (localId: number, newRequest: Request) => void;
  hideRemovalButton?: boolean;
}

const RequestForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { request, onChange } = props;

  const startDateTime = request.startTime;
  const endDateTime = addMinutes(startDateTime, request.duration * 60);
  let MIN_TITLE_LENGTH = 5;
  let MAX_TITLE_LENGTH = 60;
  let MAX_DESC_LENGTH = 400;

  // Handles the most common fields
  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const field: string = e.target.name;
    const val: string = e.target.value;

    const newRequest = {
      ...request,
      [field]: val,
    };
    onChange(request.localId, newRequest);
  }

  function handleSkillChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const field: string = e.target.name;

    // The typings don't work with multiple select, and thus assume strings
    // not string arrays! we have to cast to unknown, and then to string array
    // to fix this
    const val: unknown = e.target.value;
    const valArray = val as string[];

    const newRequest = {
      ...request,
      [field]: valArray.map((val) => ({ id: val })),
    };
    onChange(request.localId, newRequest);
  }

  function handleStartTimeChange(startTime: Date) {
    const newRequest: Request = {
      ...request,
      startTime,
    };
    onChange(request.localId, newRequest);
  }

  function handleEndTimeChange(date: Date) {
    const durationMinutes = differenceInMinutes(date, request.startTime);
    let duration = durationMinutes / 60;

    //We don't want to allow end dates earlier than start dates
    if (duration < 0) {
      duration = 0;
    }

    const newRequest = {
      ...request,
      duration,
    };
    onChange(request.localId, newRequest);
  }

  function handleNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
    const field: string = e.target.name;
    let val = Number.parseFloat(e.target.value);

    const newRequest = {
      ...request,
      [field]: val,
    };
    onChange(request.localId, newRequest);
  }

  return (
    <form className={styles.container}>
      <TextFieldBarlow
        label={t("requestForm.label.name")}
        value={request.title}
        name="title"
        onChange={handleChange}
        error={
          request.title.length < MIN_TITLE_LENGTH ||
          request.title.length > MAX_TITLE_LENGTH
        }
        helperText={
          request.title.length < MIN_TITLE_LENGTH
            ? t("requestForm.helper.titleShort")
            : request.title.length > MAX_TITLE_LENGTH
            ? t("requestForm.helper.titleLong")
            : ""
        }
      />

      <TextField
        style={{ marginTop: "1em", marginBottom: "1em", fontFamily: "Barlow" }}
        variant="outlined"
        name="description"
        fullWidth
        multiline
        rowsMax={4}
        label={t("requestForm.label.description")}
        value={request.description}
        onChange={handleChange}
        error={request.description.length > MAX_DESC_LENGTH}
        helperText={
          request.description.length > MAX_DESC_LENGTH
            ? t("requestForm.helper.description")
            : ""
        }
      />

      <TextFieldBarlow
        label={t("requestForm.label.compensation")}
        helperText={t("requestForm.label.compensationExtraInfo")}
        value={Number.isNaN(request.compensation) ? "" : request.compensation}
        name="compensation"
        onChange={handleNumberChange}
      />

      <section className={styles.dateFieldsContainer}>
        <div style={{ paddingRight: Theme.largeSpacing }}>
          <KeyboardADateTimePicker
            label={t("requestForm.label.startDate")}
            value={startDateTime}
            onChange={handleStartTimeChange}
            disablePast
          />
        </div>

        <KeyboardADateTimePicker
          label={t("requestForm.label.endDate")}
          value={endDateTime}
          onChange={handleEndTimeChange}
          disablePast
          minDate={startDateTime}
        />
      </section>

      <div>
        <TypographyRegular text={t("requestForm.header.requirements")} />
        <section className={styles.selectContainer}>
          <FormControl margin="normal" className={styles.select}>
            <InputLabel htmlFor="experience">
              {t("requestForm.label.years")}
            </InputLabel>
            <Select
              inputProps={{
                name: "yearExperience",
                id: "yearExperience",
              }}
              onChange={handleChange}
              required
              value={request.yearExperience}
            >
              <MenuItem value={0}>0 - 1 {t("requestForm.years")}</MenuItem>
              <MenuItem value={1}>1 - 2 {t("requestForm.years")}</MenuItem>
              <MenuItem value={2}>2 - 5 {t("requestForm.years")}</MenuItem>
              <MenuItem value={5}>5 - 10 {t("requestForm.years")}</MenuItem>
              <MenuItem value={10}>&gt; 10 {t("requestForm.years")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="normal" className={styles.selectWide}>
            <InputLabel htmlFor="competences">
              {t("requestForm.label.competences")}
            </InputLabel>
            <Select
              inputProps={{
                name: "competences",
                id: "competences",
              }}
              onChange={handleSkillChange}
              multiple
              required
              value={request.competences.map((competence) => competence.id)}
            >
              {props.competences.map((skill) => (
                <MenuItem key={skill.id} value={skill.id}>
                  {t(skill.tag)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" className={styles.select}>
            <InputLabel htmlFor="tools">
              {t("requestForm.label.tools")}{" "}
            </InputLabel>
            <Select
              inputProps={{
                name: "tools",
                id: "tools",
              }}
              onChange={handleSkillChange}
              multiple
              required
              value={request.tools.map((tool) => tool.id)}
            >
              {props.tools.map((skill) => (
                <MenuItem key={skill.id} value={skill.id}>
                  {t(skill.tag)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>

        <Typography
          style={{
            flex: 1,
            textAlign: "center",
            paddingBottom: 32,
          }}
        >
          {t("responderList.requestInformation.warning")}
        </Typography>

        {!props.hideRemovalButton && (
          <div className={styles.removalButton}>
            <ButtonSecondary
              title={t("requestForm.action.delete")}
              onClick={() => props.onRemove(request.localId)}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default RequestForm;
