import * as React from "react";
import { Switch, Route, RouteComponentProps } from "react-router";
import AdminResponder from "./AdminResponder";
import AdminOneResponder from "./AdminOneResponder";

const AdminResponderRoutes: React.SFC<RouteComponentProps> = props => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.path}/:responderId`} component={AdminOneResponder} />
      <Route path={`${match.path}`} component={AdminResponder} />
    </Switch>
  );
};
export default AdminResponderRoutes;
