import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/matchrequests";

type GetQueryParamsBody = {
  id: string;
  regions?: string[];
  requesters?: string[];
  fromDate?: string;
};

type GetResponseBody = Array<{
  id: string;
  startTime: Date;
  duration: number;
  yearExperience: number;
  title: string;
  description: string;
  compensation: number;
  payMargin: number;
  createdAt: string;
  skills: Array<{
    id: string;
    tag: string;
  }>;
  requester: {
    name: string;
  };
  eligible: boolean;
  booked: boolean;
}>;

export { name, GetQueryParamsBody, GetResponseBody };

export default class Translation extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get(queryParams: GetQueryParamsBody) {
    return this.sendGet<GetResponseBody>({}, queryParams);
  }
}
