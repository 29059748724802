import * as React from "react";
import styles from "./RequesterTopContent.css";
import i18n from "i18next";
import TypographyTitleBig from "../Typography/TypographyTitleBig";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitle from "../Typography/TypographyTitle";
import illustration from "../../images/hospital.png"; // fixme exchange for correct illustration when it has been produced
import imgStep1 from "../../images/register.png";
import imgStep2 from "../../images/createWorkShift.png";
import imgStep3 from "../../images/choosePersonell.png";
import imgStep4 from "../../images/reportTime.png";

class RequesterTopContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.container}>
          <TypographyTitleBig text={i18n.t("requesterPageContent.heading")} />

          <div className={styles.leftContainer}>
            <div>
              <TypographyRegular text={i18n.t("requesterPageContent.paragraph")} />
            </div>
            <div>
              <TypographyRegular text={i18n.t("requesterPageContent.paragraph_second")} />
            </div>
            <div>
              <TypographyRegular text={i18n.t("requesterPageContent.paragraph_third")} />
            </div>
            <div>
              <TypographyRegular text={i18n.t("requesterPageContent.paragraph_fourth")} />
            </div>
          </div>

          <div className={styles.rightContainer}>
            <img
              src={illustration}
              alt="Vardkallan Illustration"
              className={styles.imageContainer}
            />
          </div>

          <div className={styles.stepsContainer}>
            <table className={styles.stepTable}>
              <tbody>
              <tr>
                <td className={styles.stepLeft}>
                  <img
                    src={imgStep1}
                    alt="Vardkallan Illustration Step 1"
                    className={styles.stepImage}
                  />
                </td>
                <td className={styles.stepRight}>
                  <TypographyTitle text={i18n.t("requesterPageContent.step1")} />
                  <TypographyRegular text={i18n.t("requesterPageContent.step1_paragraph")} />
                </td>
              </tr>
              <tr>
                <td className={styles.stepLeft}>
                  <TypographyTitle text={i18n.t("requesterPageContent.step2")} />
                  <TypographyRegular text={i18n.t("requesterPageContent.step2_paragraph")} />
                </td>
                <td className={styles.stepRight}>
                  <img
                    src={imgStep2}
                    alt="Vardkallan Illustration Step 2"
                    className={styles.stepImage}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.stepLeft}>
                  <img
                    src={imgStep3}
                    alt="Vardkallan llustration Step 3"
                    className={styles.stepImage}
                  />
                </td>
                <td className={styles.stepRight}>
                  <TypographyTitle text={i18n.t("requesterPageContent.step3")} />
                  <TypographyRegular text={i18n.t("requesterPageContent.step3_paragraph")} />
                </td>
              </tr>
              <tr>
                <td className={styles.stepLeft}>
                  <TypographyTitle text={i18n.t("requesterPageContent.step4")} />
                  <TypographyRegular text={i18n.t("requesterPageContent.step4_paragraph")} />
                </td>
                <td className={styles.stepRight}>
                  <img
                    src={imgStep4}
                    alt="Vardkallan Illustration Step 4"
                    className={styles.stepImage}
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          {/*<div className={styles.loginComponent}>
            <LoginComponent />
    </div> fixme add */}
        </div>
      </React.Fragment>
    );
  }
}

export default RequesterTopContent;
