/**
 * Here we can define application wide theme settings
 */
const Theme = {
    headerTextColor: "#384B62", // Black
    secondaryTextColour: "#777C94", //Grey
    inactiveButtonColor: "#BCC2D9", //Ligh Grey
    inactiveButtonColorWarning: "#FBCCB7", // Light-ish Orange
  
    primaryColour: "#6C6CFC", // Blue
    primaryColourSoft: "#EBF1FF", // Light Blue
  
    secondaryColour: "#34B984", // Green
    secondaryColourSoft: "#E7F8F3", // Light Green
  
    tertiaryColour: "#F77E6E", // Orange
    tertiaryColourSoft: "#FEF3EE", // Light Orange
  
    primaryTextColour: "#384B62", // Dark Grey
    tertiaryTextColour: "#FFFFFF", // White
  
    backgroundColour: "#FFFFFF", // White
    backgroundColorSoft: "#FAFBFF", // Light Blue/Grey
  
    borderColor: "#EFEFEF",
    dividerColor: "#dbdbdb",
  
    whiteShadowColor: "#F0F0F0",
    whiteShadowOffset: {
      width: 0,
      height: 0,
    },
    whiteShadowOpacity: 1,
    whiteShadowRadius: 3.84,
    whiteElevation: 2,
  
    blueShadowColor: "#D5D9FF",
    blueShadowOffset: {
      width: 2,
      height: 2,
    },
    blueShadowOpacity: 0.4,
    blueShadowRadius: 3.84,
    blueElevation: 18,

    smallSpacing: "0.5em",
    mediumSpacing: "1em",
    largeSpacing: "2em",
    xlargeSpacing: "3em",

    buttonBorderRadius: 10,
  };

export default Theme;
  