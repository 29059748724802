import React from "react";
import TextField from "@material-ui/core/TextField";

interface TextFieldBarlowProps {
  label: string;
  name: string;
  value: any;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  type?: any;
  inputProps?: any;
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  onInvalid?: (e: React.FormEvent<HTMLDivElement>) => void;
  onInput?: (e: any) => void;
  onBlur?: (e: any) => void;
}

export default class TextFieldBarlow extends React.Component<
  TextFieldBarlowProps
> {
  render() {
    return (
      <TextField
        style={{ marginTop: "1em", marginBottom: "1em", fontFamily: "Barlow" }}
        variant="outlined"
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        error={this.props.error}
        helperText={this.props.helperText}
        required={this.props.required}
        onChange={this.props.onChange}
        onInvalid={this.props.onInvalid}
        onInput={this.props.onInput}
        onBlur={this.props.onBlur}
        type={this.props.type}
        placeholder={this.props.placeholder}
        inputProps={this.props.inputProps}
        disabled={this.props.disabled}
      />
    );
  }
}
