import API, { QueryParams } from "./API";

export abstract class Endpoint {
  api: API;
  endpoint: string;
  name: string;
  parent: Endpoint | null;

  constructor(api: API, name: string, parent: Endpoint | null = null) {
    this.api = api;
    this.parent = parent;
    this.name = name;
    if (this.parent === null) {
      this.endpoint = this.name;
    } else {
      this.endpoint = this.parent.endpoint + this.name;
    }
  }

  send<T>(method = "GET", body = {}, otherData: RequestInit = {}) {
    this.api.send<T>(this.endpoint, method, body, otherData);
  }

  sendPost<T>(body = {}, otherData: RequestInit = {}) {
    return this.api.post<T>(this.endpoint, body, otherData);
  }

  sendGet<T>(
    urlParams = {},
    queryParams: QueryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.api.get<T>(this.endpoint, urlParams, queryParams, otherData);
  }

  sendPut<T>(
    body = {},
    urlParams = {},
    queryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.api.put<T>(
      this.endpoint,
      body,
      urlParams,
      queryParams,
      otherData
    );
  }

  sendDelete<T>(
    body = {},
    urlParams = {},
    queryParams = {},
    otherData: RequestInit = {}
  ) {
    return this.api.delete<T>(
      this.endpoint,
      body,
      urlParams,
      queryParams,
      otherData
    );
  }
}
