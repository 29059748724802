import i18n from "i18next";
import * as React from "react";
import imgStep2 from "../../images/chooseWork.png";
import illustration from "../../images/hospitalStaff.png";
import imgStep1 from "../../images/register.png";
import imgStep3 from "../../images/reportTime.png";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitle from "../Typography/TypographyTitle";
import TypographyTitleBig from "../Typography/TypographyTitleBig";
import styles from "./ResponderContent.css";

class ResponderContent extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <TypographyTitleBig text={i18n.t("responderPageContent.heading")} />

        <div className={styles.leftContainer}>
          <div style={{ margin: 0 }}>
            <TypographyRegular
              text={i18n.t("responderPageContent.paragraph")}
            />
          </div>
          <div>
            <TypographyRegular
              text={i18n.t("responderPageContent.paragraph_second")}
            />
          </div>
          <div>
            <TypographyRegular
              text={i18n.t("responderPageContent.paragraph_third")}
            />
          </div>
          <div>
            <TypographyRegular
              text={i18n.t("responderPageContent.paragraph_fourth")}
            />
          </div>
        </div>

        <div className={styles.rightContainer}>
          <img
            src={illustration}
            alt="Vardkallan Illustration"
            className={styles.imageContainer}
          />
        </div>

        <div className={styles.stepsContainer}>
          <table className={styles.stepTable}>
            <tbody>
              <tr>
                <td className={styles.stepLeft}>
                  <img
                    src={imgStep1}
                    alt="Vardkallan Illustration Step 1"
                    className={styles.stepImage}
                  />
                </td>
                <td className={styles.stepRight}>
                  <TypographyTitle
                    text={i18n.t("responderPageContent.step1")}
                  />
                  <TypographyRegular
                    text={i18n.t("responderPageContent.step1_paragraph")}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.stepLeft}>
                  <TypographyTitle
                    text={i18n.t("responderPageContent.step2")}
                  />
                  <TypographyRegular
                    text={i18n.t("responderPageContent.step2_paragraph")}
                  />
                </td>
                <td className={styles.stepRight}>
                  <img
                    src={imgStep2}
                    alt="Vardkallan Illustration Step 2"
                    className={styles.stepImage}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.stepLeft}>
                  <img
                    src={imgStep3}
                    alt="Vardkallan Illustration Step 3"
                    className={styles.stepImage}
                  />
                </td>
                <td className={styles.stepRight}>
                  <TypographyTitle
                    text={i18n.t("responderPageContent.step3")}
                  />
                  <TypographyRegular
                    text={i18n.t("responderPageContent.step3_paragraph")}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ResponderContent;
