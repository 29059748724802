import * as React from "react";
import styles from "./RequesterCreatorForm.css";
import Button from "@material-ui/core/Button";
import { PostRequestBody } from "kgt-api/dist/Requester";
import { GetResponseBody } from "kgt-api/dist/Region";
import API from "kgt-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import i18n from "i18next";
import TypographyTitle from "../../Typography/TypographyTitle";
import { RouteComponentProps } from "react-router";
import Theme from "../../../utils/Theme";
import ButtonWhite from "../../Buttons/ButtonWhite";
import Alert from "../../Alert/Alert";
import RequesterInfo from "../../../screens/Requester/RequesterInfo";
import TextFieldBarlow from "../../TextField/TextFieldBarlow";

interface Props extends RouteComponentProps {}
interface RequesterInfo {
  name: string;
  requesterIdentifier: string;
  description: string;
  phoneNumber: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  contactPersonName: string;
  accountName: string;
  payMargin: number;
  password: string;
  regionId: string;
}

interface Props {
  onCreationSuccess?: () => void;
}

interface FormState {
  requesterInfo: RequesterInfo; // Field values
  payMarginTemp: string; //In-between field value for pay margin
  submitSuccess?: boolean; // Checks whether the form has been successfully submitted or not
  submitError?: string;
  loading: boolean;
  regions?: GetResponseBody;
  alertVisible: boolean;
  alertMessage: string;
  pressableSubmit: boolean;
}

const MAX_REQUESTERIDENTIFIER_LENGTH = 11;
const MAX_NAMELENGTH = 80;
const regex_WHITESPACE = /^\S*$/;
const regex_USERNAME =
  "^(?=[a-zA-ZäöåÄÖÅ0-9._]{5,40}$)(?!.*[_.]{2})[^_.].*[^_.]$";
const regex_PASSWORD =
  "(?!^[0-9]*$)(?!^[a-zA-Z!@#$%^&*()_+=<>?]*$)^([a-zA-Z!@#$%^&*()_+=<>?0-9]{8,})$";
const regex_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class RequesterCreatorForm extends React.Component<Props, FormState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      requesterInfo: this.getDefaultRequesterInfo(),
      payMarginTemp: "0",
      alertVisible: false,
      alertMessage: "",
      pressableSubmit: false,
    };
  }

  getDefaultRequesterInfo = () => {
    return {
      name: "",
      requesterIdentifier: "",
      description: "",
      phoneNumber: "",
      address: "",
      city: "",
      zip: "",
      email: "",
      contactPersonName: "",
      accountName: "",
      payMargin: 0,
      password: "",
      regionId: "",
    };
  };

  async componentDidMount() {
    const regionPromise = API.Instance().region.get();

    const regionsRes = await regionPromise;

    if (regionsRes.ok) {
      const regionsData = await regionsRes.json();
      this.setState({ regions: regionsData });
    } else {
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("error_noNetwork"),
        pressableSubmit: true,
      });
    }
  }

  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    let submitSuccess = false;
    let submitError: string | undefined = undefined;
    try {
      submitSuccess = await this.submitForm();
    } catch {
      submitSuccess = false;
      submitError = i18n.t("adminContentReqCreatorForm_somethingWentWrong");
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("adminContentReqCreatorForm_somethingWentWrong"),
        pressableSubmit: true,
      });
    }
    if (submitSuccess) {
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("adminContentReqCreatorForm_newRequester"),
      });
    }
    this.setState({
      submitSuccess,
      submitError,
      loading: false,
    });
  };

  private async submitForm() {
    const requesterInfo = this.state.requesterInfo;

    const body: PostRequestBody = {
      name: requesterInfo.name,
      requesterIdentifier: requesterInfo.requesterIdentifier,
      description: requesterInfo.description,
      address: requesterInfo.address,
      accountName: requesterInfo.accountName,
      payMargin: requesterInfo.payMargin / 100.0,
      password: requesterInfo.password,
      region: requesterInfo.regionId,
      city: requesterInfo.city,
      zip: requesterInfo.zip,
      email: requesterInfo.email,
      contactPersonName: requesterInfo.contactPersonName,
      phoneNumber: requesterInfo.phoneNumber,
    };
    var res: any;
    try {
      res = await API.Instance().requester.post(body);
    } catch (err) {
      err.throw();
    }
    if (!res.ok) {
      const err = (await res.json()) as any;
      console.log(err);
      if (err.name && err.name === "UNIQUE_VIOLATION") {
        if (err.message.indexOf("accountName") !== -1) {
          this.setState({
            alertVisible: true,
            alertMessage: i18n.t(
              "adminContentReqCreatorForm_usernameAlreadyExists"
            ),
          });
        } else if (err.message.indexOf("requesterIdentifier") !== -1) {
          this.setState({
            alertVisible: true,
            alertMessage: i18n.t(
              "adminContentReqCreatorForm_organisationNrAlreadyExists"
            ),
          });
        } else if (err.message.indexOf("email") !== -1) {
          this.setState({
            alertVisible: true,
            alertMessage: i18n.t(
              "adminContentReqCreatorForm_emailAlreadyExists"
            ),
          })
        }
      }
    }
    return res.ok;
  }

  updateInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const field: string = e.target.name;
    const val: string = e.target.value;

    this.setState(({ requesterInfo }) => ({
      requesterInfo: Object.assign({}, requesterInfo, { [field]: val }),
    }));
  };

  updatePayMargin = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string = e.target.value;
    let valNum = Number.parseInt(val);
    if (Number.isNaN(valNum)) {
      valNum = 0;
    }

    this.setState(({ requesterInfo }) => ({
      requesterInfo: Object.assign({}, requesterInfo, { payMargin: valNum }),
      payMarginTemp: val,
    }));
  };

  handleChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const field = e.target.name;
    this.setState(({ requesterInfo }) => ({
      requesterInfo: Object.assign({}, requesterInfo, { [field]: checked }),
    }));
  };

  handleOnNewClick = () => {
    this.setState(() => ({
      submitSuccess: undefined,
      requesterInfo: this.getDefaultRequesterInfo(),
    }));
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
    if (this.state.submitSuccess) {
      this.props.history.goBack();
    }
  };

  removeValidity = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity("");
  };

  setValidity = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity(
      i18n.t("adminContentReqCreatorForm_validityText")
    );
  };

  setValidityRegion = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity(
      i18n.t("adminContentReqCreatorForm_validityRegion")
    );
  };

  setValidityPayMargin = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity(
      i18n.t("adminContentReqCreatorForm_validityPayMargin")
    );
  };

  setValidityUserName = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity(
      !this.state.requesterInfo.accountName.match(regex_WHITESPACE)
        ? i18n.t("adminContentReqCreatorForm_usernamehelp")
        : i18n.t("adminContentReqCreatorForm_validityUsername")
    );
  };

  setValidityPassword = (event: React.FormEvent<HTMLDivElement>) => {
    event.target.setCustomValidity(
      !this.state.requesterInfo.password.match(regex_WHITESPACE)
        ? i18n.t("adminContentReqCreatorForm_passwordFieldhelp")
        : i18n.t("adminContentReqCreatorForm_validityPasswordHelper")
    );
  };

  public render() {
    const {
      submitSuccess,
      submitError,
      loading,
      payMarginTemp,
      pressableSubmit,
    } = this.state;
    const {
      name,
      requesterIdentifier,
      description,
      phoneNumber,
      address,
      city,
      zip,
      email,
      contactPersonName,
      accountName,
      password,
      regionId,
    } = this.state.requesterInfo;

    return (
      <Paper
        style={{ border: "1px solid #BDBDBD", padding: "1.5em" }}
        elevation={0}
        square={false}
      >
        <form
          onSubmit={(e) => this.handleSubmit(e)}
          method="POST"
          className={styles.form}
        >
          <TypographyTitle
            text={i18n.t("adminContentReqCreatorForm_createNew")}
          />

          <TextFieldBarlow
            type="text"
            name="name"
            label={i18n.t("adminContentReqCreatorForm_nameField")}
            onChange={this.updateInput}
            value={name}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_nameFieldExample")
            }
            error={name.length > MAX_NAMELENGTH}
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="requesterIdentifier"
            label={i18n.t("adminContentReqCreatorForm_idField")}
            onChange={this.updateInput}
            value={requesterIdentifier}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_idFieldExample")
            }
            error={requesterIdentifier.length > MAX_REQUESTERIDENTIFIER_LENGTH}
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="description"
            label={i18n.t("adminContentReqCreatorForm_description")}
            onChange={this.updateInput}
            value={description}
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_descriptionExample")
            }
          />
          <TextFieldBarlow
            type="tel"
            name="phoneNumber"
            label={i18n.t("adminContentReqCreatorForm_phoneNumber")}
            onChange={this.updateInput}
            value={phoneNumber}
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_phoneNumberExample")
            }
          />
          <TextFieldBarlow
            type="email"
            name="email"
            label={i18n.t("adminContentReqCreatorForm_emailField")}
            onChange={this.updateInput}
            value={email}
            required
            error={!email.match(regex_EMAIL) && email.length > 5}
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_emailFieldExample")
            }
            helperText={
              !email.match(regex_EMAIL) && email.length > 5
                ? i18n.t("adminContentReqCreatorForm_emailFieldhelp")
                : ""
            }
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="contactPersonName"
            label={i18n.t("adminContentReqCreatorForm_contactPersonNameHelp")}
            onChange={this.updateInput}
            value={contactPersonName}
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_contactPersonNameHelpExample")
            }
          />
          <TextFieldBarlow
            type="text"
            name="address"
            label={i18n.t("adminContentReqCreatorForm_addressField")}
            onChange={this.updateInput}
            value={address}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_addressFieldExample")
            }
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="city"
            label={i18n.t("adminContentReqCreatorForm_cityField")}
            onChange={this.updateInput}
            value={city}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_cityFieldExample")
            }
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="number"
            name="zip"
            label={i18n.t("adminContentReqCreatorForm_zipField")}
            onChange={this.updateInput}
            value={zip}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_zipFieldExample")
            }
            onInvalid={this.setValidity}
            onInput={this.removeValidity}
          />
          <InputLabel htmlFor="regionId" className={`${styles.inputLabel}`}>
            {i18n.t("requesterTable.heading.region")}
          </InputLabel>
          <Select
            inputProps={{
              name: "regionId",
              id: "regionId",
            }}
            onChange={this.updateInput}
            value={regionId}
            required
            native
            onInvalid={this.setValidityRegion}
            onClick={this.removeValidity}
          >
            <option value="" disabled>
              {i18n.t("requesterTable.heading.regionNotChosen")}
            </option>
            {this.state.regions &&
              this.state.regions.regions.map((r) => (
                <option key={r.id} value={r.id}>
                  {i18n.t(r.name)}
                </option>
              ))}
          </Select>
          <TextFieldBarlow
            type="number"
            name="payMargin"
            label={i18n.t("adminContentReqCreatorForm_payMarginField")}
            onChange={this.updatePayMargin}
            value={payMarginTemp}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_payMarginFieldExample")
            }
            helperText={i18n.t("adminContentReqCreatorForm_payMarginHelp")}
            error={Number.isNaN(Number.parseInt(payMarginTemp))}
            inputProps={{
              min: "0",
              max: "100",
            }}
            onInvalid={this.setValidityPayMargin}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="accountName"
            label={i18n.t("adminContentReqCreatorForm_usernameField")}
            onChange={this.updateInput}
            value={accountName}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_usernameExample")
            }
            helperText={
              accountName.match(regex_WHITESPACE)
                ? i18n.t("adminContentReqCreatorForm_atLeast5")
                : i18n.t("adminContentReqCreatorForm_usernamehelp")
            }
            error={!accountName.match(regex_WHITESPACE)}
            inputProps={{
              pattern: regex_USERNAME,
              title: !accountName.match(regex_WHITESPACE)
                ? i18n.t("adminContentReqCreatorForm_usernamehelp")
                : i18n.t("adminContentReqCreatorForm_validityUsernameHelper"),
            }}
            onInvalid={this.setValidityUserName}
            onInput={this.removeValidity}
          />
          <TextFieldBarlow
            type="text"
            name="password"
            label={i18n.t("adminContentReqCreatorForm_passwordField")}
            onChange={this.updateInput}
            value={password}
            required
            placeholder={
              i18n.t("adminContentReqCreatorForm_forExample") +
              " " +
              i18n.t("adminContentReqCreatorForm_passwordFieldExample")
            }
            helperText={
              !password.match(regex_WHITESPACE)
                ? i18n.t("adminContentReqCreatorForm_passwordFieldhelp")
                : i18n.t("adminContentReqCreatorForm_validityPasswordHelper")
            }
            error={
              !password.match(new RegExp(regex_PASSWORD)) && password.length > 0
            }
            inputProps={{
              pattern: regex_PASSWORD,
              title: i18n.t(
                "adminContentReqCreatorForm_validityPasswordHelper"
              ),
            }}
            onInvalid={this.setValidityPassword}
            onInput={this.removeValidity}
          />

          <div style={{ display: "flex", marginTop: Theme.mediumSpacing }}>
            <ButtonWhite
              title={i18n.t("adminContentReqCreatorForm_cancel")}
              onClick={() => this.props.history.goBack()}
            />
            <Button
              type="submit"
              color="primary"
              variant={loading ? "text" : "contained"}
              style={{ marginLeft: "auto" }}
              disabled={loading || pressableSubmit}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                i18n.t("adminContentReqCreatorForm_submit")
              )}
            </Button>
          </div>

          {submitError && (
            <Paper classes={{ root: styles.errorBox }}>{submitError}</Paper>
          )}
        </form>
        <Alert
          message={this.state.alertMessage}
          visible={this.state.alertVisible}
          closeOverlay={this.closeOverlay}
        ></Alert>
      </Paper>
    );
  }
}

export default RequesterCreatorForm;
