import * as React from "react";
import styles from "./PrivacyContent.css"
import TypographyRegular from "../Typography/TypographyRegularChildren"

class PrivacyContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <TypographyRegular>Dataskyddspolicy Vårdkällan (KGT Care AB 559116-1459)</TypographyRegular>
            <ol>
              <li><TypographyRegular>Generellt</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Denna dataskyddspolicy innehåller information om hur KGT Care AB, organisationsnummer 559116-1459, under namnet Vårdkällan, behandlar personuppgifter. Vårdkällan är personuppgiftsansvariga för den personuppgiftsbehandling som sker i enlighet med denna dataskyddspolicy.</TypographyRegular> </li>
                <li><TypographyRegular>Vårdkällan tillhandahåller en app vid namn Vårdkällan med en tillhörande webbplats (www.vårdkällan.se), även kallad för ”Tjänsten”. I appen kan personer söka och ansöka om att utföra vårdrelaterade uppdrag som konsulter från Vårdkällan hos den vårdgivare som har valt att utlysa uppdraget via webbplatsen designerad för vårdgivare (www.vårdkällan.se).</TypographyRegular> </li>
                <li><TypographyRegular>Vårdkällan kommer enbart samla in och behandla personuppgifter i enlighet med personuppgiftslagen (1998:204) eller dataskyddsförordningen (EU 2016/679), allmänt känt som GDPR, som gäller vid tidpunkten för behandlingen. Denna policy beskriver även tjänstutövarens rättigheter och möjligheter att begära utdrag över behandlade uppgifter.</TypographyRegular> </li>
                <li><TypographyRegular>Denna dataskyddspolicy gäller inte för personuppgiftsbehandling i de fall Vårdkällan agerar personuppgiftbiträde åt vårdgivare. För frågor gällande personuppgiftshantering som Vårdkällan utför som biträden, vänligen kontakta oss på kontakt@vårdkällan.se.</TypographyRegular> </li>
                <li><TypographyRegular>Den senaste versionen av denna policy finns tillgänglig digital i Tjänsten.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Insamling av personuppgifter</TypographyRegular></li>
              <ol>
                <li><TypographyRegular>Vårdkällan behandlar följande personuppgifter som en användare lämnar till Vårdkällan.</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Personuppgifter som gäller alla användare av Tjänsten.</TypographyRegular></li>
                  <ol>
                    <li><TypographyRegular>Namn</TypographyRegular></li>
                    <li><TypographyRegular>E-post adress</TypographyRegular></li>
                    <li><TypographyRegular>Telefonnummer</TypographyRegular></li>
                  </ol>
                  <li><TypographyRegular>Uppgifter som gäller för vårdpersonal som vill söka uppdrag (konsulter) i  Tjänsten.</TypographyRegular></li>
                  <ol>
                    <li><TypographyRegular>Personnummer</TypographyRegular></li>
                    <li><TypographyRegular>Foto</TypographyRegular></li>
                    <li><TypographyRegular>Adress</TypographyRegular></li>
                    <li><TypographyRegular>Personlig beskrivning</TypographyRegular></li>
                  </ol>
                  <li><TypographyRegular>Ytterligare uppgifter av de konsulter som tilldelats uppdrag och därför anställs av Vårdkällan.</TypographyRegular></li>
                  <ol>
                    <li><TypographyRegular>Bankuppgifter för utbetalning av lön</TypographyRegular></li>
                    <li><TypographyRegular>HSA-id</TypographyRegular></li>
                  </ol>
                  <li><TypographyRegular>Vid kontakt med Vårdkällan via e-mail, telefon, sociala medier, annan väg eller via Tjänsten, samlar Vårdkällan in namn, telefonnummer, namnet på  kontot i sociala medier och e-postadress, beroende på hur Vårdkällan har blivit kontaktad, samt andra personuppgifter som krävs för att kunna hjälpa den som kontaktar Vårdkällan.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Vid användning av  Tjänsten samlar Vårdkällan in ytterligare personuppgifter:</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Vid uppdatering av uppgifter på användarens personliga sidor i appen/hemsidan</TypographyRegular></li>
                </ol>
              </ol>

              <li><TypographyRegular>Hur Vårdkällan behandlar personuppgifter. Vårdkällan behandlar personuppgifter för att tillhandahålla och möjliggöra förbättring av Tjänsten samt uppfylla vårt åtagande gentemot dig som användare. Nedan följer en specificering av hur Vårdkällan använder personuppgifter:</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att tillhandahålla tjänsten</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Ett år efter det att användaren slutat använda Tjänsten</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer. När applicerbart även personnummer, foto, adress, personlig beskrivning, bankuppgifter, HSA-id</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna administrera vårdrelaterade uppdrag</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Tre år efter avslutat uppdrag och slutgiltig reglering</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer. </TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna skapa och tillhandahålla ett användarkonto till varje enskild användare av Tjänsten, med personlig information samt föra register över fullföljda, pågående och kommande uppdrag som konsult för Vårdkällan.</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Ett år efter att användaren sagt upp eller avslutat sitt konto.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer. När applicerbart även personnummer, foto, adress, personlig beskrivning, bankuppgifter, HSA-id</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna administrera anställningar</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Tre år efter anställningen avslutats.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: De uppgivna i punkt 2a, IV</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna erbjuda support och kundtjänst</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Ett år avslutat support ärende.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer. </TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att skicka information om Tjänsten, uppdrag och nyhetsbrev.</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Användaren har närsomhelst möjlighet att tacka nej till sådan information.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: Andra berättigade intressen för Vårdkällan</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, personnummer.</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna kontrollera legitimation i Socialstyrelsens register över legitimerad hälso- och sjukvårdspersonal (HoSp), samt kontroll om ev. ärenden hos Inspektionen för vård och omsorg (IVO). </TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Ett år efter avslutat användarkonto.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna fullgöra Vårdkällans kontraktuella åtaganden</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: De kategorier som anges i 2b, ii</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att innehåll skall presenteras effektivt för användaren av Tjänsten. </TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Så länge aktuell session pågår.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: Andra berättigade intressen för Vårdkällan</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: De kategorier som anges i 2b, ii</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att förbättra Tjänsten och utveckling av affärsmodell.</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Tre år från insamling.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: Andra berättigade intressen för Vårdkällan</TypographyRegular></li>
                </ol>
                <li><TypographyRegular> </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: De kategorier som anges i 2b, ii</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För statistiska ändamål som marknadsundersökningar och analyser</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: Ett år från insamling.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: Andra berättigade intressen för Vårdkällan</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>k </TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Kategori: Namn, epost, telefonnummer, personnummer, adress, bankuppgifter</TypographyRegular></li>
                  <li><TypographyRegular>Syfte: För att Vårdkällan skall kunna följa lagstiftning rörande bokföring och arbetsrätt.</TypographyRegular></li>
                  <li><TypographyRegular>Lagringstid: I enlighet med svenska bokföringsregler, vilket innebär 3 eller 7 år beroende på uppgiftstyp.</TypographyRegular></li>
                  <li><TypographyRegular>Laglig grund: För att kunna följa rådande lagstiftning</TypographyRegular></li>
                </ol>
              </ol>

              <li><TypographyRegular>Utlämmning av personuppgifter</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Vid ansökan om att få utföra ett uppdrag i Tjänsten (som konsult), kommer Vårdkällan att dela användarens personuppgifter med den Vårdgivaren. Vårdkällan delar följande personuppgifter med den Vårdgivare som användaren ansöker om att få utföra ett uppdrag åt:</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Namn</TypographyRegular></li>
                  <li><TypographyRegular>Foto</TypographyRegular></li>
                  <li><TypographyRegular>Personlig beskrivning</TypographyRegular></li>
                  <li><TypographyRegular>Resultat från utförd kontroll hos IVO</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Vårdkällan kan komma att överföra eller dela personuppgifter till valda samarbetspartner som behandlar data på Vårdkällans vägnar, för att Vårdkällan skall kunna tillhandahålla Tjänsten och administrera avtalen med användare. Vårdkällan använder följande underleverantörer eller kategorier av underleverantörer</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Lagringstjänst, tillhandahåller lagringstjänst.</TypographyRegular></li>
                  <li><TypographyRegular>Bokföringstjänst, tillhandahåller bokföringstjänster.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Om användaren vill söka uppdrag i Tjänsten, kommer Vårdkällan att dela användarens personnummer med Socialstyrelsen och Inspektionen för vård och omsorg för att utföra register kontroller som ovan sagt.</TypographyRegular> </li>
                <li><TypographyRegular>Vårdkällan lämnar ut nödvändiga personuppgifter till andra myndigheter (ex. Skatteverket) om Vårdkällan är skyldig till detta enligt lag.</TypographyRegular> </li>
                <li><TypographyRegular>Om Vårdkällan säljer eller köper bolag eller tillgångar kan Vårdkällan lämna ut personuppgifter till den ev. säljaren/köparen av sådan verksamhet. Om Vårdkällan förvärvas av tredje part, kan personuppgifter komma att lämnas ut till sådan tredje part.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Var vi behandlar personuppgifter</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Vårdkällan behandlar och lagrar alla personuppgifter inom EU/EES</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Rättigheter för användare</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Rätt till registerutdrag</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Användare av Tjänsten har rätt att en gång per kalenderår utan kostnad ta del av ett registerutdrag över lagrade och behandlade personuppgifter. Begäran om detta måste skickas skriftligt till oss med underskrift.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Rätt till rättelse</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Du har rätt korrigera felaktig eller inkomplett information om dig själv.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Dataportabilitet</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>När det gäller personuppgifter som du har lämnat till oss har du rätt att begära utlämning av dessa. Kontakta oss på support@vårdkällan om du vill detta.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Avaktivering och radering av användarkontot (Rätten att bli bortglömd).</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Du har rätt att motsätta dig vår behandling av dina personuppgifter. Konsekvensen är trolig att Tjänsten då inte kan brukas. Om du vill radera ditt användarkonto, vänligen kontakta oss på support@vårdkällan.se.</TypographyRegular></li>
                </ol>
                <li><TypographyRegular>Marknadsföringskommunikation</TypographyRegular> </li>
                <ol>
                  <li><TypographyRegular>Du kan när som helst avstå från att ta emot marknadsföringsinformation. Alternativ för detta kommer att finnas i utskickat material. Användare är välkomna att kontakta Vårdkällan för hjälp med att avstå marknadsföringsinformation på support@vårdkällan.se.</TypographyRegular></li>
                </ol>
              </ol>

              <li><TypographyRegular>Säkerhet</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Vårdkällan tar seriöst på säkerhet. Vi har adekvata tekniska och organisatoriska säkerhetsåtgärder för att säkerställa att personuppgifter inte kan missbrukas, förloras eller förfogas över av obehöriga.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Ändringar i denna dataskyddspolicy</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Vårdkällan förbehåller sig rätten att göra ändringar i denna dataskyddspolicy.</TypographyRegular> </li>
                <li><TypographyRegular>Alla ändringar i denna dataskyddspolicy kommer att finnas tillgängliga i Tjänsten.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Kontakt</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Vårdkällan kan alltid kontakts på support@vårdkällan.se gällande frågor i detta avtal.</TypographyRegular> </li>
                <li><TypographyRegular>Är du missnöjd med hur vi behandlar personuppgifter kan du vända dig direkt till oss, support@vårdkällan.se, eller till Datainspektionen, www.datainspektionen.se.</TypographyRegular> </li>
              </ol>

              <li><TypographyRegular>Datum</TypographyRegular> </li>
              <ol>
                <li><TypographyRegular>Denna dataskyddspolicy gäller från och med 2019-01-01 och tills den ersätts av en uppdaterad version.</TypographyRegular> </li>
              </ol>
            </ol>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyContent;
