//Gets all the english translation data
const getEnglishTranslateData = () => {
  return ({
    //MenuBar.tsx
    menubar_Home: "Home",
    menubar_About: "About",
    menubar_Responder: "Responder",
    menubar_Requester: "Requester",

    //HomeTopContent.tsx
    homeTopContent_RegisterInterest: "Register interest here",

    //ResponderContent.tsx
    responderContent_forMedicalPers: "For medical personnel",
    responderContent_howItWorks: "How does Vårdkällan work?",
    responderContent_registerInterest: "Register your interest here",
    responderContent_step: "Step",

    //RegisterInterest/RegisterInterest.tsx
    registerInterest_thanksForSubmit: "Thanks for your submission!",
    registerInterest_beInTouch: "We'll be in touch as soon as Vårdkällan is available for download.",

    //RegisterInterest/TextContent.tsx
    registerInterest_textContent_excited: "Are you as excited as we are?",
    registerInterest_textContent_maintext: "Register your interest as medical personnel here and we'll contact you once we're ready to find you the perfect shifts!",

    //Login.tsx
    login_username: "Username",
    login_password: "Password",
    login_login: "Login",
    login_forgotPassword: "Forgot password?",

    //AdminResponder.tsx
    adminResponder_detailedInfo: "Detailed Information",

    //Admin.tsx
    admin_interestedPeople: "Interested People",

    //AdminContent/ResponderTable
    adminContentResponderTable_name: "Name",
    adminContentResponderTable_email: "E-mail",

    //AdminContent/RegisteredPerson
    adminContentRegisteredPerson_info: "INFORMATION",
    adminContentRegisteredPerson_name: "Name",
    adminContentRegisteredPerson_personalNumber: "Personal Number",
    adminContentRegisteredPerson_email: "E-Mail",
    adminContentRegisteredPerson_phoneNumber: "Phone Number",
    adminContentRegisteredPerson_presentation: "Presentation",
    adminContentRegisteredPerson_bankInfo: "BANK INFORMATION",
    adminContentRegisteredPerson_bankName: "Bank name",
    adminContentRegisteredPerson_bankAccountNumber: "Bank account number",
    adminContentRegisteredPerson_clearingNumber: "Clearing number",
    adminContentRegisteredPerson_address: "Address",
    adminContentRegisteredPerson_cityArea: "City/area",
    adminContentRegisteredPerson_zipcode: "ZIP Code",
    adminContentRegisteredPerson_experienceCategory: "EXPERIENCE",
    adminContentRegisteredPerson_skills: "Skills",
    adminContentRegisteredPerson_experience: "Experience",
    adminContentRegisteredPerson_otherInfo: "OTHER INFORMATION",
    adminContentRegisteredPerson_eServiceCard: "e-Service Card",
    adminContentRegisteredPerson_driversLicense: "Driver's License",
    adminContentRegisteredPerson_hsaId: "HSA-ID",
    adminContentRegisteredPerson_sithsExpiryDate: "SITHS expiry date",

    //AdminContent/RequesterCreatorForm
    adminContentReqCreatorForm_somethingWentWrong: "Something went wrong, please try later.",
    adminContentReqCreatorForm_forExample: "E.g.",
    adminContentReqCreatorForm_newRequester: "A new requester has been added!",
    adminContentReqCreatorForm_updatePage: "Update the page to add a new requester (this is a temporary limitation while in development)",
    adminContentReqCreatorForm_addNewRequesterHere: "Add a new requester here:",
    adminContentReqCreatorForm_nameField: "Name",
    adminContentReqCreatorForm_idField: "Organization-ID",
    adminContentReqCreatorForm_addressField: "Address",
    adminContentReqCreatorForm_usernameField: "Username",
    adminContentReqCreatorForm_usernameExample: "Username_1",
    adminContentReqCreatorForm_passwordField: "Password",
    adminContentReqCreatorForm_submit: "Register requester",

    //Header.tsx
    header_home: "Get started",
    header_aboutUs: "About us",
    header_medicalPersonnel: "Medical personnel",
    header_medicalCentral: "Medical central",
    header_signOut: "Sign out",

    //Footer.tsx
    footer_contactUs: "Do you have questions? kontakt@vårdkällan.se",
    footer_developedAndMaintainedBy: "Developed and maintained by",

    yes: "Yes",
    no: "No",
  });
};

export default getEnglishTranslateData;