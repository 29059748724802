import * as React from "react";
import styles from "./ScreenContent.css"

interface Props {
  home?: boolean;
}
//This is the component used to contain all webpage content of a screen: it simply creates a div with appropriate flex properties
class ScreenContent extends React.Component<Props> {
  render() {
    return (
      <div className={this.props.home? styles.home : styles.content}>
        {this.props.children}
      </div>
    )
  }
}

export default ScreenContent;
