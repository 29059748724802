import { Endpoint } from "../../Endpoint";
import RequesterTimeReportEndpoint from "./timeReport/timeReport";

import API from "../../API";

const name = "/:id";

export interface Region {
  id: string;
  name: string;
}

export interface GetResponseBody {
  id: string;
  accountName: string;
  name: string;
  description: string;
  requesterIdentifier: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  contactPersonName: string;
  phoneNumber: string;
  region: Region | null;
}

export interface GetUrlParams {
  id: string;
}

export interface PutUrlParams {
  id: string;
}

export interface PutRequestBody {
  name?: string;
  description?: string;
  requesterIdentifier?: string;
  address?: string;
  phoneNumber?: string;
  region?: { id: string } | null;
  standardPayMargin?: number;
  zip?: string;
  city?: string;
  email?: string;
  contactPersonName?: string;
}

export type PutResponseBody = "OK";

export default class OneRequesterEndpoint extends Endpoint {
  timereport: RequesterTimeReportEndpoint;

  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
    this.timereport = new RequesterTimeReportEndpoint(api, this);
  }

  get(urlParams: GetUrlParams) {
    return this.sendGet<GetResponseBody>(urlParams);
  }

  put(body: PutRequestBody, urlParams: PutUrlParams) {
    return this.sendPut<PutResponseBody>(body, urlParams);
  }
}
