import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/resettoken";

export interface PostRequestBody {
  accountName: string;
}
export type PostResponseBody = "OK";

export default class ResetToken extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
}
