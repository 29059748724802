enum InvalidReason {
  characters = "characters",
  checksum = "checksum",
  numbers = "numbers",
  valid = "valid"
}
export { InvalidReason };

function calculateChecksum(personalNumber: string) {
  let sum = 0;
  for (let i = 2; i < 11; i++) {
    const multiple = ((i + 1) % 2) + 1;
    const value = multiple * Number(personalNumber[i]);
    String(value)
      .split("")
      .forEach(num => {
        sum += Number(num);
      });
  }
  return (10 - (sum % 10)) % 10;
}

export function validatePersonalNumber(personalNumber: string) {
  if (personalNumber.length !== 12) {
    return {
      valid: false,
      reason: InvalidReason.characters
    };
  }

  if (
    personalNumber
      .split("")
      .map(Number)
      .some(isNaN)
  ) {
    return {
      valid: false,
      reason: InvalidReason.numbers
    };
  }

  if (calculateChecksum(personalNumber) !== Number(personalNumber[11])) {
    return {
      valid: false,
      reason: InvalidReason.checksum
    };
  }

  return {
    valid: true,
    reason: InvalidReason.valid
  };
}

export function parsePersonalNumber(personalNumber: string) {
  // Remove all non digit characters, e.g. in 1990-01-01-0101 => 19900101010101
  let parsedNumber = personalNumber.replace(/\D/g, "");

  // They gave us 10 digit, convert to 12 digit. Assume born in 19 hundreds
  if (parsedNumber.length > 2) {
    const twoFirst = parsedNumber.substr(0, 2);
    if (twoFirst !== "19" && twoFirst !== "20") {
      parsedNumber = "19" + parsedNumber;
    }
  }

  return parsedNumber;
}
