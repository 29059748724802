import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface State {
  message: string;
  title?: string;
  type?: string;
  closeOverlay: () => void;
  //buttonMessage?: string[];
  visible: boolean;
}

export default class Alert extends React.Component<State> {
  state = {
    visible: false,
    message: "",
    title: "",
    type: "",
  };

  render() {
    const { message, visible, closeOverlay, type, title } = this.props;
    return (
      <div>
        <Dialog
          open={visible}
          onClose={closeOverlay}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeOverlay} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
