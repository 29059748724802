import { GetResponseBody } from "kgt-api/dist/Translation";
import API from "kgt-api";

// Gets any translations that may have changed or been added on the back-end server after the last time we updated our local translations
export const getNewTranslations = async (lang: string) => {
  const response = await API.Instance().translation.get();
  const body: GetResponseBody = await response.json();

  const filteredBody = body.translations.filter(
    translation => translation.language === lang
  );
  const returnValue: { [tag: string]: string } = {};
  filteredBody.forEach(translation => {
    returnValue[translation.tag] = translation.text;
  });

  return returnValue;
};
