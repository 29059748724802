import * as React from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { withRouter, RouteComponentProps } from "react-router";
import API from "kgt-api";
import { GetResponseBody } from "kgt-api/dist/Responder/_id";
import styles from "./RegisteredPerson.css";
import i18n from "i18next";
import TypographySubtitle from "../../Typography/TypographySubtitle";
import CellBold from "../../Table/CellBold";
import Cell from "../../Table/Cell";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import ButtonWarning from "../../Buttons/ButtonWarning";
import Theme from "../../../utils/Theme";
import { convertExperienceToText } from "kgt-api/dist/utils/experienceConverter";
import defaultProfilePic from "../../../images/default_profile.png";
import Alert from "../../Alert/Alert";
import Confirm from "../../Alert/Confirm";

interface Props extends RouteComponentProps<{ responderId: string }> {}
interface State {
  responder?: GetResponseBody;
  alertVisible: boolean;
  confirmVisable: boolean;
  alertMessage: string;
}

class RegisteredPerson extends React.Component<Props, State> {
  state: State = {
    alertVisible: false,
    confirmVisable: false,
    alertMessage: "",
  };

  async componentDidMount() {
    const response = await API.Instance().responder._id.get({
      id: this.props.match.params.responderId,
    });
    const responder = await response.json();
    this.setState({ responder });
  }

  onClick = async () => {
    const responder = this.state.responder;
    try {
      if (responder) {
        this.setState(({ responder }) => {
          const newResponder = Object.assign({}, responder, { verified: true });

          return { responder: newResponder };
        });
        const resp = await API.Instance().responder.put({
          id: responder.id,
          verified: true,
        });
        this.setState({
          alertVisible: true,
          alertMessage: i18n.t("adminContentRegisteredPerson_approved"),
        });
        return resp.ok;
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  onDeleteClick = () => {
    this.setState({
      confirmVisable: true,
      alertMessage: i18n.t("adminContentRegisteredPerson_areYouSure"),
    });
  };

  onDeleteButtonClick = async () => {
    const responder = this.state.responder;
    try {
      if (responder) {
        const resp = await API.Instance().deleteresponder.put({
          id: responder.id,
        });
        if (resp.ok) {
          this.props.history.push("/admin/responder");
        }
        return resp.ok;
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  translateBoolean = (bool: Boolean) => {
    if (bool) {
      return i18n.t("yes");
    }
    return i18n.t("no");
  };

  //Shorthand for converting experience from number to proper year-span format
  //  Uses the API solution but also adds the "Years" text at the end
  convertExperienceToFullText = (num: number) => {
    if (num <= 0) {
      return convertExperienceToText(num) + " " + i18n.t("profile.year");
    } else {
      return convertExperienceToText(num) + " " + i18n.t("profile.years");
    }
  };

  skillsToString(skills: Skill[]) {
    if (skills.length == 0) {
      return "-";
    }
    let skillsArray = new Array(skills.length);
    let i = 0;

    skills.map((value) => ((skillsArray[i] = i18n.t(value.tag)), i++));

    return skillsArray.join(", ");
  }

  setProfilePic = (url: string) => {
    if (url) {
      return url;
    } else {
      return defaultProfilePic;
    }
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  closeOverlayConfirm = (value: Boolean | undefined) => {
    this.setState({
      confirmVisable: false,
      alertMessage: "",
    });
    if (value !== undefined) {
      if (value) {
        this.onDeleteButtonClick();
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.responder && (
          <React.Fragment>
            <Table>
              <colgroup>
                <col style={{ width: "60%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: "none" }}>
                    <div style={{ marginBottom: Theme.largeSpacing }}>
                      <div style={{ marginBottom: Theme.smallSpacing }}>
                        <TypographySubtitle
                          text={i18n.t("adminContentRegisteredPerson_info")}
                        />
                      </div>
                      <Table>
                        <colgroup>
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "70%" }} />
                        </colgroup>
                        <TableBody>
                          <TableRow>
                            <CellBold
                              text={i18n.t("adminContentRegisteredPerson_name")}
                            />
                            <Cell text={this.state.responder.name} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_personalNumber"
                              )}
                            />
                            <Cell text={this.state.responder.personalNumber} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_email"
                              )}
                            />
                            <Cell text={this.state.responder.email} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_phoneNumber"
                              )}
                            />
                            <Cell text={this.state.responder.phone} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_presentation"
                              )}
                            />
                            <Cell text={this.state.responder.presentation} />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                    <div style={{ marginBottom: Theme.largeSpacing }}>
                      <div style={{ marginBottom: Theme.smallSpacing }}>
                        <TypographySubtitle
                          text={i18n.t("adminContentRegisteredPerson_bankInfo")}
                        />
                      </div>
                      <Table>
                        <colgroup>
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "70%" }} />
                        </colgroup>
                        <TableBody>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_bankName"
                              )}
                            />
                            <Cell text={this.state.responder.bankName} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_bankAccountNumber"
                              )}
                            />
                            <Cell
                              text={this.state.responder.bankAccountNumber}
                            />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_clearingNumber"
                              )}
                            />
                            <Cell text={this.state.responder.clearingNumber} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_address"
                              )}
                            />
                            <Cell text={this.state.responder.billingStreet} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_cityArea"
                              )}
                            />
                            <Cell text={this.state.responder.billingCity} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_zipcode"
                              )}
                            />
                            <Cell text={this.state.responder.billingZip} />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </TableCell>

                  <TableCell style={{ border: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: Theme.largeSpacing,
                      }}
                    >
                      <img
                        src={this.setProfilePic(
                          this.state.responder.profilePicture
                        )}
                        className={styles.rounded}
                      />
                    </div>
                    <div style={{ marginBottom: Theme.largeSpacing }}>
                      <div style={{ marginBottom: Theme.smallSpacing }}>
                        <TypographySubtitle
                          text={i18n.t(
                            "adminContentRegisteredPerson_experienceCategory"
                          )}
                        />
                      </div>
                      <Table>
                        <colgroup>
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "70%" }} />
                        </colgroup>
                        <TableBody>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_experience"
                              )}
                            />
                            <Cell
                              text={this.convertExperienceToFullText(
                                this.state.responder.experience
                              )}
                            />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_skills"
                              )}
                            />
                            <Cell
                              text={this.skillsToString(
                                this.state.responder.skills
                              )}
                            />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                    <div style={{ marginBottom: Theme.largeSpacing }}>
                      <div style={{ marginBottom: Theme.smallSpacing }}>
                        <TypographySubtitle
                          text={i18n.t(
                            "adminContentRegisteredPerson_otherInfo"
                          )}
                        />
                      </div>
                      <Table>
                        <colgroup>
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "70%" }} />
                        </colgroup>
                        <TableBody>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_eServiceCard"
                              )}
                            />
                            <Cell
                              text={this.translateBoolean(
                                this.state.responder.eServiceCard
                              )}
                            />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_driversLicense"
                              )}
                            />
                            <Cell
                              text={this.translateBoolean(
                                this.state.responder.driversLicence
                              )}
                            />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_hsaId"
                              )}
                            />
                            <Cell text={this.state.responder.hsaId} />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_sithsExpiryDate"
                              )}
                            />
                            <Cell
                              text={new Date(
                                this.state.responder.sithsExpiration
                              ).toLocaleDateString()}
                            />
                          </TableRow>
                          <TableRow>
                            <CellBold
                              text={i18n.t(
                                "adminContentRegisteredPerson_verifiedUser"
                              )}
                            />
                            <Cell
                              text={this.translateBoolean(
                                this.state.responder.verified
                              )}
                            />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <div style={{ display: "flex", marginBottom: Theme.smallSpacing }}>
              <ButtonWarning
                onClick={this.onDeleteClick}
                title={i18n.t("adminContentRegisteredPerson_deleteButton")}
              />
              <ButtonPrimary
                onClick={this.onClick}
                title={i18n.t("adminContentRegisteredPerson_approveUser")}
                disabled={this.state.responder.verified}
                marginLeft="auto"
              />
            </div>
            <Confirm
              message={this.state.alertMessage}
              visible={this.state.confirmVisable}
              closeOverlayConfirm={this.closeOverlayConfirm}
            ></Confirm>
            <Alert
              message={this.state.alertMessage}
              visible={this.state.alertVisible}
              closeOverlay={this.closeOverlay}
            ></Alert>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(RegisteredPerson);
