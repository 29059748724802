import React from "react";
import { Button } from "@material-ui/core";
import Theme from "../../utils/Theme"

interface ButtonWhiteProps {
    title: string;
    onClick: () => void;
    name?: string;
    disabled?: boolean;
    marginLeft?: any;
    marginRight?: any;
}

export default class ButtonWhite extends React.Component<ButtonWhiteProps> {
    setBackground = (disabled?: boolean) => {
        if (disabled) {
            return Theme.inactiveButtonColorWarning
        }
        return Theme.tertiaryColour
    }

    render() {
        return (
            <Button
                variant="outlined"
                style={{
                    color: "white",
                    background: this.setBackground(this.props.disabled),
                    border: "none",
                    textTransform: "none",
                    fontFamily: "Barlow",
                    marginLeft: this.props.marginLeft,
                    marginRight: this.props.marginRight,
                    borderRadius: Theme.buttonBorderRadius,
                }}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {this.props.title}
            </Button>
        );
    }
}