import { Endpoint } from "../Endpoint";
import API from "../API";

export const name = "/changepassword";

export interface ChangePasswordBody {
  accountName: string;
  newPassword: string;
}
export interface ForgotPasswordBody {
  resetToken: string;
  newPassword: string;
}
export type PostRequestBody = ChangePasswordBody | ForgotPasswordBody;

export type PostResponseBody = "OK";

export default class ChangePasswordEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }
}
