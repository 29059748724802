import { Endpoint } from "../Endpoint";
import API from "../API";
import OneRequesterEndpoint from "./_id";

const name = "/requester";

export interface Region {
  id: string;
}

interface PostRequestBody {
  name: string;
  requesterIdentifier: string;
  description: string;
  phoneNumber: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  contactPersonName: string;
  payMargin: number;
  accountName: string;
  password: string;
  region?: string | null;
}
interface PostResponseBody {
  id: string;
  name: string;
  requesterIdentifier: string;
  address: string;
}

interface PutRequestBody {
  id: string;
  name?: string;
  description?: string;
  requesterIdentifier?: string;
  address?: string;
  city?: string;
  zip?: string;
  email?: string;
  contactPersonName?: string;
  phoneNumber?: string;
  region?: Region | null;
  compensation?: number;
  accountName?: string;
}
type PutResponseBody = "OK";

interface GetResponseBody {
  requesters: Array<{
    id: string;
    name: string;
    address: string;
  }>;
}

export {
  name,
  PostRequestBody,
  PostResponseBody,
  PutRequestBody,
  PutResponseBody,
  GetResponseBody,
};

export default class Requester extends Endpoint {
  _id: OneRequesterEndpoint;

  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);

    this._id = new OneRequesterEndpoint(api, this);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }

  put(body: PutRequestBody) {
    return this.sendPut<PutResponseBody>(body);
  }
}
