import * as React from "react";
import RegisteredPerson from "../../../components/AdminContent/RegisteredPerson";
import Paper from "@material-ui/core/Paper";

function AdminOneResponder() {
  return (
    <Paper
      style={{ border: "1px solid #BDBDBD", padding: "1.5em"}}
      elevation={0}
      square={false}>
      <RegisteredPerson />
    </Paper>
  );
}

export default AdminOneResponder;
