import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import API from "kgt-api";
import {
  GetResponseBody as TimeReports,
  TimeReportStatus,
} from "kgt-api/dist/TimeReport";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Theme from "../../utils/Theme";
import Alert from "../Alert/Alert";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import CellBold from "../Table/CellBold";
import TypographyTitle from "../Typography/TypographyTitle";
import styles from "./RequesterList.css";

interface Props extends RouteComponentProps {}
interface Props {
  requesters: Array<{
    id: string;
    name: string;
    address: string;
  }>;
  onRequesterClick?: (id: string) => void;
}

const filterDeletedRows = (
  requesters: { id: string; name: string; address: string }[]
) => {
  return requesters.filter((r) => r.name !== "DELETED");
};

const RequesterList: React.SFC<Props> = (props) => {
  const { t } = useTranslation();
  let fileName = "default_time_reports";

  const [isVisable, setVisableAlert] = React.useState(false);
  const [alertMessage, setMessage] = React.useState("");
  const [requesterList, setRequesterList] = React.useState(
    filterDeletedRows(props.requesters)
  );

  const handleOnExportButtonClick = async (requesterId: string) => {
    const res = await API.Instance().requester._id.timereport.get({
      id: requesterId,
    });

    if (res.ok) {
      const data = await res.json();
      if (data.length > 0) {
        let csvData = handleFormattingForCSV(
          handleSeparatingApprovedReports(data),
          requesterId
        );

        handleExportCSV(csvData);
      } else {
        setVisableAlert(true);
        setMessage(t("requesterList_none_to_export"));
      }
    }
  };

  const handleSeparatingApprovedReports = (timeReports: TimeReports) => {
    let approvedTimeReports: TimeReports = [];

    // Move all approved time reports into a separate array
    for (let i = 0; i < timeReports.length; i++) {
      let timeReport = timeReports[i];
      if (timeReport.status === TimeReportStatus.approved) {
        timeReports.splice(i, 1);
        approvedTimeReports.push(timeReport);
        i--;
      }
    }
    return approvedTimeReports;
  };

  // Make sure not to auto-format the formatted string (e.g. Prettier adds linebreaks)
  const handleFormattingForCSV = (
    timeReports: TimeReports,
    requesterId: string
  ) => {
    let basic: string = `Default Header,
    Default Value
    `;

    const requester = props.requesters.find((req) => req.id === requesterId);

    if (
      timeReports !== undefined &&
      timeReports !== null &&
      timeReports.length > 0 &&
      requester !== undefined
    ) {
      let formattedData = "";

      for (let k = 0; k < timeReports.length; k++) {
        let timeReportData = `
          ${requester.name}, ${timeReports[k].request.title}, ${
          timeReports[k].responder.name
        }, ${timeReports[k].request.compensation}, ${
          timeReports[k].request.payMargin
        }, ${new Date(
          timeReports[k].request.startTime
        ).toLocaleDateString()}, ${timeReports[k].request.duration}, ${
          timeReports[k].hoursWorked
        }`;

        formattedData = formattedData + timeReportData;
      }

      fileName = `${requester.name}_${new Date()
        .toISOString()
        .slice(0, 10)}.csv`; /* All time reports share the same requester */

      return `Vårdcentral, Passtitel, Namn på Arbetstagare, Timpris (kr/h), Betalmarginal, Starttid Pass, Planerad Längd Pass (h), Godkänd Rapporterad Tid (h),${formattedData},
      `;
    }
    console.log("The time reports could not be read for CSV-formatting");
    return basic;
  };

  const handleExportCSV = (data: string) => {
    var encodedUri = encodeURI("data:text/csv;charset=utf-8," + data);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  function handleOnClick(id: string) {
    if (props.onRequesterClick) {
      props.onRequesterClick(id);
    }
  }

  function handleOnButtonClick() {
    props.history.push(`${props.match.path}/create`);
  }

  function closeOverlay() {
    setVisableAlert(false);
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <TypographyTitle text={t("admin.requester.title")} />
        <ButtonPrimary
          title={t("admin.requester.create.heading")}
          onClick={() => handleOnButtonClick()}
          marginLeft="auto"
        />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <CellBold text={t("requesterList.head.name")} />
            <CellBold text={t("requesterList.head.address")} />
            <CellBold text={t("requesterList.head.time_reports")} />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {requesterList.map((req) => (
            <TableRow
              key={req.id}
              onClick={() => handleOnClick(req.id)}
              hover
              className={styles.row}
            >
              <TableCell>{req.name}</TableCell>
              <TableCell>{req.address}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  style={{
                    color: Theme.primaryColour,
                    borderColor: Theme.borderColor,
                    textTransform: "none",
                    fontSize: "0.9em",
                    borderRadius: Theme.buttonBorderRadius,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnExportButtonClick(req.id);
                  }}
                >
                  {t("requesterList.export")}
                </Button>
              </TableCell>
              <TableCell>
                <ArrowRight />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Alert
        message={alertMessage}
        visible={isVisable}
        closeOverlay={closeOverlay}
      ></Alert>
    </React.Fragment>
  );
};

export default RequesterList;
