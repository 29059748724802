import DateFnsUtils from "@date-io/date-fns";
import {
  DateTimePickerProps,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import svLocale from "date-fns/locale/sv";
import * as React from "react";

const KeyboardADateTimePicker: React.FunctionComponent<DateTimePickerProps> = (
  props
) => {
  return (
    <>
      {
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={svLocale}>
          {
            <KeyboardDateTimePicker
              {...props}
              variant="inline"
              autoOk
              format={"yyyy-MM-dd HH:mm"}
              ampm={false}
            />
          }
        </MuiPickersUtilsProvider>
      }
    </>
  );
};

export default KeyboardADateTimePicker;
