import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

interface RequestTableRowProps {
    title: string;
    text: string;
}

export default class RequestTableRow extends React.Component<RequestTableRowProps> {
    render() {
        return (
            <TableRow>
                <TableCell component="th" style={{ padding: "1em" }}>
                    <Typography style={{ fontWeight: "bold" }} color="textSecondary" >
                        {this.props.title}
                    </Typography>{" "}
                </TableCell>
                <TableCell>
                    <Typography>
                        {this.props.text}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }
}