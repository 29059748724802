import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/deleterequester";

interface PutRequestBody {
  id: string;
  accountName?: string;
}

type PutResponseBody = "OK";

export { name, PutRequestBody, PutResponseBody };

export default class DeleteRequesterEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  put(body: PutRequestBody) {
    return this.sendPut<PutResponseBody>(body);
  }
}
