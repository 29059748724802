import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/skill";

type GetResponseBody = Array<{
  id: string;
  tag: string;
  type: string;
}>;

export { name, GetResponseBody };

export default class SkillEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }
}
