import Typography from "@material-ui/core/Typography";
import React from "react";
import Theme from "../../utils/Theme"

interface TypographyTitleProps {
    text: string;
    className?: string;
    marginLeft?: any;
}

export default class TypographyTitle extends React.Component<TypographyTitleProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                variant="h6"
                style={{
                    marginLeft: this.props.marginLeft,
                    color: Theme.primaryTextColour,
                }}
            >
                {this.props.text}
            </Typography>
        );
    }
}