import React from "react";
import Typography from "@material-ui/core/Typography";
import Theme from "../../utils/Theme"

interface TypographyErrorProps {
    text: string;
    className?: string;
    marginLeft?: any;
}

export default class TypographyError extends React.Component<TypographyErrorProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                style={{
                    marginLeft: this.props.marginLeft,
                    color: Theme.tertiaryColour
                }}>
                {this.props.text}
            </Typography>
        );
    }
}