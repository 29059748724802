import * as React from "react";
import AboutContent from "../components/AboutContent";
import ScreenContent from "../components/ScreenContent";

function About() {
  return (
    <ScreenContent>
      <AboutContent />
    </ScreenContent>
  );
}

export default About;
