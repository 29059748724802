import * as React from "react";
import { Route, withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import Session from "../../stores/Session";
import { DegreeOfAccess } from "kgt-api/dist/Login";
import Requester from "./Requester";
import RequestDashboard from "./RequesterDashboard";

type Props = RouteComponentProps;

interface InjectedProps extends Props {
  session: Session;
}

@inject("session")
@observer
class RequesterRoutes extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const { match } = this.props;
    const { session } = this.injected;

    const isLoggedIn =
      session.data && session.data.degreeOfAccess === DegreeOfAccess.Requester;
      
    if (isLoggedIn) {
      return <Route path={`${match.path}`} component={RequestDashboard} />;
    } else {
      return <Route path={`${match.path}`} component={Requester} />;
    }
  }
}

export default withRouter(RequesterRoutes);
