import i18next from "i18next";
import React from "react";
import Container from "../Container";
import TypographyTitleBigger from "../Typography/TypographyTitleBigger";
import i18n from "i18next";
import TypographySubtitle from "../Typography/TypographySubtitle";
import styles from "../NotFound/NotFound.css"


export default class ButtonWhite extends React.Component {
    render() {
        return (
            <Container className={styles.container}>
                <TypographyTitleBigger text={i18n.t("notFound_title")} />
                <TypographySubtitle text={i18n.t("notFound_text")} />
            </Container>
        );
    }
}