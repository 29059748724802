import * as React from "react";
import ScreenContent from "../components/ScreenContent";
import ForgotPasswordComponent from "../components/ForgotPassword";
import Container from "../components/Container";

function ForgotPassword() {
  return (
    <ScreenContent>
      <Container>
        <ForgotPasswordComponent />
      </Container>
    </ScreenContent>
  );
}

export default ForgotPassword;
