import { InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import API from "kgt-api";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import ButtonPrimary from "../components/Buttons/ButtonPrimary";
import Container from "../components/Container";
import Logo from "../components/Logo";
import ScreenContent from "../components/ScreenContent";
import SpinLoader from "../components/SpinLoader";
import TypographyError from "../components/Typography/TypographyError";
import TypographyRegular from "../components/Typography/TypographyRegular";
import TypographyTitle from "../components/Typography/TypographyTitle";
import TypographyTitleBigger from "../components/Typography/TypographyTitleBigger";
import styles from "./Reset.css";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

type Props = RouteComponentProps;

const ResetScreen: React.SFC<Props> = ({ match, location }) => {
  const { t } = useTranslation();
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const tokenResult = /token=([^\?]+)/.exec(location.search);
  const token = tokenResult ? tokenResult[1] : null;

  const resetPassword = React.useCallback(
    (e: React.FormEvent) => {
      async function resetPassword() {
        if (token) {
          setLoading(true);

          const res = await API.Instance().changepassword.post({
            resetToken: token,
            newPassword: password,
          });

          if (res.ok) {
            setSuccess(true);
          } else {
            if (res.status === 404) {
              setError(t("reset.error.missing") as string);
            } else {
              setError(t("reset.error.unknown") as string);
              console.log("Fel:" + res.status);
            }
          }

          setLoading(false);
        }
      }

      e.preventDefault();
      resetPassword();
    },
    [password, token]
  );
  if (token === null) {
    return (
      <ScreenContent>
        <Container>
          <div className={styles.container}>
            <ThemeProvider theme={theme}>
              <TypographyTitleBigger text={t("reset.heading")} />
            </ThemeProvider>
            <TypographyError text={t("reset.error.missing")} />
          </div>
        </Container>
      </ScreenContent>
    );
  } else {
    return (
      <ScreenContent>
        <Container>
          <div className={styles.container}>
            <ThemeProvider theme={theme}>
              <TypographyTitleBigger text={t("reset.heading")} />
              <form onSubmit={resetPassword} className={styles.form}>
                {success && (
                  <div className={styles.successContainer}>
                    <Link to="/">
                      <Logo />
                    </Link>
                    <div style={{ padding: "2em" }}>
                      <TypographyTitle text={t("reset.success")} />
                    </div>
                    <Link to="/login">
                      <ButtonPrimary title={t("reset.password.goBack")} />
                    </Link>
                  </div>
                )}
                <TextField
                  name="password"
                  variant="outlined"
                  label={t("reset.password.label")}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  style={{ backgroundColor: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityOff
                            color="primary"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <Visibility
                            color="primary"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ margin: "1em" }}>
                  <TypographyRegular text={t("reset.password.helper")} />
                </div>
                <div className={styles.buttonContainer}>
                  {password.length < 8 || loading ? (
                    <Button
                      variant={loading ? "outlined" : "contained"}
                      color="primary"
                      style={{
                        background: "#BCC2D9",
                        color: "white",
                        border: "none",
                        textTransform: "none",
                        fontFamily: "Barlow",
                        borderRadius: "10px",
                      }}
                      type="submit"
                      disabled={true}
                    >
                      {loading ? <SpinLoader /> : t("reset.button.reset")}
                    </Button>
                  ) : (
                    <Button
                      variant={loading ? "outlined" : "contained"}
                      color="primary"
                      style={{
                        background: "#6C6CFC",
                        color: "white",
                        border: "none",
                        textTransform: "none",
                        fontFamily: "Barlow",
                        borderRadius: "10px",
                      }}
                      type="submit"
                      disabled={false}
                    >
                      {loading ? <SpinLoader /> : t("reset.button.reset")}
                    </Button>
                  )}
                </div>
              </form>
            </ThemeProvider>
            {error && (
              <section>
                <TypographyError text={error} />
              </section>
            )}
          </div>
        </Container>
      </ScreenContent>
    );
  }
};

export default ResetScreen;
