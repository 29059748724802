import * as React from "react";
import ReactDOM from "react-dom";
import Webapp from "./Webapp";
import API from "kgt-api";
import initializeI18n from "./i18n";

const DEFAULT_API_IP = "https://api.vårdkällan.se";
if (process.env.API_IP) {
  API.Initialize(process.env.API_IP, fetch.bind(window));
} else {
  API.Initialize(DEFAULT_API_IP, fetch.bind(window));
}
initializeI18n();

ReactDOM.render(<Webapp />, document.getElementById("root"));
