import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import API from "kgt-api";
import { GetResponseBody as RequesterData } from "kgt-api/dist/Requester/_id";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Container from "../../components/Container";
import RequesterTable from "../../components/RequesterTable";
import SpinLoader from "../../components/SpinLoader";
import Session from "../../stores/Session";
import styles from "./RequesterInfo.css";
import Alert from "../../components/Alert/Alert";
import i18n from "i18next";

type Props = RouteComponentProps;
type InjectedProps = Props & { session: Session };

interface State {
  requester?: RequesterData;
  loading: boolean;
  alertVisible: boolean;
  alertMessage: string;
}

const MIN_PASSWORD_LENGTH: number = 8;

@inject("session")
@observer
class RequesterInfoScreen extends React.Component<Props, State> {
  state: State = {
    loading: true,
    alertVisible: false,
    alertMessage: "",
  };

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    if (this.injected.session.data === null) {
      return;
    }

    const res = await API.Instance().requester._id.get({
      id: this.injected.session.data.profileId,
    });

    if (res.ok) {
      this.setState({
        requester: await res.json(),
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
    if (this.state.requester == undefined) {
      this.setState({
        alertVisible: true,
        alertMessage: i18n.t("error_noNetwork"),
      });
    }
  }

  saveRequester = async (requester: RequesterData, password: string) => {
    var requesterToUpdate = {};

    // Optimistic state change yay!
    this.setState(
      {
        requester,
      },
      async () => {
        const res = await API.Instance().requester._id.put(requesterToUpdate, {
          id: requester.id,
        });

          if (res.ok)  {
            // All is well, update requester
            requesterToUpdate = {
              name: requester.name,
              description: requester.description,
              requesterIdentifier: requester.requesterIdentifier,
              address: requester.address,
              city: requester.city,
              zip: requester.zip,
              email: requester.email,
              contactPersonName: requester.contactPersonName,
              phoneNumber: requester.phoneNumber,
              region: requester.region ? { id: requester.region.id } : null,
            };
          } else if (res.status === 406) {
            this.setState({
              alertVisible: true,
              alertMessage: i18n.t("requester_editInfo_attempted_email_change"),
            });
          } else {
            this.setState({
              alertVisible: true,
              alertMessage: i18n.t("requester_editInfo_error"),
            });
          }
        });
      

    if (password !== "" && password.length >= MIN_PASSWORD_LENGTH) {
      const res = await API.Instance().changepassword.post({
        accountName: requester.accountName,
        newPassword: password,
      });
      if (!res.ok) {
        console.log(res.status);
      }
    }
  };

  closeOverlay = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
    });
  };

  render() {
    const { loading, requester, alertVisible, alertMessage } = this.state;
    return (
      <Container className={styles.container}>
        <Paper
          style={{
            border: "1px solid #BDBDBD",
            padding: "1.5em",
            marginBottom: "100px",
          }}
          elevation={0}
          square={false}
        >
          {loading && <SpinLoader />}
          {!loading && requester !== undefined && (
            <RequesterTable
              requester={requester}
              editable
              onEdited={this.saveRequester}
            />
          )}
          <Alert
            visible={alertVisible}
            message={alertMessage}
            closeOverlay={this.closeOverlay}
          ></Alert>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(RequesterInfoScreen);
