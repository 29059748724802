import React from "react";
import { Button } from "@material-ui/core";
import Theme from "../../utils/Theme";

interface ButtonPrimaryProps {
  title: any;
  onClick?: () => void;
  disabled?: boolean;
  marginLeft?: any;
  marginRight?: any;
  green?: boolean;
}

export default class ButtonPrimary extends React.Component<ButtonPrimaryProps> {
  setBackground = (disabled?: boolean) => {
    if (disabled) {
      return Theme.inactiveButtonColor;
    }
    if (this.props.green) {
      return Theme.secondaryColour;
    }
    return Theme.primaryColour;
  };

  render() {
    return (
      <Button
        variant="outlined"
        style={{
          background: this.setBackground(this.props.disabled),
          color: "white",
          border: "none",
          textTransform: "none",
          fontFamily: "Barlow",
          marginLeft: this.props.marginLeft,
          marginRight: this.props.marginRight,
          borderRadius: Theme.buttonBorderRadius,
        }}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.title}
      </Button>
    );
  }
}
