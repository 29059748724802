import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import i18n from "i18next";
import API from "kgt-api";
import { DegreeOfAccess } from "kgt-api/dist/Login";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import Session from "../../stores/Session";
import Theme from "../../utils/Theme";
import ButtonWhite from "../Buttons/ButtonWhite";
import SpinLoader from "../SpinLoader";
import TypographyTitleBig from "../Typography/TypographyTitleBig";
import styles from "./Login.css";

interface Props {
  onLogin?: (jwt: string) => void;
}

interface InjectedProps extends Props {
  session: Session;
}

interface State {
  username: string;
  password: string;
  loading: boolean;
  failedLogin: boolean;
  referrer: boolean;
  showPassword: boolean;
  responderLogin: boolean;
  errorMessage: string;
}

@inject("session")
@observer
class LoginComponent extends React.Component<Props, State> {
  state = {
    username: "",
    password: "",
    loading: false,
    failedLogin: false,
    referrer: false,
    showPassword: false,
    responderLogin: false,
    errorMessage: "",
  };

  get injected() {
    return this.props as InjectedProps;
  }

  onSubmit = async (e: React.FormEvent) => {
    this.setState({
      loading: true,
      responderLogin: false,
      failedLogin: false,
    });
    e.preventDefault();

    const resp = await API.Instance().login.post({
      accountName: this.state.username,
      password: this.state.password,
    });
    if (resp.ok) {
      const token = await resp.text();
      await this.injected.session.setToken(token);
      if (this.injected.session.data) {
        if (
          this.injected.session.data.degreeOfAccess === DegreeOfAccess.Responder
        ) {
          this.setState({
            responderLogin: true,
            loading: false,
          });
          this.injected.session.logOut();
        } else {
          if (this.props.onLogin) {
            this.props.onLogin(token);
          }
          this.setState({
            referrer: true,
            loading: false,
          });
        }
      }
    } else {
      this.setState({
        failedLogin: true,
        loading: false,
      });
      if (resp.status === 401) {
        this.setState({
          errorMessage: i18n.t("login_fail"),
        });
      } else {
        this.setState({
          errorMessage: i18n.t("error_noNetwork"),
        });
      }
    }
  };

  handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  handleToggleVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    if (this.state.referrer && !this.state.loading) {
      if (this.state.username == "admin") {
        return <Redirect to={"/admin"} />;
      } else {
        return <Redirect to={"/requester"} />;
      }
    }

    return (
      <div className={styles.container}>
        {this.state.loading ? (
          <SpinLoader />
        ) : (
          <React.Fragment>
            <TypographyTitleBig text={i18n.t("login_title")} />
            <form className={styles.form} onSubmit={this.onSubmit}>
              <TextField
                style={{ backgroundColor: "white" }}
                className={styles.formTextField}
                onChange={this.handleUsernameChange}
                label={i18n.t("login_username")}
                id="requester-username"
                margin="normal"
                variant="outlined"
                value={this.state.username}
              />
              <TextField
                style={{ backgroundColor: "white", color: "#384B62" }}
                className={styles.formTextField}
                onChange={this.handlePasswordChange}
                label={i18n.t("login_password")}
                id="requester-password"
                type={this.state.showPassword ? "text" : "password"}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.showPassword ? (
                        <VisibilityOff
                          color="primary"
                          onClick={this.handleToggleVisibility}
                        />
                      ) : (
                        <Visibility
                          color="primary"
                          onClick={this.handleToggleVisibility}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {this.state.failedLogin && (
                <Typography style={{ color: "#f44336" }}>
                  {this.state.errorMessage}
                </Typography>
              )}
              {this.state.responderLogin && (
                <Typography style={{ color: "#f44336" }}>
                  {i18n.t("login_errorResponder")}
                </Typography>
              )}
              <div className={styles.buttonContainer}>
                <Link to="/forgot">
                  <ButtonWhite
                    title={i18n.t("login_forgotPassword")}
                    onClick={() => {}}
                  />
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: Theme.buttonBorderRadius }}
                  type="submit"
                >
                  {i18n.t("login_login")}
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default LoginComponent;
