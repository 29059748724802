//Converts an experience number into the text representing that experience (without the "years" at the
//end, since that varies by language)
export function convertExperienceToText(num: number) {
  if (num >= 10) {
    return "10+";
  } else if (num >= 5) {
    return "5-10";
  } else if (num >= 2) {
    return "2-5";
  } else if (num >= 1) {
    return "1-2";
  } else {
    return "<1";
  }
}