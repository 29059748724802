import * as React from "react";
import HomeTopContent from "../components/HomeContent";
import ScreenContent from "../components/ScreenContent";

function Home() {
  return (
    <ScreenContent home={true}>
      <HomeTopContent />
    </ScreenContent>
  );
}

export default Home;
