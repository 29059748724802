import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import { GetResponseBody as RequesterData, PutRequestBody } from "kgt-api/dist/Requester/_id";
import API from "kgt-api";
import { RouteComponentProps, withRouter } from "react-router";
import RequesterTable, { Requester } from "../../../components/RequesterTable";
import SpinLoader from "../../../components/SpinLoader";
import Session from "../../../stores/Session";
import { inject, observer } from "mobx-react";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary";
import styles from "../Requester/AdminRequester.css";
import { TextField } from "@material-ui/core";
import Alert from "../../../components/Alert/Alert";
import i18next from "i18next";

type Props = RouteComponentProps<{ requesterId: string }>;

interface LoginButtonProps extends RouteComponentProps, WithTranslation {
  accountName: string;
}
interface InjectedLoginButtonProps extends LoginButtonProps {
  session: Session;
}
interface LoginButtonState {
  loading: boolean;
  error?: string;
}

@inject("session")
@observer
class LoginButton extends React.Component<LoginButtonProps, LoginButtonState> {
  state: LoginButtonState = {
    loading: false
  };

  get injected() {
    return this.props as InjectedLoginButtonProps;
  }

  handleOnClick = () => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const res = await API.Instance().login.post({
          accountName: this.props.accountName,
          // Not needed, since we are admin
          password: ""
        });

        if (res.ok) {
          const token = await res.text();
          this.injected.session.setToken(token);
          this.props.history.push("/requester");
        } else {
          this.setState({
            loading: false,
            error: "adminOneRequester.loginButton.error"
          });
        }
      }
    );
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        {this.state.loading ? (
          <div style={{ marginLeft: "auto" }} >
            <SpinLoader />
          </div>
        ) : (<ButtonPrimary
          onClick={this.handleOnClick}
          title={this.props.t("adminOneRequester.loginButton.text")}
          marginLeft="auto" />)}
        {this.state.error !== undefined && (
          <Typography color="error">
            {this.props.t(this.state.error)}
          </Typography>
        )}
      </div>
    );
  }
}
const WrappedLoginButton = withRouter(withTranslation()(LoginButton));

const AdminOneRequesterScreen: React.SFC<Props> = ({ match }) => {
  const [timesUpdated, setTimesUpdated] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [requester, setRequester] = React.useState<RequesterData | null>(null);
  const [alertVisible, setAlertVisible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  React.useEffect(() => {
    async function fetchData() {
      const res = await API.Instance().requester._id.get({
        id: match.params.requesterId
      });

      if (res.ok) {
        setRequester(await res.json());
      }

      setLoading(false);
    }

    fetchData();
  }, [match.params.requesterId, timesUpdated]);
  
  
  const saveRequester = async (newRequester: Requester) => {
    if (requester === null) {
      return;
    }

    const requesterToUpdate: PutRequestBody = {
      name: newRequester.name,
      description: newRequester.description,
      requesterIdentifier: newRequester.requesterIdentifier,
      address: newRequester.address,
      zip: newRequester.zip,
      city: newRequester.city,
      email: newRequester.email,
      contactPersonName: newRequester.contactPersonName,
      phoneNumber: newRequester.phoneNumber,
      region: newRequester.region ? { id: newRequester.region.id } : null,
      standardPayMargin: newRequester.standardPayMargin
    };

    const res = await API.Instance().requester._id.put(requesterToUpdate, {
      id: newRequester.id
    });

    if(res.ok){
      // Optimistic state change yay!
      setRequester({
        ...requester,
        ...newRequester
    });
      setTimesUpdated(old => old + 1);
    } else if (res.status === 406) {
      setAlertVisible(true);
      setAlertMessage("requester_editInfo_unique_violation_email_asAdmin");
    } else {
      setAlertVisible(true);
      setAlertMessage("requester_editInfo_error");
    }
  };


  const closeOverlay = () => {
    setAlertVisible(false);
    setAlertMessage("");
  };

  return (
    <Paper
      style={{ border: "1px solid #BDBDBD", padding: "1.5em"}}
      elevation={0}
      square={false}>
      {loading && <SpinLoader />}
      {!loading && requester !== null && (
        <React.Fragment>
          <RequesterTable
            requester={requester}
            editable
            onEdited={saveRequester}
          />
          <WrappedLoginButton accountName={requester.accountName} />
          <Alert
            visible={alertVisible}
            message={i18next.t(alertMessage)}
            closeOverlay={closeOverlay}
          ></Alert>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default AdminOneRequesterScreen;
