import React from "react";
import Typography from "@material-ui/core/Typography";
import Theme from "../../utils/Theme"

interface TypographyBoldProps {
    text: string;
    className?: string;
}

export default class TypographyBold extends React.Component<TypographyBoldProps> {
    render() {
        return (
            <Typography
                className={this.props.className}
                style={{ 
                    fontFamily: "Barlow",
                    fontWeight: "bold",
                    color: Theme.primaryTextColour }}
            >
                {this.props.text}
            </Typography>
        );
    }
}