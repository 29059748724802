import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import getSwedishTranslateData from "./translations/sv";
import getEnglishTranslateData from "./translations/en";
import { getNewTranslations } from "./translations/translationUpdater";

//Initializes i18next
const initializeI18n = async () => {
  i18n.use(initReactI18next).init({
    // Set the fallback language to swedish
    lng: "sv",
    fallbackLng: "sv",

    // We want a common namespace; call this "translations"
    ns: ["translations"],
    defaultNS: "translations",

    //Related to, among other things, code injection. This ensures "escaped" translations get caught, AFAIK
    interpolation: {
      escapeValue: true
    }
  });

  // Goes through all languages in order and adds their translation data
  i18n.addResources("sv", "translations", getSwedishTranslateData());
  i18n.addResources("en", "translations", getEnglishTranslateData());

  // Adds any new translate data that might exist on the server, but not on our device
  i18n.addResources("sv", "translations", await getNewTranslations("sv"));
  i18n.addResources("en", "translations", await getNewTranslations("en"));
};

export default initializeI18n;
