import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from "react-router";
import styles from "./RequesterDashboard.css";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import RequestTab from "./RequestTabRoutes";
import TimeReportTab from "./TimeReportTabRoutes";
import RequesterInfoScreen from "./RequesterInfo";
import ScreenContent from "../../components/ScreenContent";
import { Link } from "react-router-dom";

const TabContainer: React.FunctionComponent = ({ children }) => (
  <Typography component="div" className={styles.tabContainer}>
    {children}
  </Typography>
);

type Props = RouteComponentProps;

const RequestDashboard: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { match } = props;

  let currentTab: string = "requester";

  if (window.location.href.indexOf("request/list") > -1) {
    currentTab = "request";
  } else if (window.location.href.indexOf("timeReport") > -1) {
    currentTab = "timeReport";
  };

  return (
    <ScreenContent>
      <AppBar position="static" color="inherit" elevation={0} className={styles.appBar}>
        <Typography variant="subtitle1">
          <Link to="/requester"
            className={"requester" == currentTab ? styles.linkActive : styles.link}
            onClick={() => currentTab = "requester"}>
            {t("requestDashboard.tabs.info")}
          </Link>
          <Link to="/requester/request/list"
            className={"request" == currentTab ? styles.linkActive : styles.link}
            onClick={() => currentTab = "request"}>
            {t("requestDashboard.tabs.request_list")}
          </Link>
          <Link to="/requester/timeReport"
            className={"timeReport" == currentTab ? styles.linkActive : styles.link}
            onClick={() => currentTab = "timeReport"}>
            {t("requestDashboard.tabs.time_report")}
          </Link>
        </Typography>
      </AppBar>

      <TabContainer>
        <Switch>
          <Route path={`${match.path}/request/list`} component={RequestTab} />;
          <Route path={`${match.path}/timeReport`} component={TimeReportTab} />;
          <Route path={`${match.path}`} component={RequesterInfoScreen} />;
          <Redirect to={`${match.path}`} />
        </Switch>
      </TabContainer>
    </ScreenContent>
  );
};

export default withRouter(RequestDashboard);
