import * as React from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import AdminRequesterScreen from "./AdminRequester";
import AdminOneRequesterScreen from "./AdminOneRequester";
import RequesterCreatorForm from "../../../components/AdminContent/RequesterCreatorForm/RequesterCreatorForm";

type Props = RouteComponentProps;

const AdminRequesterRoutes: React.SFC<Props> = ({ match }) => {
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/create`} component={RequesterCreatorForm} />
        <Route path={`${match.path}/:requesterId`} component={AdminOneRequesterScreen} />
        <Route path={match.path} component={AdminRequesterScreen} />
      </Switch>
    </div>
  );
};

export default AdminRequesterRoutes;
