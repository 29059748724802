import { Endpoint } from "../../../Endpoint";
import API from "../../../API";
import { TimeReport } from "../../../TimeReport";

export const name = "/timereport";

export interface GetURLParams {
  id: string;
}

export type GetResponseBody = Array<TimeReport>;

export default class RequesterTimeReportEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get(params: GetURLParams) {
    return this.sendGet<GetResponseBody>(params);
  }
}
