import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import i18n from "i18next";
import React from "react";

const MAX_LENGTH_COMMENT = 100;

interface State {
  message: string;
  title?: string;
  type?: string;
  value?: Boolean;
  input?: boolean | undefined;
  inputMessage?: string;
  inputComment?: string | undefined;
  isCommentValid?: boolean;
  closeOverlayConfirm: (
    value: Boolean | undefined,
    input?: string | undefined
  ) => void;
  visible: boolean;
  approveButtonText?: string | undefined;
}

export default class Confirm extends React.Component<State> {
  state = {
    visible: false,
    message: "",
    title: "",
    value: false,
    type: "",
    inputMessage: "",
    isCommentValid: true,
  };

  handleCancel = () => {
    this.props.closeOverlayConfirm(undefined);
  };

  handleOk = () => {
    this.props.input
      ? this.props.closeOverlayConfirm(true, this.state.inputComment)
      : this.props.closeOverlayConfirm(true);
  };

  handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.length > MAX_LENGTH_COMMENT
      ? this.setState({
          isCommentValid: false,
          inputComment: e.target.value,
        })
      : this.setState({
          isCommentValid: true,
          inputComment: e.target.value,
        });
  };

  render() {
    const {
      message,
      visible,
      title,
      input,
      inputMessage,
      approveButtonText,
    } = this.props;
    return (
      <div>
        <Dialog
          open={visible}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
            {input && (
              <TextField
                autoFocus
                margin="dense"
                id="comment"
                value={this.state.inputComment}
                onChange={this.handleCommentChange}
                label={inputMessage}
                type="text"
                fullWidth
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              {i18n.t("confirm.decline")}
            </Button>
            <Button
              onClick={this.handleOk}
              color="primary"
              disabled={!this.state.isCommentValid}
            >
              {i18n.t(
                approveButtonText ? approveButtonText : "confirm.approved"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
