import i18n from "i18next";
import * as React from "react";
import { Link } from "react-router-dom";
import illustration from "../../images/phones.png";
import ButtonSecondary from "../Buttons/ButtonWhite";
import TypographyRegular from "../Typography/TypographyRegular";
import TypographyTitleBig from "../Typography/TypographyTitleBig";
import styles from "./HomeTopContent.css";

class HomePageContent extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <TypographyTitleBig text={i18n.t("homePageContent.heading")} />

        <div className={styles.leftContainer}>
          <div>
            <TypographyRegular
              text={i18n.t("homePageContent.paragraph_first")}
            />
          </div>
          <div>
            <TypographyRegular
              text={i18n.t("homePageContent.paragraph_second")}
            />
          </div>
          <div>
            <TypographyRegular
              text={i18n.t("homePageContent.paragraph_third")}
            />
          </div>
          <div className={styles.link}>
            <Link to="/responder">
              <ButtonSecondary
                title={i18n.t("homeTopContent_learn_more")}
                whiteBackground
                onClick={() => {}}
              />
            </Link>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <img
            src={illustration}
            alt="iPhone Screenshot"
            className={styles.image}
          />
        </div>
      </div>
    );
  }
}

export default HomePageContent;
