import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import API from "kgt-api";
import { DegreeOfAccess } from "kgt-api/dist/Login";
import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NotFound from "./components/NotFound/NotFound";
import SpinLoader from "./components/SpinLoader";
import About from "./screens/About";
import AdminRoutes from "./screens/Admin/AdminRoutes";
import ForgotPassword from "./screens/ForgotPassword";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Privacy from "./screens/Privacy/Privacy";
import RequesterRoutes from "./screens/Requester/RequesterRoutes";
import ResetScreen from "./screens/Reset";
import Responder from "./screens/Responder/Responder";
import ResponderInterest from "./screens/Responder/ResponderInterest";
import TermsOfService from "./screens/Terms/TermsOfService";
import Session from "./stores/Session";

//---Theme declarations: declares all the themes used on the website---
const defaultTheme = createMuiTheme({
  palette: {
    text: {
      primary: "#384B62",
      secondary: "#384B62",
      disabled: "#384B62",
      hint: "#384B62",
    },
    error: {
      main: "#F77E6E",
    },
  },
  typography: {
    fontFamily: `"Barlow", "Helvetica", "Arial", sans-serif`,
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
        border: "none",
        textTransform: "none",
        fontFamily: "Barlow",
        backgroundColor: "#6C6CFC",
        borderRadius: 12,
      },
    },
  },
});
//---End of theme declaration--

interface Props extends RouteComponentProps {}
interface InjectedProps extends Props {
  session: Session;
}

@inject("session")
@observer
//Declares all routes in the project
class Routes extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    document.body.style.backgroundColor = "#FFFFFF";

    const isAdmin =
      this.injected.session.data &&
      this.injected.session.data.degreeOfAccess === DegreeOfAccess.Admin;

    const isRequester =
      this.injected.session.data &&
      this.injected.session.data.degreeOfAccess === DegreeOfAccess.Requester;

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <Header />
        <Switch>
          {isAdmin && (
            <Switch>
              <Route path="/admin" component={AdminRoutes} />
              <Redirect from="*" to="/admin" />
            </Switch>
          )}
          {isRequester && (
            <Switch>
              <Route path="/requester" component={RequesterRoutes} />
              <Redirect from="*" to="/requester" />
            </Switch>
          )}
          <Route path="/" exact component={Home} />
          <Route path="/reset" component={ResetScreen} />
          <Route path="/about" component={About} />
          <Route path="/requester" component={RequesterRoutes} />
          <Route path="/responder" exact component={Responder} />
          <Route path="/responder/register" component={ResponderInterest} />
          <Route path="/login" component={Login} />
          <Redirect from="/admin" to="/login" />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={TermsOfService} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/notFound" component={NotFound} />
          <Redirect from="*" to="/notFound" />
        </Switch>
        <Footer location={this.props.location.pathname} />
      </MuiThemeProvider>
    );
  }

  //Function for mapping web path to override web theme
  //Any URL not mapped here will revert to default theme
  pageToThemeFunction = () => {
    return defaultTheme;
  };
}
const RoutesWithRouter = withRouter(Routes);

const sessionStore = new Session();

//Exports the content of this file (so, the entire webpage router)
class WebappRouter extends React.Component {
  componentDidMount() {
    API.Instance().addOnUnauthorized(this.handleOnUnauthorized);
    document.title = "Vårdkällan";
  }

  componentWillUnmount() {
    API.Instance().removeOnUnauthorized(this.handleOnUnauthorized);
  }

  handleOnUnauthorized = () => {
    sessionStore.clearToken();
    API.Instance().setJWT(null);
  };

  render() {
    return (
      <React.Suspense fallback={<SpinLoader />}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider session={sessionStore}>
            <Router>
              <React.Fragment>
                <CssBaseline />
                <RoutesWithRouter />
              </React.Fragment>
            </Router>
          </Provider>
        </MuiPickersUtilsProvider>
      </React.Suspense>
    );
  }
}
export default WebappRouter;
