import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/region";

interface GetResponseBody {
  regions: Array<{
    id: string;
    name: string;
  }>;
}

export { name, GetResponseBody };

export default class Region extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  get() {
    return this.sendGet<GetResponseBody>();
  }
}
