import React from "react";
import TableCell from "@material-ui/core/TableCell";

interface CellProps {
    text: string;
    maxWidth?: string;
}

export default class Cell extends React.Component<CellProps> {
    render() {
        return (
            <TableCell style={{ maxWidth: this.props.maxWidth }}>
                {this.props.text}
            </TableCell>
        )
    }
}